import { Card, Checkbox, Col, Form, Input, Row, Space } from "antd";
import React from "react";
import { GenericFormProps } from "@3ts/react-ant-crud";
import formItemProps from "../../helper/form/formItemProps";
import { CreateUserInput, Role, UpdateUserInput } from "../../graphql/schema";

export interface FormFields {
  active: boolean;
  firstName: string;
  lastName: string;
  phone: string | null | undefined;
  email: string;
  password?: string;
  isAdmin: boolean;
  street: string | null | undefined;
  streetNumber: string | null | undefined;
  zipCode: string | null | undefined;
  city: string | null | undefined;
  role: Role;
  categoryId: number;
}

export const parseFormFields = (
  fields: FormFields,
): UpdateUserInput & CreateUserInput => {
  return {
    active: fields.active,
    firstName: fields.firstName,
    lastName: fields.lastName,
    phone: fields.phone,
    email: fields.email,
    password: fields.password,
    street: fields.street,
    streetNumber: fields.streetNumber,
    zipCode: fields.zipCode,
    city: fields.city,
    role: fields?.role || Role.Admin,
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
}

const UserForm = ({ formProps }: Props) => {
  const { form } = formProps;

  return (
    <Form {...formProps} layout="vertical" form={form}>
      <Space size={24} direction="vertical">
        <Card title="Allgemein">
          <Row gutter={24}>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("firstName", "Vorname", true)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("lastName", "Nachname", true)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("email", "E-Mail", true)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("street", "Straße", false)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("streetNumber", "Hausnummer", false)}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("zipCode", "Postleitzahl", false)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("city", "Stadt", false)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("password", "Passwort", false)}>
                <Input.Password placeholder="Neues Passwort" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Berechtigungen">
          <Row gutter={24}>
            {/* <Col {...colSmall}>
              <Form.Item {...formItemProps("role", "Rolle", true)}>
                <RoleSelect />
              </Form.Item>
            </Col> */}
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("active", "Aktiv", false)}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Space>
    </Form>
  );
};

export default UserForm;
