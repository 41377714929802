import { Select } from "antd";
import React from "react";
import countries from "../../helper/form/dialCodes";

interface Props {
  onChange?: (value: string) => void;
  value?: string;
}

const SelectCountryCode = ({ onChange, value }: Props) => (
  <Select
    defaultValue="+49"
    onChange={(val) => {
      if (onChange) onChange(val);
    }}
    value={value}
    filterOption={(input: string, option: any) =>
      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    showSearch
    options={countries
      .sort((a, b) => a.dial_code.localeCompare(b.dial_code))
      .map((country) => ({
        value: `${country.dial_code}`,
        key: `${country.dial_code}#${country.code}`,
        label: `${country.emoji} ${country.dial_code}`,
      }))}
    style={{
      minWidth: 100,
    }}
  />
);

export default SelectCountryCode;
