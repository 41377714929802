import { Select } from "antd";
import { useGetProductCategoriesQuery } from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

const ProductCategorySelect = ({ value, onChange, disabled }: Props) => {
  const { data } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
  });
  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.productCategories.items.map((productCategory) => {
          return {
            value: productCategory.id,
            label: productCategory.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default ProductCategorySelect;
