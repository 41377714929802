import { Select } from "antd";

export interface GenericEnumSelectProps<Type extends string | number | symbol> {
  value?: Type | null;
  onChange?: (val: Type) => void;
  options?: {
    [key in Type]: string | number;
  };
  disabled?: boolean;
}

const GenericEnumSelect = <Type extends string | number | symbol>({
  value,
  onChange,
  options,
  disabled,
}: GenericEnumSelectProps<Type>) => {
  return (
    <Select
      disabled={disabled}
      value={value}
      defaultValue={value}
      onChange={onChange}
      options={
        options
          ? Object.entries(options).map(([value, label]) => {
              return {
                value,
                label,
              };
            })
          : []
      }
    />
  );
};

export default GenericEnumSelect;
