import React from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Content from "../components/layout/Content";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Content>
      <Row justify="center" gutter={[10, 10]} style={{ marginBottom: 50 }}>
        <Col span={8}>
          <Card title="Termine" onClick={() => navigate("appointments")}>
            <Row>
              <Col>
                <Typography.Text>
                  Hier können Sie Termine verwalten. Sie können Termine
                  erstellen, bearbeiten und löschen.
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => navigate("/appointments")}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Kunden" onClick={() => navigate("customers")}>
            <Row>
              <Col>
                <Typography.Text>
                  Hier können Sie Kunden verwalten. Sie können Kunden erstellen,
                  bearbeiten und löschen.
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => navigate("/customers")}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row justify="center" gutter={[10, 10]} style={{ marginBottom: 50 }}>
        <Col span={8}>
          <Card title="Gutscheine" onClick={() => navigate("/coupons")}>
            <Row>
              <Col>
                <Typography.Text>
                  Hier können Sie Gutscheine verwalten. Sie können Gutscheine
                  erstellen, bearbeiten und löschen.
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => navigate("/practitioner-assignments")}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Behandler" onClick={() => navigate("practitioners")}>
            <Row>
              <Col>
                <Typography.Text>
                  Hier können Sie Behandler verwalten. Sie können Behandler
                  erstellen, bearbeiten und löschen.
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => navigate("/practitioners")}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default Dashboard;
