import { Select } from "antd";
import { useGetRoomsSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
}

const RoomsSelect = ({ value, onChange }: Props) => {
  const { data } = useGetRoomsSelectQuery({
    fetchPolicy: "no-cache",
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.rooms.items.map((room) => {
          return {
            value: room.id,
            label: room.name,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default RoomsSelect;
