import { Checkbox, Form, Input, message, Modal } from "antd";
import React from "react";
import {
  CommunicationType,
  Country,
  CustomerType,
  GetCustomersSelectDocument,
  useCreateCustomerMutation,
} from "../../../../graphql/schema";
import CustomerTypeSelect from "../../../customer/CustomerTypeSelect";
import CommunicationTypeSelect from "../../../customer/CommunicationTypeSelect";
import formItemProps from "../../../../helper/form/formItemProps";
import SelectCountryCode from "../../../customer/SelectCountryCode";

const nameRegex = /^[a-zA-ZäöüßÄÖÜẞ\- ]{2,50}$/g;
const phoneRegex = /^\+?\d{7,12}$/;
const mailRegex =
  /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;

interface Props {
  open: boolean;
  onClose: () => void;
  onCustomerCreated: (customer: any) => void;
}

const CreateCustomerModal = ({ open, onCustomerCreated, onClose }: Props) => {
  const [form] = Form.useForm();
  const [createCustomer] = useCreateCustomerMutation();

  const handleCreate = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const data = await createCustomer({
            variables: {
              dto: {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                email: values.email,
                category: values.category || CustomerType.Standard,
                city: values.city || "",
                street: values.street || "",
                zipCode: values.zipCode || "",
                termsAccepted: values.termsAccepted || false,
                cooperation: false,
                preferredCommunicationType: CommunicationType.Email,
                country: Country.Germany,
                streetNumber: values.streetNumber || "",
              },
            },
            refetchQueries: [GetCustomersSelectDocument],
          });
          if (!data?.data?.createCustomer)
            throw new Error("Error creating customer");
          onCustomerCreated(data?.data?.createCustomer.id);
          onClose();
        } catch (e) {
          if (e.graphQLErrors?.[0]?.extensions?.code === "CONFLICT") {
            message.error("Kunde existiert bereits.");
          }
        }
      })
      .catch((e) => {
        message.error("Bitte füllen Sie alle Pflichtfelder aus.");
      });
  };

  return (
    <Modal
      title="Kunde anlegen"
      open={open}
      onCancel={onClose}
      onOk={handleCreate}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          country: Country.Germany,
          preferredCommunicationType: CommunicationType.Email,
        }}
      >
        <Form.Item
          {...formItemProps("firstName", "Vorname", true, [
            {
              required: true,
              validator: (rule, value) => {
                return value.length < 50 ? Promise.resolve() : Promise.reject();
              },
              message: "Die maximale Länge beträgt 50 Zeichen.",
            },
            {
              required: true,
              validator: (rule, value) => {
                return new RegExp(nameRegex).test(value)
                  ? Promise.resolve()
                  : Promise.reject();
              },
              message: "Bitte trage deinen Vornamen ein.",
            },
          ])}
        >
          <Input maxLength={50} minLength={2} showCount />
        </Form.Item>
        <Form.Item
          {...formItemProps("lastName", "Nachname", true, [
            {
              required: true,
              validator: (rule, value) => {
                return value.length < 50 ? Promise.resolve() : Promise.reject();
              },
              message: "Die maximale Länge beträgt 50 Zeichen.",
            },
            {
              required: true,
              validator: (rule, value) => {
                return new RegExp(nameRegex).test(value)
                  ? Promise.resolve()
                  : Promise.reject();
              },
              message: "Bitte trage deinen Nachnamen ein.",
            },
          ])}
        >
          <Input maxLength={50} minLength={2} showCount />
        </Form.Item>
        <Form.Item
          {...formItemProps("phone", "Telefonnummer", true, [
            {
              required: true,
              message: "Bitte trage deine Mobilnummer ein.",
              validator: (rule, value) => {
                return new RegExp(phoneRegex).test(value)
                  ? Promise.resolve()
                  : Promise.reject();
              },
            },
          ])}
        >
          <Input
            addonBefore={
              <Form.Item name="countryCodePhone" noStyle initialValue="+49">
                <SelectCountryCode />
              </Form.Item>
            }
            style={{
              borderRadius: "10px",
            }}
          />
        </Form.Item>
        <Form.Item
          {...formItemProps("email", "E-Mail", true, [
            {
              required: true,
              type: "email",
              validator: (rule, value) => {
                return new RegExp(mailRegex).test(value)
                  ? Promise.resolve()
                  : Promise.reject();
              },
              message: "Bitte trage deine E-Mail ein.",
            },
          ])}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formItemProps("category", "Kategorie", true)}>
          <CustomerTypeSelect />
        </Form.Item>
        <Form.Item
          tooltip="Aktuell werden nur E-Mails unterstützt."
          label="Kommunikationskanal"
          name="preferredCommunicationType"
        >
          <CommunicationTypeSelect disabled value={CommunicationType.Email} />
        </Form.Item>
        <Form.Item label="" name="termsAccepted">
          <Checkbox>AGBs gelesen & akzeptiert</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCustomerModal;
