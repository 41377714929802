import dayjs from "dayjs";
import {
  AppointmentStatus,
  BookingChannel,
  ResourceAllocation,
} from "../../graphql/schema";
import { omitDeepArrayWalk } from "./removeTypename";

const prepareFormValues = (values: any) => {
  return {
    forceCreation: values.forceCreation || false,

    // DON'T TOUCH, PAINFUL.
    dateFrom: values.dateFrom,
    dateUntil: values.dateUntil,

    appointmentTypeId: values.appointmentType || null,
    storeId: values.storeId,
    appointmentStatus: values.appointmentStatus || AppointmentStatus.New,
    bookedPrice: values.bookedPrice || "0.00",
    practitionerIds: values.practitionerIds || [],
    products: omitDeepArrayWalk(
      values?.products?.map((appointmentProduct: any) => {
        return {
          id: appointmentProduct?.id,
          productId: appointmentProduct.productId,
          variationId:
            appointmentProduct?.variationId !== undefined
              ? appointmentProduct.variationId
              : null,
          bookedPrice: String(appointmentProduct.bookedPrice) || "0.00",
          totalDuration: appointmentProduct.totalDuration || 0,
          packageId: appointmentProduct.packageId || null,
          requiredResources: appointmentProduct?.requiredResources.map(
            (r: any, index: number) => {
              const allocation = appointmentProduct?.allocations?.find(
                (a: ResourceAllocation, i: number) => i === index,
              );

              const resourceRequirements: any[] =
                r?.resourceRequirements?.filter((requirements: any) => {
                  return {
                    property: requirements.property,
                    value: requirements.value.filter((v: any) => v !== null),
                  };
                }) || [];

              if (!allocation) {
                return {
                  resourceType: r.resourceType,
                  resourceOwner: r.resourceOwner,
                  resourceRequirements,
                };
              }

              if (
                !resourceRequirements.some(
                  (resourceRequirement) =>
                    resourceRequirement.property === "id",
                )
              ) {
                resourceRequirements.push({
                  property: "id",
                  value: [allocation.resourceId].filter((v: any) => v !== null),
                  operator: "IN",
                });
              } else {
                resourceRequirements.map((rr: any) => {
                  if (rr.property === "id") {
                    return {
                      ...rr,
                      value: [allocation.resourceId].filter(
                        (v: any) => v !== null,
                      ),
                    };
                  }
                  return rr;
                });
              }

              return {
                resourceType: r.resourceType,
                resourceOwner: r.resourceOwner,
                resourceRequirements,
              };
            },
          ),
          usedCouponIds:
            appointmentProduct?.usedCouponIds?.map(
              (couponId: number) => couponId,
            ) || [],
          allocations: appointmentProduct.allocations,
        };
      }) || [],
      "__typename",
    ),
    dateOfBooking: values.dateOfBooking || dayjs().toString(),
    internalNote: values.internalNote || "",
    bookingChannel: BookingChannel.Personal,
    notifyForEarlierAppointment: values.notifyForEarlierAppointment || false,
    message: values.message || "",
    utmParameters: values.utmParameters || "",
    customerIds: values.customerIds || [],
    notifyCustomer: values.notifyCustomer || false,
  };
};

export default prepareFormValues;
