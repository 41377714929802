import { FormItemProps } from "antd/lib/form/FormItem";
import { Rule } from "rc-field-form/lib/interface";

const formItemProps = (
  name: string | (string | number)[],
  label: string,
  required = false,
  rules?: Rule[],
): FormItemProps => {
  return {
    name,
    label,
    rules: [
      {
        required,
        message: `${label} ist ein Pflichtfeld!`,
      },
      ...(rules || []),
    ],
  };
};

export default formItemProps;
