import { Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import {
  ProductFragment,
  useGetProductsSelectQuery,
} from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  filter?: any;
}

const ProductSelect = ({ value, onChange, filter = {} }: Props) => {
  const { data } = useGetProductsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductFragment>(filter),
        itemsPerPage: 10000,
      },
    },
  });
  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.products.items.map((product) => {
          return {
            value: product.id,
            label: product.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default ProductSelect;
