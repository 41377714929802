import { Button, Col, Form, InputNumber, message, Row } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import dayjs from "dayjs";
import AllocationsConfigList from "./AllocationsConfigList";
import {
  AppointmentProduct,
  AppointmentProductInput,
  Customer,
  ProductPriceOutput,
  useDeleteAppointmentProductMutation,
  useDeleteAppointmentProductsMutation,
  useGetCustomerQuery,
} from "../../../graphql/schema";
import formItemProps from "../../../helper/form/formItemProps";
import VariationSelect from "../../products/VariationSelect";
import getBaseProduct from "../../../helper/common/getBaseProduct";
import getProductsTotalDuration from "../../../helper/common/getProductsTotalDuration";
import getPriceForCustomer from "../../../helper/common/getPriceForCustomer";
import AppointmentProductSelect from "./AppointmentProductSelect";
import { useAppointment } from "../appointment/context/AppointmentContext";
import { useCalendar } from "../appointment/calendar/CalendarContext";

interface Props {
  id: number;
  products?: any;
}

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};

const AppointmentProductsList = ({ id, products }: Props) => {
  const { storeId } = useCalendar();
  const form = Form.useFormInstance();
  const formProducts = Form.useWatch("products", form);
  const { validateVacancies } = useAppointment();
  const [deleteManyAppointmentProducts] =
    useDeleteAppointmentProductsMutation();
  const [deleteOneAppointmentProduct] = useDeleteAppointmentProductMutation();

  const { data: customer } = useGetCustomerQuery({
    variables: {
      id: form?.getFieldValue("customerIds")?.[0],
    },
    skip: !form?.getFieldValue("customerIds")?.[0],
  });

  const add = useCallback(() => {
    const list = form?.getFieldValue("products") || [];

    const newList = [
      ...list,
      {
        id: undefined,
        productId: undefined,
        variationId: undefined,
        usedCouponIds: [],
        requiredResources: [],
        totalDuration: 0,
        allocations: [],
        package: undefined,
      },
    ];
    form?.setFieldValue("products", newList);
  }, [form]);

  const remove = useCallback(
    async (name: number) => {
      const appointmentProductId = form?.getFieldValue([
        "products",
        name,
        "id",
      ]);
      try {
        if (appointmentProductId) {
          await deleteOneAppointmentProduct({
            variables: {
              id: appointmentProductId,
            },
          });
        }

        form?.setFieldsValue({
          products:
            form
              ?.getFieldValue("products")
              ?.filter((_: AppointmentProduct, i: number) => i !== name) || [],
        });
      } catch (e: any) {
        if (e.graphQLErrors?.[0]?.extensions?.code === "NOT_FOUND") {
          return;
        }
        message.error("Leistung konnte nicht gelöscht werden");
      }
    },
    [form],
  );

  const handleAppointmentDuration = useCallback(() => {
    const dateFrom = dayjs(form?.getFieldValue("dateFrom"));
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalDuration = getProductsTotalDuration(appointmentProducts);

    if (dateFrom) {
      form?.setFieldValue(
        "dateUntil",
        dayjs(dateFrom.toDate()).add(totalDuration, "minute"),
      );
    }
  }, [form]);

  const handleTotalPrice = useCallback(() => {
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalPrice = appointmentProducts.reduce(
      (acc: any, product: AppointmentProductInput) => {
        return acc + Number(product.bookedPrice);
      },
      0,
    );
    form?.setFieldsValue({
      ...form?.getFieldsValue(),
      bookedPrice: String(totalPrice),
    });
  }, [form]);

  // Building list of appointment products to delete starting with the grandchildren
  const removeAssociatedPackageProducts = useCallback(
    async (
      appointmentProducts: AppointmentProductInput[],
      targetId: number,
    ) => {
      const productIdsToRemove: number[] = [];
      if (!targetId) return;
      const findProductsToRemove = (currentId: number) => {
        const childProducts = appointmentProducts.filter(
          (p: AppointmentProductInput) =>
            p.packageId && p.packageId === currentId,
        );

        const isPackageProduct = (
          appointmentProduct: AppointmentProductInput,
        ) => {
          return appointmentProducts.some(
            (p: AppointmentProductInput) =>
              p?.packageId && p.packageId === appointmentProduct?.id,
          );
        };

        childProducts.map(async (p: AppointmentProductInput) => {
          if (isPackageProduct(p) && p?.id) {
            findProductsToRemove(p.id);
          }
        });

        childProducts.forEach(
          (p: AppointmentProductInput) => p.id && productIdsToRemove.push(p.id),
        );
      };
      findProductsToRemove(targetId);

      await deleteManyAppointmentProducts({
        variables: {
          ids: [...productIdsToRemove, targetId],
        },
      });
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        products: form
          ?.getFieldValue("products")
          ?.filter(
            (p: AppointmentProductInput) =>
              p.id && !productIdsToRemove.includes(p.id),
          ),
      });
    },
    [],
  );

  const appointmentExpired = useCallback(() => {
    const start = dayjs(form?.getFieldValue("dateFrom"));
    return start ? start.isBefore(new Date()) : false;
  }, [form]);

  const handleChangeProduct = useCallback(
    async (value: any, name: number, target: string) => {
      form?.setFieldValue([name, target], value);
      const product = form?.getFieldValue(["products", name]);

      const currentProduct = form?.getFieldValue(["products", name]);

      await removeAssociatedPackageProducts(
        form?.getFieldValue(["products"]),
        currentProduct.id,
      );

      form?.setFieldsValue({
        ...form?.getFieldsValue(),

        products: form
          ?.getFieldValue("products")

          .map((item: AppointmentProduct, index: number) => {
            if (index === name) {
              const bookedPrice = getPriceForCustomer(
                (getBaseProduct(product, products)
                  ?.prices as Partial<ProductPriceOutput>[]) || [],
                customer?.customer as Partial<Customer>,
              );

              const totalDuration =
                getBaseProduct(product, products)?.totalDuration || 0;

              return {
                ...item,
                id: undefined,
                bookedPrice,
                requiredResources: [],
                allocation: [],
                totalDuration,
              };
            }
            return item;
          }),
      });
      handleTotalPrice();
      await validateVacancies({
        values: form?.getFieldsValue(),
        appointmentId: id,
        index: name,
        restore: true,
      });
      handleAppointmentDuration();
    },
    [form, handleTotalPrice, validateVacancies, id, handleAppointmentDuration],
  );

  return (
    <Form.List
      name="products"
      rules={[
        {
          validator: (_, value) => {
            if (value && value.length > 0) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Mindestens eine Leistung muss angegeben werden"),
            );
          },
        },
      ]}
    >
      {(fields, _, { errors }) => {
        const handleRemove = async (name: number) => {
          const product = form?.getFieldValue(["products", name]);

          await removeAssociatedPackageProducts(
            form?.getFieldValue(["products"]),
            product.id,
          );

          form?.setFieldsValue({
            ...form?.getFieldsValue(),
            products: form
              ?.getFieldValue("products")
              ?.filter(
                (p: AppointmentProductInput) =>
                  product?.id !== p?.packageId || product?.id !== p?.id,
              ),
          });

          await remove(name);
        };

        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const isPackageProduct = formProducts?.[name]?.packageId || false;
              const isMainProduct = !formProducts?.[name]?.packageId || false;

              const isLastPackageProduct =
                formProducts?.length &&
                (!formProducts[name + 1] || !formProducts[name + 1]?.packageId);

              return (
                <Form.Item noStyle key={`Product_${key}`} shouldUpdate>
                  {({ getFieldValue }) => {
                    if (!getFieldValue("products")) return null;

                    return (
                      <div
                        style={{
                          width: "100%",
                          margin: 0,
                          marginTop: isMainProduct ? 10 : 0,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: isPackageProduct ? 0 : 10,
                          paddingBottom: isPackageProduct ? 0 : 10,
                          position: "relative",
                          borderTopLeftRadius: isPackageProduct ? 0 : 10,
                          borderTopRightRadius: isPackageProduct ? 0 : 10,
                          borderBottomLeftRadius: isLastPackageProduct ? 10 : 1,
                          borderBottomRightRadius: isLastPackageProduct
                            ? 10
                            : 0,
                          backgroundColor: "#f0f2f5",
                        }}
                        key={key}
                      >
                        <Button
                          hidden={isPackageProduct}
                          style={{
                            zIndex: 999,
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                          disabled={appointmentExpired()}
                          danger
                          size="small"
                          onClick={async () => {
                            await handleRemove(name);
                            handleAppointmentDuration();
                          }}
                          icon={<DeleteOutlined />}
                        />
                        <Row
                          hidden={isPackageProduct}
                          style={{
                            width: "100%",
                            margin: 0,
                          }}
                        >
                          <Col {...colSmall}>
                            <Form.Item
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "productId"],
                                "Leistung",
                                true,
                              )}
                            >
                              <AppointmentProductSelect
                                filter={{
                                  isSubProduct: false,
                                  nextVersionId: null,
                                  "stores.id": storeId,
                                }}
                                products={form?.getFieldValue("products")}
                                onChange={(value) =>
                                  handleChangeProduct(value, name, "productId")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col {...colSmall}>
                            <Form.Item
                              hidden={isPackageProduct}
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "variationId"],
                                "Variation",
                                false,
                              )}
                            >
                              <VariationSelect
                                filter={
                                  form?.getFieldValue([
                                    "products",
                                    name,
                                    "productId",
                                  ])
                                    ? {
                                        "product.id": form?.getFieldValue([
                                          "products",
                                          name,
                                          "productId",
                                        ]),
                                      }
                                    : undefined
                                }
                                onChange={async (value) => {
                                  await handleChangeProduct(
                                    value,
                                    name,
                                    "variationId",
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row
                          hidden={isPackageProduct}
                          style={{
                            width: "100%",
                            margin: 0,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "bookedPrice"],
                                "Gebuchter Preis",
                                true,
                              )}
                            >
                              <InputNumber
                                precision={2}
                                style={{ width: "100%" }}
                                onChange={() => handleTotalPrice()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <AllocationsConfigList
                              products={products}
                              id={id}
                              name={name}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Form.Item>
              );
            })}
            <Form.Item
              style={{
                marginTop: 10,
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Leistung hinzufügen
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default AppointmentProductsList;
