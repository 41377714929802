import { Select } from "antd";
import { useGetPractitionerCategoriesQuery } from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

const PractitionerCategorySelect = ({ value, onChange, disabled }: Props) => {
  const { data } = useGetPractitionerCategoriesQuery({
    fetchPolicy: "no-cache",
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      disabled={disabled}
      options={
        data?.practitionerCategories.items.map((practitionerCategory) => {
          return {
            value: practitionerCategory.id,
            label: practitionerCategory.name,
          };
        }) || []
      }
    />
  );
};

export default PractitionerCategorySelect;
