import { Card, Col, Form, Input, Row, Space, Tabs, TimePicker } from "antd";
import React from "react";
import { GenericFormProps } from "@3ts/react-ant-crud";
import dayjs, { Dayjs } from "dayjs";
import { format } from "date-fns";
import {
  ExclamationOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CreateStoreInput, UpdateStoreInput } from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import ProductsCheckboxGroup from "../products/ProductsCheckboxGroup";

export interface FormFields {
  name: string;
  address: string;
  description: string;
  city: string;
  slug?: string;
  monday: dayjs.Dayjs[][];
  tuesday: dayjs.Dayjs[][];
  wednesday: dayjs.Dayjs[][];
  thursday: dayjs.Dayjs[][];
  friday: dayjs.Dayjs[][];
  saturday: dayjs.Dayjs[][];
  sunday: dayjs.Dayjs[][];
  productIds: number[];
  zipCode: string;
  externalId: string;
}

const parseBusinessHours = (businessHours: Dayjs[][]) => {
  const defaultHours = [
    {
      start: "00:00",
      end: "00:00",
    },
  ];
  if (businessHours?.length === 0 || !businessHours[0]) return defaultHours;
  return businessHours.map((businessHour: any) => {
    if (!businessHour[0] || !businessHour[1]) return defaultHours[0];
    return {
      start: format(dayjs(businessHour[0]).toDate(), "HH:mm"),
      end: format(dayjs(businessHour[1]).toDate(), "HH:mm"),
    };
  });
};

export const parseFormFields = (
  fields: FormFields,
): UpdateStoreInput & CreateStoreInput => {
  return {
    name: fields.name,
    address: fields.address,
    description: fields.description || "",
    city: fields.city,
    slug: fields.slug || null,
    monday: parseBusinessHours(fields.monday),
    tuesday: parseBusinessHours(fields.tuesday),
    wednesday: parseBusinessHours(fields.wednesday),
    thursday: parseBusinessHours(fields.thursday),
    friday: parseBusinessHours(fields.friday),
    saturday: parseBusinessHours(fields.saturday),
    sunday: parseBusinessHours(fields.sunday),
    zipCode: fields.zipCode,
    productIds: fields.productIds || [],
    externalId: fields.externalId || "",
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
}

const hasError = (errorList: any[] | undefined) => {
  return errorList
    ? errorList.filter((errorItem: any) => errorItem.errors?.length > 0)
        ?.length > 0
    : false;
};

const StoreForm = ({ formProps }: Props) => {
  const { form } = formProps;
  return (
    <Form {...formProps} layout="vertical" form={form}>
      <Tabs>
        <Tabs.TabPane
          tab={
            <span
              style={{
                color: hasError(
                  form?.getFieldsError(["name", "address", "city", "zipCode"]),
                )
                  ? "red"
                  : "black",
              }}
            >
              Allgemein{" "}
              {hasError(
                form?.getFieldsError(["name", "address", "city", "zipCode"]),
              ) && <ExclamationOutlined />}
            </span>
          }
          key="base"
        >
          <Card>
            <Row gutter={24}>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("name", "Name", true)}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("address", "Adresse", true)}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("city", "Stadt", true)}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("zipCode", "Postleitzahl", true)}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  {...formItemProps("description", "Beschreibung", false)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  tooltip="Der Slug wird für die Marketing-URL des Stores verwendet. Es dürfen nur Kleinbuchstaben, Zahlen und Bindestriche verwendet werden."
                  {...formItemProps("slug", "Slug", false)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  tooltip="Wählen Sie die Leistungen aus, die in diesem Store angeboten werden sollen."
                  {...formItemProps("productIds", "Leistungen", false)}
                >
                  <ProductsCheckboxGroup />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Externe Konfiguration" key="4" forceRender>
          <Card title="Externe Konfiguration">
            <Form.Item {...formItemProps("externalId", "Externe ID")}>
              <Input />
            </Form.Item>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Öffnungszeiten" key="3" forceRender>
          <Card title="Öffnungszeiten">
            <Row>
              <Col {...colSmall}>
                <Form.List name="monday" key="monday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`monday_${dayKey}`}
                            {...formItemProps([dayKey], "Montag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              format="HH:mm"
                              minuteStep={15}
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="tuesday" key="tuesday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`tuesday_${dayKey}`}
                            {...formItemProps([dayKey], "Dienstag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="wednesday" key="wednesday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`wednesday_${dayKey}`}
                            {...formItemProps([dayKey], "Mittwoch", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="thursday" key="thursday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`thursday_${dayKey}`}
                            {...formItemProps([dayKey], "Donnerstag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="friday" key="friday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`firday_${dayKey}`}
                            {...formItemProps([dayKey], "Freitag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="saturday" key="saturday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`saturday_${dayKey}`}
                            {...formItemProps([dayKey], "Samstag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Row>
              <Col {...colSmall}>
                <Form.List name="sunday" key="sunday">
                  {(fieldsDay, { add, remove }) => (
                    <Space direction="horizontal">
                      {fieldsDay.map(({ key: dayKey }) => {
                        return (
                          <Form.Item
                            key={`sunday_${dayKey}`}
                            {...formItemProps([dayKey], "Sonntag", false)}
                          >
                            <TimePicker.RangePicker
                              allowClear
                              minuteStep={15}
                              format="HH:mm"
                            />
                          </Form.Item>
                        );
                      })}
                      {fieldsDay.length > 1 && (
                        <MinusOutlined
                          onClick={() => {
                            remove(fieldsDay.length - 1);
                          }}
                        />
                      )}
                      <PlusOutlined
                        onClick={() => {
                          add([null, null]);
                        }}
                      />
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Card>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default StoreForm;
