import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { CustomerType } from "../../graphql/schema";

const CustomerTypeSelect = (props: GenericEnumSelectProps<CustomerType>) => {
  return (
    <GenericEnumSelect<CustomerType>
      {...props}
      options={{
        [CustomerType.Standard]: "Standard",
        [CustomerType.Premium]: "Premium",
      }}
    />
  );
};

export default CustomerTypeSelect;
