import { Checkbox, Col, Row } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useGetVariationsSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number];
  onChange?: (value: number[]) => void;
  filter?: any;
}

const VariationCheckboxGroup = ({ value, onChange, filter = {} }: Props) => {
  const { data } = useGetVariationsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where(filter),
        itemsPerPage: 10000,
      },
    },
  });

  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      value={value}
    >
      <Row>
        {data?.variations.items.map((variation) => {
          return (
            <Col span={8} key={variation.id}>
              <Checkbox key={variation.id} value={variation.id}>
                {variation.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );
};

export default VariationCheckboxGroup;
