import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tabs,
} from "antd";
import React from "react";
import { GenericFormProps } from "@3ts/react-ant-crud";
import { Dayjs } from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import formItemProps from "../../helper/form/formItemProps";
import EquipmentsSelect from "./appointment/drawer/EquipmentsSelect";
import MaterialsSelect from "./appointment/drawer/MaterialsSelect";
import {
  UpdateTreatmentInput,
  useDeleteTreatmentProductMutation,
} from "../../graphql/schema";
import CustomersSelect from "../customer/CustomersSelect";
import StoreSelect from "../store/StoreSelect";
import RoomsSelect from "../store/RoomsSelect";
import PractitionersSelect from "../practitioners/PractitionersSelect";
import ProductSelect from "../products/ProductSelect";
import VariationSelect from "../products/VariationSelect";
import StaticTimezoneDatePicker from "../staticTimezoneDatePicker/StaticTimezoneDatePicker";

export interface FormFields {
  dateFrom: Dayjs;
  dateUntil: Dayjs;
  price: string;
  invoiceNumber: string | null;
  paymentStatus: string | null;
  paymentDate: Dayjs;
  discount: string | null;
  materialIds: number[];
  equipmentIds: number[];
  practitionerIds: number[];
  storeId: number;
  bookedPrice: string;
  customerIds: number[];
  roomIds: number[];
  productIds: number[];
  treatmentProducts: {
    id?: number;
    product?: number;
    variation?: number;
    amount: number;
  }[];
}

export const parseFormFields = (fields: FormFields): UpdateTreatmentInput => {
  return {
    dateFrom: fields.dateFrom || new Date(),
    dateUntil: fields.dateUntil || new Date(),
    price: fields.price || "",
    invoiceNumber: fields.invoiceNumber || "",
    paymentStatus: fields.paymentStatus || "",
    paymentDate: fields.paymentDate || new Date(),
    discount: fields.discount || "",
    materialIds: fields.materialIds || [],
    equipmentIds: fields.equipmentIds || [],
    practitionerIds: fields.practitionerIds || [],
    storeId: fields.storeId || 0,
    customerIds: fields.customerIds || [],
    roomIds: fields.roomIds || [],
    productIds: fields.productIds || [],
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
}

const TreatmentForm = ({ formProps }: Props) => {
  const [removeTreatmentProduct] = useDeleteTreatmentProductMutation();
  const { form } = formProps;

  return (
    <Form {...formProps} layout="vertical">
      <Tabs>
        <Tabs.TabPane tab="Allgemein" key="1" forceRender>
          <Card title="Allgemein">
            <Row gutter={24}>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("dateFrom", "Datum", true)}>
                  <StaticTimezoneDatePicker
                    style={{ width: "100%" }}
                    format="DD.MM.YYYY"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("customerIds", "Kunden", true)}>
                  <CustomersSelect />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("dateFrom", "Beginn", true)}>
                  <StaticTimezoneDatePicker.TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("dateUntil", "Ende", true)}>
                  <StaticTimezoneDatePicker.TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Behandlung" key="2" forceRender>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Card title="Behandlung">
              <Row gutter={24}>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("storeId", "Filiale", true)}>
                    <StoreSelect />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("bookedPrice", "gebuchter Preis", true)}
                  >
                    <InputNumber precision={2} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("roomIds", "Räume", false)}>
                    <RoomsSelect />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("materialIds", "Material", false)}
                  >
                    <MaterialsSelect />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("equipmentIds", "Equipment", false)}
                  >
                    <EquipmentsSelect />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("practitionerIds", "Behandler", false)}
                  >
                    <PractitionersSelect />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Form.List
              name="treatmentProducts"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && value.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Mindestens eine Leistung muss angegeben werden",
                      ),
                    );
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                const handleRemove = async (name: number) => {
                  const appointmentProductId = form?.getFieldValue(
                    "appointmentProducts",
                  )[name].id;
                  if (appointmentProductId)
                    await removeTreatmentProduct({
                      variables: {
                        id: appointmentProductId,
                      },
                    });
                  remove(name);
                };
                return (
                  <Card title="Durchgeführte Leistungen" key={0}>
                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <Row gutter={24} key={`Product_${key}`} align="middle">
                          <Col span={9}>
                            <Form.Item
                              {...restField}
                              {...formItemProps(
                                [name, "product"],
                                "Leistung",
                                true,
                              )}
                            >
                              <ProductSelect />
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item
                              {...restField}
                              {...formItemProps(
                                [name, "variation"],
                                "Variation",
                                false,
                              )}
                            >
                              <VariationSelect />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              {...formItemProps(
                                [name, "amount"],
                                "Menge",
                                true,
                              )}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                          <Col
                            span={2}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <MinusCircleOutlined
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => handleRemove(name)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Leistung hinzufügen
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </Card>
                );
              }}
            </Form.List>
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rechnung & Zahlung" key="3" forceRender>
          <Card title="Rechnung & Zahlung">
            <Row gutter={24}>
              <Col {...colSmall}>
                <Form.Item
                  {...formItemProps("invoiceNumber", "Rechnungsnummer", false)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  {...formItemProps("paymentStatus", "Zahlungsstatus", false)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  {...formItemProps("paymentDate", "Zahlungsdatum", false)}
                >
                  <StaticTimezoneDatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("discount", "Rabatt", false)}>
                  <InputNumber precision={2} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("price", "Preis", false)}>
                  <InputNumber precision={2} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default TreatmentForm;
