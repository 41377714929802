import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import {
  GetStoresQuery,
  GetStoresQueryVariables,
  StoreFragment,
  useGetStoresLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Stores = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <DataTable<StoreFragment, GetStoresQuery, GetStoresQueryVariables>
        id="stores"
        title="Filialen"
        singleItemTitle="Filiale"
        query={useGetStoresLazyQuery}
        columns={{
          id: "ID",
          name: "Name",
          address: "Adresse",
          city: "Stadt",
        }}
        onClickRow={(item) => {
          navigate(`/store/${item.id}`);
        }}
        onClickCreate={() => {
          navigate("/createStore");
        }}
      />
    </Content>
  );
};

export default Stores;
