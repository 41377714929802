import { Col, Form, Modal, Row } from "antd";
import React from "react";
import StoreSelect from "../../../../store/StoreSelect";
import { useCalendar } from "../CalendarContext";

interface Props {
  showStoreFilter: boolean;
  setShowStoreFilter: (open: boolean) => void;
}

const StoreFilterModal = ({ showStoreFilter, setShowStoreFilter }: Props) => {
  const { setStoreId } = useCalendar();
  const [form] = Form.useForm();
  return (
    <Modal
      title="Filiale auswählen"
      open={showStoreFilter}
      footer={null}
      onCancel={() => setShowStoreFilter(false)}
    >
      <Form
        onValuesChange={(changedValues, values) => {
          setStoreId(values?.store || undefined);
          setShowStoreFilter(false);
        }}
        form={form}
      >
        <Row
          gutter={10}
          style={{
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          <Col span={24}>
            <Form.Item name="store" label="">
              <StoreSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default StoreFilterModal;
