import { ProductFragment } from "../../graphql/schema";

interface Params {
  id: number | null;
  product: ProductFragment;
  products: ProductFragment[];
}

const isSelfReferencing = ({ id, product, products }: Params): boolean => {
  if (!id) return false;
  if (product.packageProducts.length === 0) return false;
  return product.packageProducts.some((p) => {
    if (p.product.id === id) return true;
    const current = products.find((item) => item.id === p.product.id);
    if (!current) return false;
    return isSelfReferencing({ id, product: current, products });
  });
};

export default isSelfReferencing;
