import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import {
  ExclamationCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useCallback } from "react";
import { GenericFormProps, where } from "@3ts/react-ant-crud";
import { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import formItemProps from "../../helper/form/formItemProps";
import {
  CreateProductInput,
  CustomerType,
  GetProductDocument,
  ProductFragment,
  ResourceType,
  UpdateProductInput,
  useCreateVariationMutation,
  useDeletePackageProductMutation,
  useDeleteProductPriceMutation,
  useGetProductsLazyQuery,
} from "../../graphql/schema";
import ResourceTypeSelect from "./ResourceTypeSelect";
import ProductCategorySelect from "./ProductCategorySelect";
import CustomerTypeSelect from "../customer/CustomerTypeSelect";
import ProductsSelect from "./ProductsSelect";
import Variation from "../../pages/Variation";
import PackageProductSelect from "./PackageProductSelect";
import PackageVariationSelect from "./PackageVariationSelect";
import ResourceRequirementSelect from "./ResourceRequirementSelect";
import StaticTimezoneDatePicker from "../staticTimezoneDatePicker/StaticTimezoneDatePicker";

const { confirm } = Modal;

interface Package {
  packageProductIds: number[];
  packageAmount: number;
}

interface Price {
  customerType: CustomerType;
  validFrom: Dayjs | undefined;
  validTo: Dayjs | undefined;
  price: string;
}

interface PackageProduct {
  id: number;
  productId: number;
  variationId: number | undefined;
}

export interface FormFields {
  id: number;
  index: number;
  productCategoryId: number;
  name: string;
  descriptionShort: string;
  descriptionLong: string;
  prices: Price[];
  taxRate: string;
  package: Package[];
  isSubProduct: boolean;
  blockedBetweenTreatments: number;
  variationIds: number[];
  picture: string;
  isPackage: boolean;
  packageProducts: PackageProduct[];
  packageAmount: number;
  available: boolean;
  serviceCategoryId: number;
  isGoae: boolean;
  goae: string;
  treatments: number[];
  appointments: number[];
  coupons: number[];
  externalId?: string;
  practitioners: number[];
  slug?: string;
  incompatibleProductIds: number[];
  requiredResources: {
    resourceType: ResourceType;
    resourceRequirements: {
      property: string;
      value: number[];
    }[];
  }[];
  totalDuration: number;
}

export const parseFormFields = (
  fields: FormFields,
): UpdateProductInput & CreateProductInput => {
  return {
    name: fields.name,
    index: Number(fields.index) || 0,
    descriptionShort: fields.descriptionShort || "",
    descriptionLong: fields.descriptionLong || "",
    prices:
      fields.prices?.map((price) => ({
        ...price,
        price: String(price.price),
      })) || [],
    taxRate: String(fields.taxRate),
    isSubProduct: fields.isSubProduct,
    blockedBetweenTreatments: fields.blockedBetweenTreatments,
    variationIds: fields.variationIds || [],
    picture: "",
    slug: fields.slug || null,
    isPackage: fields.packageProducts?.length > 0,
    available: fields.available,
    isGoae: fields.isGoae,
    goae: fields.goae,
    packageAmount: fields.packageAmount || 0,
    productCategoryId: fields.productCategoryId,
    incompatibleProductIds: fields.incompatibleProductIds || [],
    requiredResources:
      fields.requiredResources.map((resource) => ({
        resourceType: resource.resourceType,
        resourceRequirements: resource.resourceRequirements.map(
          (requirement) => ({
            property: requirement.property,
            value: requirement.value,
          }),
        ),
      })) || [],
    totalDuration: fields.totalDuration,
    externalId: fields.externalId || "",
    packageProducts:
      fields.packageProducts?.map((product: any) => ({
        id: product.id,
        productId: product.productId,
        variationId: product.variationId,
      })) || [],
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
  refetch?: () => void;
}

interface TabHeaderProps {
  errorList: any[] | undefined;
  title: string;
  tooltip?: string;
  disabled?: boolean;
}

const TabHeader = ({ errorList, title, disabled, tooltip }: TabHeaderProps) => {
  let color = "black";
  let opacity = 1;

  const hasError = () => {
    return errorList
      ? errorList.filter((errorItem: any) => errorItem.errors?.length > 0)
          ?.length > 0
      : false;
  };

  if (hasError()) {
    color = "red";
    opacity = 1;
  }
  if (disabled) {
    color = "black";
    opacity = 0.25;
  }

  return (
    <Tooltip title={disabled ? tooltip : ""}>
      <span
        style={{
          color,
          opacity,
        }}
      >
        {title}
      </span>
    </Tooltip>
  );
};

const ProductForm = ({ formProps }: Props) => {
  const params = useParams();
  const { id } = params;
  const [removePackageProduct] = useDeletePackageProductMutation();
  const [removeProductPrice] = useDeleteProductPriceMutation();
  const { form, initialValues } = formProps;
  const [getProducts] = useGetProductsLazyQuery({
    variables: {
      options: {
        itemsPerPage: 10000,
        ...where<ProductFragment>({
          nextVersionId: null,
        }),
      },
    },
  });

  const [create] = useCreateVariationMutation();

  const handleSave = useCallback(async (fields: any) => {
    try {
      const response = await create({
        variables: {
          dto: {
            name: fields.name,
            totalDuration: fields.totalDuration,
            prices: fields.prices,
            requiredResources: fields.requiredResources,
            productId: initialValues?.id,
          },
        },
        refetchQueries: [GetProductDocument],
      });
      message.success("Variation wurde erstellt");
      return response;
    } catch (e) {
      message.error("Variation konnte nicht erstellt werden");
      return null;
    }
  }, []);

  const updateTotalDuration = useCallback(
    async (packageProducts: any) => {
      const products = await getProducts();
      const total =
        packageProducts?.reduce((acc: number, p: any) => {
          const product = products?.data?.products.items?.find(
            (item: any) => item.id === p.productId,
          );
          const duration = p?.variationId
            ? product?.variations?.find((v: any) => v.id === p.variationId)
                ?.totalDuration
            : product?.totalDuration;
          return acc + (duration || 0);
        }, 0) || 0;
      form?.setFieldsValue({
        totalDuration: total,
      });
    },
    [form?.getFieldValue("packageProducts"), getProducts],
  );

  const changeResourceType = useCallback(
    (value: any, name: any) => {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        requiredResources: form
          ?.getFieldValue("requiredResources")
          .map((resource: any, index: number) =>
            index === name
              ? {
                  ...resource,
                  resourceRequirements:
                    value === ResourceType.Practitioner
                      ? [
                          ...resource.resourceRequirements,
                          {
                            property: "productCategoryId",
                            value:
                              form?.getFieldValue("productCategoryId") !==
                              undefined
                                ? [form?.getFieldValue("productCategoryId")]
                                : [],
                          },
                        ]
                      : [
                          {
                            property: "categoryId",
                            value: [],
                          },
                        ],
                }
              : resource,
          ),
      });
    },
    [form],
  );

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item noStyle shouldUpdate>
        {({
          getFieldValue,
          getFieldsValue,
          getFieldsError,
          setFieldsValue,
          setFieldValue,
        }) => (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={
                <TabHeader
                  errorList={getFieldsError([
                    "name",
                    "taxRate",
                    "totalDuration",
                    "productCategoryId",
                    "blockedBetweenTreatments",
                  ])}
                  title="Allgemein"
                />
              }
              key="1"
              forceRender
            >
              <Card title="Allgemein">
                <Row gutter={24}>
                  <Col {...colSmall}>
                    <Form.Item {...formItemProps("name", "Name", true)}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps(
                        "descriptionShort",
                        "Beschreibung kurz",
                        false,
                      )}
                    >
                      <Input
                        showCount
                        maxLength={50}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps(
                        "descriptionLong",
                        "Beschreibung lang",
                        false,
                      )}
                    >
                      <Input.TextArea
                        showCount
                        maxLength={300}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps(
                        "taxRate",
                        "Steuersatz in %",
                        !form?.getFieldValue("isSubProduct"),
                      )}
                    >
                      <InputNumber
                        precision={2}
                        style={{ width: "100%" }}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps(
                        "blockedBetweenTreatments",
                        "Schonzeit in Tagen",
                        true,
                      )}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Die Leistungskategorie wird von Leistungen verwendet, um Anforderungen an einen Behandler zu definieren."
                      {...formItemProps(
                        "productCategoryId",
                        "Leistungskategorie",
                        !form?.getFieldValue("isSubProduct"),
                      )}
                    >
                      <ProductCategorySelect
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col {...colSmall}>
                    <Form.Item {...formItemProps("picture", "Bild", false)}>
                      <Input
                        placeholder="Bild"
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Die Gesamtdauer der Leistung in Minuten. 5 Minuten Schritte sind erlaubt."
                      {...formItemProps(
                        "totalDuration",
                        "Dauer in Minuten",
                        true,
                      )}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        disabled={
                          form?.getFieldValue("packageProducts")?.length
                        }
                        step={5}
                        min={5}
                        max={1000}
                        parser={(value) => {
                          if (!value) return 5;
                          return (Math.round(Number(value) / 5) *
                            5) as unknown as number;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Die Leistung wird nur in der Buchungsstrecke angezeigt, wenn sie buchbar ist."
                      {...formItemProps("available", "Buchbar", false)}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => {
                          const externalId = form?.getFieldValue("externalId");
                          if (!externalId && e.target.checked) {
                            confirm({
                              title: "Externe ID fehlt",
                              icon: <ExclamationCircleFilled />,
                              content:
                                "Die externe ID fehlt. Bitte füge zuerst eine externe ID hinzu, um eine Leistung buchbar zu machen.",
                              onOk() {
                                setFieldsValue({
                                  ...getFieldsValue(),
                                  available: false,
                                });
                              },
                              onCancel() {
                                setFieldsValue({
                                  ...getFieldsValue(),
                                  available: false,
                                });
                              },
                            });
                          }
                        }}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Subleistungen definieren Unterschritte einer Behandlung. Beispielsweise die Injektion einer Botoxbehandlung, bei der ein Arzt nur 15 Minuten anwesend sein muss und nicht für die Gesamtdauer der Behandlung. Subleistungen können nicht direkt gebucht werden, sondern nur als Teil eines Pakets. Eine Subleistung müssen während der Erstellung als Subleistung definiert werden. Eine Umwandlung ist anschließend nicht mehr möglich."
                      {...formItemProps("isSubProduct", "Unterleistung", false)}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={!!id}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            setFieldsValue({
                              ...getFieldsValue(),
                              isSubProduct: false,
                            });
                          }
                          if (e.target.checked) {
                            confirm({
                              title: "In Subleistung umwandeln?",
                              icon: <ExclamationCircleFilled />,
                              content:
                                "Bei der Umwandlung werden alle bestehenden Paketbestandteile & Preise entfernt.",
                              onOk() {
                                setFieldsValue({
                                  ...getFieldsValue(),
                                  isPackage: false,
                                  isSubProduct: true,
                                  packageProducts: [],
                                  packageAmount: 0,
                                  available: false,
                                  prices: [],
                                  picture: "",
                                  descriptionShort: "",
                                  descriptionLong: "",
                                  taxRate: "0",
                                  externalId: "",
                                  blockedBetweenTreatments: 0,
                                });
                              },
                              onCancel() {
                                setFieldsValue({
                                  ...getFieldsValue(),
                                  isSubProduct: false,
                                });
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Die Reihenfolge, in der die Leistung in der Buchungsstrecke angezeigt wird. Eine niedrigere Zahl bedeutet eine höhere Position."
                      {...formItemProps("index", "Index", false)}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        precision={0}
                        step={1}
                        disabled={form?.getFieldValue("isSubProduct")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabHeader
                  tooltip="Preise können nur von einfachen Leistungen & Paketen definiert werden. Subleistungen können keine Preise haben."
                  errorList={getFieldsError(["prices"])}
                  title="Preise"
                  disabled={form?.getFieldValue("isSubProduct")}
                />
              }
              key="2"
              forceRender
              disabled={form?.getFieldValue("isSubProduct")}
            >
              <Form.List
                name="prices"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      if (form?.getFieldValue("isSubProduct")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Mindestens ein Preis muss angegeben werden"),
                      );
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  const handleAdd = () => {
                    add({
                      customerType: CustomerType.Standard,
                    });
                  };

                  const handleRemove = async (index: number) => {
                    const id = form?.getFieldValue("prices")[index].id;
                    if (id) {
                      await removeProductPrice({
                        variables: {
                          id,
                        },
                      });
                    }
                    remove(index);
                  };
                  return (
                    <Card title="Preise" key="prices_Card">
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Row gutter={24} align="middle" key={`prices_${key}`}>
                            <Col span={6}>
                              <Form.Item
                                tooltip="Die Kundengruppe bestimmt, welcher Kunde den Preis sieht. Standardpreise sind für alle Kunden sichtbar."
                                {...formItemProps(
                                  [name, "customerType"],
                                  "Kundengruppe",
                                  true,
                                )}
                              >
                                <CustomerTypeSelect />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                tooltip="Über das Datum können temporäre Preise definiert werden. Wenn kein Datum angegeben wird, ist der Preis dauerhaft gültig."
                                {...restField}
                                {...formItemProps(
                                  [name, "validFrom"],
                                  "Gültig ab",
                                  false,
                                )}
                              >
                                <StaticTimezoneDatePicker
                                  format="DD.MM.YYYY"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                {...formItemProps(
                                  [name, "validTo"],
                                  "Gültig bis",
                                  false,
                                )}
                              >
                                <StaticTimezoneDatePicker
                                  format="DD.MM.YYYY"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                tooltip="Der Preis darf maximal 100.000 € betragen."
                                {...restField}
                                {...formItemProps(
                                  [name, "price"],
                                  "Preis in €",
                                  true,
                                  [
                                    {
                                      validator: (_, value) =>
                                        value > 100000
                                          ? Promise.reject(
                                              new Error(
                                                "Der Preis darf maximal 100.000 € betragen.",
                                              ),
                                            )
                                          : Promise.resolve(),
                                    },
                                  ],
                                )}
                              >
                                <InputNumber
                                  min={0}
                                  precision={2}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={2}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <MinusCircleOutlined
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => handleRemove(name)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => handleAdd()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Preis hinzufügen
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </Card>
                  );
                }}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabHeader
                  tooltip="Subleistungen können keine Paketleistungen definieren."
                  errorList={getFieldsError(["packageProducts"])}
                  title="Paket"
                  disabled={form?.getFieldValue("isSubProduct")}
                />
              }
              key="3"
              forceRender
              disabled={form?.getFieldValue("isSubProduct")}
            >
              <Form.List name="packageProducts">
                {(fields, { add, remove }) => {
                  const handleAdd = () => {
                    add({});
                  };

                  const handleRemove = async (index: number) => {
                    const id = form?.getFieldValue("packageProducts")[index].id;
                    if (id) {
                      await removePackageProduct({
                        variables: {
                          id,
                        },
                      });
                    }
                    remove(index);
                    await updateTotalDuration(getFieldValue("packageProducts"));
                  };
                  return (
                    <Card title="Paket" key="package_Card">
                      {fields.map((packageProduct) => {
                        return (
                          <Row
                            gutter={24}
                            align="middle"
                            key={`packageProduct_${packageProduct.key}`}
                          >
                            <Col span={7}>
                              <Form.Item
                                tooltip="Leistungen, die in dieses Paket eingefügt werden, können sowohl Subleistungen als auch eigentständige Hauptleistungen sein."
                                {...formItemProps(
                                  [packageProduct.name, "productId"],
                                  "Leistungen",
                                  true,
                                )}
                              >
                                <PackageProductSelect
                                  filterId={form.getFieldValue("id")}
                                  onChange={async (value, totalDuration) => {
                                    const packageProducts = getFieldValue(
                                      "packageProducts",
                                    ).map((product: any, index: number) =>
                                      index === packageProduct.name
                                        ? {
                                            ...product,
                                            variationId: undefined,
                                            productId: value,
                                            totalDuration,
                                          }
                                        : product,
                                    );
                                    setFieldsValue({
                                      ...getFieldsValue(),
                                      packageProducts,
                                    });
                                    await updateTotalDuration(packageProducts);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item noStyle shouldUpdate>
                                {() => (
                                  <Form.Item
                                    {...formItemProps(
                                      [packageProduct.name, "variationId"],
                                      "Variation",
                                      false,
                                    )}
                                  >
                                    <PackageVariationSelect
                                      index={packageProduct.name}
                                      updateTotalDuration={updateTotalDuration}
                                    />
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>
                            <Col
                              span={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <MinusCircleOutlined
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() =>
                                  handleRemove(packageProduct.name)
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => handleAdd()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Leistung hinzufügen
                        </Button>
                      </Form.Item>
                    </Card>
                  );
                }}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Weiteres" key="4" forceRender>
              <Card title="Weiteres">
                <Row gutter={24}>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps(
                        "incompatibleProductIds",
                        "inkompatible Leistungen",
                        false,
                      )}
                    >
                      <ProductsSelect />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      {...formItemProps("isGoae", "GoÄ", false)}
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item {...formItemProps("goae", "GoÄ", false)}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col {...colSmall}>
                    <Form.Item
                      tooltip="Zur Erstellung von Marketing-URLs wird der Slug benötigt."
                      {...formItemProps("slug", "Slug", false)}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabHeader
                  tooltip="Ressourcenanforderungen können nur von einfachen Leistungen & Subleistungen definiert werden. Pakete können keine eigenen Resourcenanforderungen definieren. Bei Paketen müssen die Ressourcenanforderungen von den Paketbestandteilen definiert werden."
                  errorList={getFieldsError(["requiredResources"])}
                  disabled={getFieldValue("packageProducts")?.length}
                  title="Ressourcen"
                />
              }
              key="5"
              disabled={getFieldValue("packageProducts")?.length}
              forceRender
            >
              <Form.List
                name="requiredResources"
                rules={[
                  {
                    validator: async (_, requiredResources) => {
                      if (getFieldValue("packageProducts")?.length)
                        return Promise.resolve();
                      if (!requiredResources || requiredResources.length < 1) {
                        message.error(
                          "Es muss mindestens eine benötigte Ressource geben. Schaue im Tab 'Resourcen', ob die Anforderungen erfüllt sind.",
                        );
                        return Promise.reject(
                          new Error("Es muss mindestens eine Ressource geben"),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  const handleAdd = () => {
                    add({
                      resourceType: "ROOM",
                      resourceRequirements: [
                        {
                          property: "categoryId",
                          value: [],
                        },
                      ],
                    });
                  };
                  const handleRemove = (index: number) => {
                    remove(index);
                  };
                  return (
                    <Card
                      title="Benötigte Ressourcen"
                      key="resourceRequirements"
                    >
                      {fields.map(({ name, key, ...restField }) => {
                        return (
                          <Row
                            gutter={24}
                            key={`requiredRessources_${key}`}
                            align="middle"
                          >
                            <Col span={10}>
                              <Form.Item shouldUpdate noStyle>
                                <Form.Item
                                  {...restField}
                                  {...formItemProps(
                                    [name, "resourceType"],
                                    "Ressourcentyp",
                                    false,
                                  )}
                                >
                                  <ResourceTypeSelect
                                    onChange={(value: any) =>
                                      changeResourceType(value, name)
                                    }
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.List name={[name, "resourceRequirements"]}>
                                {(requirementFields) => {
                                  return (
                                    <Row gutter={20}>
                                      {requirementFields.map(
                                        ({
                                          key: requirementKey,
                                          name: requirementName,
                                        }) => (
                                          <Form.Item
                                            noStyle
                                            shouldUpdate
                                            key={`requirement_${requirementKey}`}
                                          >
                                            <ResourceRequirementSelect
                                              name={name}
                                              requirementName={requirementName}
                                            />
                                          </Form.Item>
                                        ),
                                      )}
                                    </Row>
                                  );
                                }}
                              </Form.List>
                            </Col>
                            <Col
                              span={2}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <MinusCircleOutlined
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => handleRemove(name)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => handleAdd()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Ressourcen hinzufügen
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </Card>
                  );
                }}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabHeader
                  tooltip="Variationen können nur bei einfachen Leistungen erstellt werden. Pakete und Subleistungen können keine Variationen haben."
                  errorList={getFieldsError(["variations"])}
                  disabled={
                    !initialValues?.id ||
                    getFieldValue("packageProducts")?.length ||
                    form?.getFieldValue("isSubProduct")
                  }
                  title="Variationen"
                />
              }
              key="6"
              forceRender
              disabled={
                !initialValues?.id ||
                getFieldValue("packageProducts")?.length ||
                form?.getFieldValue("isSubProduct")
              }
            >
              <Form.List name="variationIds">
                {(fields, { add, remove }, { errors }) => {
                  const handleAdd = async () => {
                    const variation = await handleSave({
                      name: "Variation",
                      totalDuration: 30,
                      prices: form?.getFieldsValue()?.prices || [],
                      requiredResources:
                        form
                          ?.getFieldsValue()
                          ?.requiredResources.map((requiredResource) => ({
                            resourceType: requiredResource.resourceType,
                            resourceRequirements:
                              requiredResource.resourceRequirements.map(
                                (resourceRequirement) => ({
                                  property: resourceRequirement.property,
                                  value: resourceRequirement.value,
                                }),
                              ),
                          })) || [],
                      productId: initialValues?.id,
                    });
                    if (variation?.data?.createVariation?.id)
                      add(variation?.data?.createVariation?.id);
                  };
                  return (
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                    >
                      {fields.map((variation) => (
                        <Form.Item key={`variation_${variation.key}`}>
                          <Card>
                            <Variation index={variation.name} />
                          </Card>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => handleAdd()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Variation hinzufügen
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </Space>
                  );
                }}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabHeader
                  tooltip="Subleistungen können keine externe ID haben."
                  errorList={[]}
                  disabled={getFieldValue("isSubProduct")}
                  title="Externe Konfiguration"
                />
              }
              key="7"
              forceRender
              disabled={form?.getFieldValue("isSubProduct")}
            >
              <Card title="Externe Konfiguration">
                <Form.Item {...formItemProps("externalId", "Externe ID")}>
                  <Input />
                </Form.Item>
              </Card>
            </Tabs.TabPane>
          </Tabs>
        )}
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
