import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  CommunicationType,
  Country,
  CustomerType,
  GetCustomersDocument,
  GetCustomersSelectDocument,
  useCreateCustomerMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import CustomerForm, {
  FormFields,
  parseFormFields,
} from "../components/customer/CustomerForm";

const CreateCustomer = () => {
  const navigate = useNavigate();

  const [create] = useCreateCustomerMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    try {
      await create({
        variables: {
          dto: parseFormFields(fields),
        },
        refetchQueries: [GetCustomersDocument, GetCustomersSelectDocument],
      });
      navigate(`/customers`);
    } catch (error: any) {
      if (
        error?.graphQLErrors &&
        error?.graphQLErrors[0]?.extensions.code === "CONFLICT"
      ) {
        message.error(
          "Ein Kunde mit dieser E-Mail-Adresse oder Telefonnummer existiert bereits. Bitte überprüfen Sie Ihre Eingaben.",
        );
      }
    }
  }, []);

  return (
    <Content>
      <GenericForm
        title="Kunde erstellen"
        singleItemTitle="Kunde"
        formRenderer={(formProps) => <CustomerForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/customers");
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          preferredCommunicationType: CommunicationType.Email,
          cooperation: false,
          category: CustomerType.Standard,
          termsAccepted: false,
          phone: "",
          alternativePhone: "",
          streetNumber: "",
          city: "",
          zipCode: "",
          country: Country.Germany,
          street: "",
        }}
      />
    </Content>
  );
};

export default CreateCustomer;
