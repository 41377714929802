import { Button, Tooltip } from "antd";
import { useCallback, useState } from "react";
import { isAfter, isToday } from "date-fns";
import { AppointmentStatus } from "../../../../graphql/schema";
import { useAppointment } from "../context/AppointmentContext";
import prepareFormValues from "../../../../helper/form/prepareFormValues";

interface Props {
  onClick: (input: any) => Promise<void>;
}

const CancelByCustomerButton = ({ onClick }: Props) => {
  const { appointment, formValues, tab } = useAppointment();
  const [loading, setLoading] = useState(false);
  const showButton = useCallback(() => {
    if (
      appointment?.id &&
      (isToday(new Date(appointment?.dateFrom)) ||
        isAfter(new Date(appointment?.dateFrom), new Date())) &&
      (appointment?.appointmentStatus ===
        AppointmentStatus.ConfirmedWithVirtualPractitioner ||
        appointment?.appointmentStatus === AppointmentStatus.Confirmed)
    ) {
      return true;
    }
    return false;
  }, [appointment]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await onClick({
      input: prepareFormValues(formValues),
      extra: {
        id: appointment?.id,
        appointmentStatus: AppointmentStatus.Cancelled,
      },
    });
    setLoading(false);
  }, [formValues, appointment]);

  if (tab !== "new") return null;
  if (!showButton()) return null;

  return (
    <Tooltip title="Termin auf Kundenwunsch absagen">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        danger
        loading={loading}
        onClick={handleClick}
      >
        Absagen
      </Button>
    </Tooltip>
  );
};

export default CancelByCustomerButton;
