import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { Country } from "../../graphql/schema";

const CountrySelect = (props: GenericEnumSelectProps<Country>) => {
  return (
    <GenericEnumSelect<Country>
      {...props}
      options={{
        [Country.Germany]: "Deutschland",
        [Country.Austria]: "Österreich",
        [Country.Switzerland]: "Schweiz",
        [Country.Belgium]: "Belgien",
        [Country.Denmark]: "Dänemark",
        [Country.France]: "Frankreich",
        [Country.Italy]: "Italien",
        [Country.Luxembourg]: "Luxemburg",
        [Country.Netherlands]: "Niederlande",
        [Country.NorthernIreland]: "Nordirland",
        [Country.Spain]: "Spanien",
        [Country.Sweden]: "Schweden",
        [Country.England]: "England",
        [Country.Finland]: "Finnland",
        [Country.Scotland]: "Schottland",
        [Country.Ireland]: "Irland",
        [Country.Portugal]: "Portugal",
        [Country.Wales]: "Wales",
        [Country.UnitedStates]: "Vereinigte Staaten",
        [Country.Canada]: "Kanada",
        [Country.Norway]: "Norwegen",
        [Country.UnitedKingdom]: "Vereinigtes Königreich",
      }}
    />
  );
};

export default CountrySelect;
