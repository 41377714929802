import { Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useParams } from "react-router-dom";
import { ProductFragment, useGetProductsQuery } from "../../graphql/schema";
import isSelfReferencing from "../../helper/common/isSelfReferencing";

interface Props {
  value?: number | null;
  onChange?: (value: number, duration: number) => void;
  filterId?: number;
  disabled?: boolean;
}

const PackageProductSelect = ({
  value,
  onChange,
  filterId,
  disabled,
}: Props) => {
  const params = useParams();
  const { data } = useGetProductsQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 1000,
        ...where<ProductFragment>({
          nextVersionId: null,
        }),
      },
    },
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) {
          const product = data?.products.items.find((p) => p.id === val);
          onChange(val, product?.totalDuration || 0);
        }
      }}
      filterOption={(input: string, option: any) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      disabled={disabled}
      showSearch
      options={
        data?.products.items
          ?.filter((product) => product.id !== filterId)
          .filter(
            (product) =>
              !isSelfReferencing({
                id: params.id ? parseInt(params.id, 10) : null,
                product,
                products: data?.products.items || [],
              }),
          )
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((product) => {
            return {
              value: product.id,
              label: product.name,
            };
          }) || []
      }
      allowClear
    />
  );
};

export default PackageProductSelect;
