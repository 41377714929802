import React from "react";

import { Button, Tooltip } from "antd";
import { AppointmentStatus } from "../../../../graphql/schema";

interface Props {
  onClick: (force: boolean, status: AppointmentStatus) => Promise<void>;
}

const CreateBlockerButton = ({ onClick }: Props) => {
  return (
    <Tooltip title="Zeitblocker erstellen">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={() => onClick(true, AppointmentStatus.Absent)}
      >
        Zeitblocker erstellen
      </Button>
    </Tooltip>
  );
};

export default CreateBlockerButton;
