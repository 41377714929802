import { Select } from "antd";
import { useGetMaterialsQuery } from "../../../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
}

const MaterialsSelect = ({ value, onChange }: Props) => {
  const { data } = useGetMaterialsQuery({
    variables: {
      options: {
        itemsPerPage: 1000,
      },
    },
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.materials.items.map((material) => {
          return {
            value: material.id,
            label: material.name,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default MaterialsSelect;
