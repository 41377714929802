import { AppointmentStatus } from "../../graphql/schema";

const getStatusName = (status: string) => {
  switch (status) {
    case AppointmentStatus.New:
      return "Neu";
    case AppointmentStatus.Present:
      return "Anwesend";
    case AppointmentStatus.NoShow:
      return "Nicht erschienen";
    case AppointmentStatus.Cancelled:
      return "Abgesagt";
    case AppointmentStatus.CancelledByCustomer:
      return "Abgesagt durch Kunde";
    case AppointmentStatus.Absent:
      return "Zeitblocker";
    case AppointmentStatus.RescheduledByKalialab:
      return "Verschoben durch Kalialab";
    case AppointmentStatus.RescheduledByCustomer:
      return "Verschoben durch Kunde";
    case AppointmentStatus.Confirmed:
      return "Bestätigt";
    case AppointmentStatus.ConfirmedWithVirtualPractitioner:
      return "Bestätigt mit virtuellem Behandler";
    case AppointmentStatus.CustomerArrived:
      return "Kunde ist eingetroffen";
    case AppointmentStatus.Accomplished:
      return "Termin abgeschlossen";
    default:
      return "Unbekannt";
  }
};

export default getStatusName;
