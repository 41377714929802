import { Button } from "antd";
import React from "react";

interface Props {
  calenderRef: any;
}

const ViewOptions = ({ calenderRef }: Props) => {
  return (
    <>
      <Button
        style={{
          margin: 10,
          marginRight: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          marginLeft: 5,
        }}
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("resourceTimeGridDay");
          }
        }}
      >
        Tag
      </Button>
      <Button
        style={{
          margin: 10,
          marginRight: 0,
          borderRadius: 0,
          marginLeft: 0,
        }}
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("timeGridThreeDays");
          }
        }}
      >
        3 Tage
      </Button>
      <Button
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("timeGridWeek");
          }
        }}
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        Woche
      </Button>
    </>
  );
};
export default ViewOptions;
