import React from "react";
import { Col, Form, FormInstance, Input, Row } from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import {
  AppointmentTypeFragment,
  GetAppointmentTypesDocument,
  GetAppointmentTypesQuery,
  useCreateAppointmentTypeMutation,
  useDeleteAppointmentTypeMutation,
  useGetAppointmentTypesLazyQuery,
  useUpdateAppointmentTypeMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";

interface FormFields {
  name: string;
}

const AppointmentType = () => {
  const [create] = useCreateAppointmentTypeMutation();
  const [update] = useUpdateAppointmentTypeMutation();
  const [remove] = useDeleteAppointmentTypeMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
        },
      },
      refetchQueries: [{ query: GetAppointmentTypesDocument }],
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
        },
      },
      refetchQueries: [{ query: GetAppointmentTypesDocument }],
    });
  };

  const handleDelete = async (id: number) => {
    await remove({
      variables: {
        id,
      },
    });
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<
        FormFields,
        AppointmentTypeFragment,
        GetAppointmentTypesQuery
      >
        id="appointment-types"
        title="Terminarten"
        singleItemTitle="Terminart"
        query={useGetAppointmentTypesLazyQuery}
        editFormRenderer={formRenderer}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        columns={{
          name: "Name",
        }}
      />
    </Content>
  );
};

export default AppointmentType;
