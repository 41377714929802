import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import {
  GetCustomersDocument,
  GetPractitionersDocument,
  ResourcePresenceType,
  useCreatePractitionerMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import PractitionerForm, {
  FormFields,
  parseFormFields,
} from "../components/practitioners/PractitionerForm";

const CreatePractitioner = () => {
  const navigate = useNavigate();

  const [create] = useCreatePractitionerMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    const data = await create({
      variables: {
        dto: parseFormFields(fields),
      },
      refetchQueries: [GetCustomersDocument, GetPractitionersDocument],
    });
    navigate(`/practitioner/${data.data?.createPractitioner.id}`);
  }, []);

  return (
    <Content>
      <GenericForm
        title="Behandler erstellen"
        singleItemTitle="Behandler"
        formRenderer={(formProps) => (
          <PractitionerForm
            formProps={formProps}
            isAvailabilitiesVisible={false}
          />
        )}
        onSave={handleSave}
        onBack={() => {
          navigate("/practitioners");
        }}
        initialValues={{
          title: "",
          firstName: "",
          lastName: "",
          availabilitiesConfiguration: [],
          presenceType: ResourcePresenceType.Physical,
        }}
      />
    </Content>
  );
};

export default CreatePractitioner;
