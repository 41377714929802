import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { CommunicationType } from "../../graphql/schema";

const CommunicationTypeSelect = (
  props: GenericEnumSelectProps<CommunicationType>,
) => {
  return (
    <GenericEnumSelect<CommunicationType>
      {...props}
      options={{
        [CommunicationType.Sms]: "SMS",
        [CommunicationType.Email]: "E-Mail",
        [CommunicationType.Phone]: "Telefon",
        [CommunicationType.Whatsapp]: "Whatsapp",
      }}
    />
  );
};

export default CommunicationTypeSelect;
