import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import { useGetIsExistingCustomerLazyQuery } from "customer-client/src/graphql/schema";
import Loading from "../components/base/Loading";
import CustomerForm, {
  FormFields,
  parseFormFields,
} from "../components/customer/CustomerForm";
import Content from "../components/layout/Content";
import {
  CustomerType,
  useDeleteCustomerMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "../graphql/schema";

const Customer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isExisting] = useGetIsExistingCustomerLazyQuery();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetCustomerQuery({
    variables: {
      id,
    },
  });

  const [update] = useUpdateCustomerMutation();
  const [remove] = useDeleteCustomerMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      try {
        const isExistingCustomer = await isExisting({
          variables: {
            email: fields.email,
          },
        });
        if (
          isExistingCustomer?.data?.isExistingCustomer &&
          fields.email !== data?.customer.email
        ) {
          message.error("Kunde mit dieser E-Mail Adresse existiert bereits.");
          return;
        }
        await update({
          variables: {
            id,
            dto: parseFormFields(fields),
          },
        });
        refetch();

        message.success("Die Änderungen wurden gespeichert.");

        navigate("/customers");
      } catch (e) {
        message.error("Es ist ein Fehler aufgetreten.");
      }
    },
    [id, data],
  );

  const handleDelete = useCallback(async () => {
    await remove({
      variables: {
        id,
      },
    });
    navigate("/customers");
  }, [id]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Kunden bearbeiten"
        singleItemTitle="Kunde"
        formRenderer={(formProps) => (
          <CustomerForm formProps={formProps} id={id} />
        )}
        onSave={handleSave}
        onBack={() => {
          navigate("/customers");
        }}
        onDelete={handleDelete}
        initialValues={{
          firstName: data?.customer.firstName || "",
          lastName: data?.customer.lastName || "",
          email: data?.customer.email || "",
          preferredCommunicationType: data?.customer.preferredCommunicationType,
          cooperation: data?.customer.cooperation || false,
          category: data?.customer.category || CustomerType.Standard,
          termsAccepted: data?.customer.termsAccepted || false,
          phone: data?.customer.phone?.includes(" ")
            ? data?.customer.phone?.split(" ")[1]
            : data?.customer.phone || "",
          alternativePhone: data?.customer.alternativePhone?.includes(" ")
            ? data?.customer?.alternativePhone?.split(" ")[1]
            : data?.customer.alternativePhone || "",
          countryCodePhone: data?.customer.phone.includes(" ")
            ? data?.customer.phone.split(" ")[0]
            : "+49",
          countryCodeAlternativePhone:
            data?.customer.alternativePhone?.includes(" ")
              ? data?.customer.alternativePhone.split(" ")[0]
              : "+49",
          note: data?.customer.note || "",
          streetNumber: data?.customer.streetNumber || "",
          city: data?.customer.city || "",
          zipCode: data?.customer.zipCode || "",
          country: data?.customer.country || "",
          street: data?.customer.street || "",
        }}
      />
    </Content>
  );
};

export default Customer;
