import { Select } from "antd";
import { useGetEquipmentCategoriesQuery } from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: any) => void;
  forResource?: boolean;
}

const EquipmentCategorySelect = ({ value, onChange, forResource }: Props) => {
  const { data } = useGetEquipmentCategoriesQuery({
    fetchPolicy: "no-cache",
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.equipmentCategories.items.map((equipmentCategory) => {
          return {
            value: equipmentCategory.id,
            label: equipmentCategory.name,
          };
        }) || []
      }
    />
  );
};

export default EquipmentCategorySelect;
