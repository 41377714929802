import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import Loading from "../components/base/Loading";
import UserForm, {
  FormFields,
  parseFormFields,
} from "../components/user/UserForm";
import Content from "../components/layout/Content";
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useValidateUserExistsMutation,
} from "../graphql/schema";

const User = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetUserQuery({
    variables: {
      id,
    },
  });

  const [update] = useUpdateUserMutation();
  const [remove] = useDeleteUserMutation();
  const [exists] = useValidateUserExistsMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      try {
        const userExists = await exists({
          variables: {
            email: fields.email,
          },
        });
        if (userExists.data?.userExists && fields.email !== data?.user.email) {
          message.error(
            "Ein Mitarbeiter mit dieser E-Mail-Adresse existiert bereits.",
          );
          return;
        }
        await update({
          variables: {
            id,
            dto: parseFormFields(fields),
          },
        });
        refetch();

        message.success("Die Änderungen wurden gespeichert.");

        navigate("/users");
      } catch (error: any) {
        if (error.message === "Cannot use the same password as before.") {
          message.error("Das Passwort kann nicht das gleiche sein wie zuvor.");
        }
      }
    },
    [id, data],
  );

  const handleDelete = useCallback(async () => {
    await remove({
      variables: {
        id,
      },
    });

    message.success("Der Mitarbeiter wurde erfolgreich gelöscht.");

    navigate("/users");
  }, [id]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Mitarbeiter bearbeiten"
        singleItemTitle="Mitarbeiter"
        formRenderer={(formProps) => <UserForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/users");
        }}
        onDelete={handleDelete}
        initialValues={{
          firstName: data?.user.firstName || "",
          lastName: data?.user.lastName || "",
          email: data?.user.email || "",
          phone: data?.user.phone || "",
          streetNumber: data?.user.streetNumber || "",
          city: data?.user.city || "",
          zipCode: data?.user.zipCode || "",
          street: data?.user.street || "",
          role: data?.user.role,
          active: data?.user.active,
        }}
      />
    </Content>
  );
};

export default User;
