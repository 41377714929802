import { Col, Flex, Row, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";
import StatusName from "../../drawer/StatusName";
import { useGetAppointmentQuery } from "../../../../../graphql/schema";

const PopoverContent = ({ event }: any) => {
  const { data } = useGetAppointmentQuery({
    variables: {
      id: parseInt(event.id, 10),
    },
  });
  if (!data) return null;

  return (
    <Row
      style={{
        padding: 5,
        width: 200,
      }}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text
              style={{
                fontSize: "12px",
                fontWeight: "lighter",
              }}
            >
              <StatusName status={data.appointment.appointmentStatus} /> (
              {dayjs(data.appointment.dateFrom).tz().format("HH:mm")} -{" "}
              {dayjs(data.appointment.dateUntil).tz().format("HH:mm")})
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          {data.appointment.customers?.map((customer: any) => (
            <Col span={24}>
              <Flex>
                <UserOutlined
                  style={{
                    padding: 10,
                    fontSize: "20px",
                  }}
                />
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {customer.firstName} {customer.lastName}
                </Typography.Text>
              </Flex>
            </Col>
          ))}
        </Row>
        {event.extendedProps?.products?.length && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Leistungen</Typography.Text>
                </Col>
              </Row>
              <Row>
                {event.extendedProps.products
                  ?.filter((product: any) => !product?.package?.id)
                  ?.map((product: any) => (
                    <Col span={24}>
                      <Typography.Text>{product.name}</Typography.Text>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        )}
        {event.extendedProps.message && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Nachricht</Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Text>
                    {event.extendedProps.message}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {event.extendedProps.internalNotice && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Interne Notiz</Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Text>
                    {event.extendedProps.internalNotice}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default PopoverContent;
