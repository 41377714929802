import { Dayjs } from "dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import generateStaticTimezonePickerConfig from "./configs/generateStaticTimezonePickerConfig";

const StaticTimezoneDatePicker = generatePicker<Dayjs>(
  generateStaticTimezonePickerConfig,
);

const { RangePicker, TimePicker } = StaticTimezoneDatePicker;

export type DatePickerType = typeof StaticTimezoneDatePicker & {
  generatePicker: typeof generatePicker;
};

export type RangePickerType = typeof RangePicker & {
  generatePicker: typeof generatePicker;
};

export type TimePickerType = typeof TimePicker & {
  generatePicker: typeof generatePicker;
};

(StaticTimezoneDatePicker as DatePickerType).generatePicker = generatePicker;
(RangePicker as RangePickerType).generatePicker = generatePicker;
(TimePicker as TimePickerType).generatePicker = generatePicker;

StaticTimezoneDatePicker.TimePicker = TimePicker as TimePickerType;
StaticTimezoneDatePicker.RangePicker = RangePicker as RangePickerType;

export default StaticTimezoneDatePicker as DatePickerType;
