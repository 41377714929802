import { Badge, Button } from "antd";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import AppointmentsInbox from "./AppointmentsInbox";
import StatusFilter from "./StatusFilter";
import PractitionerFilterModal from "./PractitionerFilterModal";
import StoreFilterModal from "../modals/StoreFilterModal";
import { useCalendar } from "../CalendarContext";

interface Props {
  openDrawer: any;
  handleOpenAppointment: any;
}

const FilterOptions = ({ openDrawer, handleOpenAppointment }: Props) => {
  const { practitionerIds, storeId } = useCalendar();
  const [showPractitionerFilter, setShowPractitionerFilter] =
    React.useState(false);
  const [showStoreFilter, setShowStoreFilter] = React.useState(!storeId);
  return (
    <>
      <PractitionerFilterModal
        showFilter={showPractitionerFilter}
        setShowFilter={setShowPractitionerFilter}
      />
      <StoreFilterModal
        setShowStoreFilter={setShowStoreFilter}
        showStoreFilter={showStoreFilter}
      />
      <StatusFilter />
      <AppointmentsInbox
        openDrawer={openDrawer}
        onOpenAppointment={handleOpenAppointment}
      />
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        icon={<ShopOutlined />}
        onClick={() => setShowStoreFilter(true)}
      />
      <Badge offset={[-10, 10]} color="blue" count={practitionerIds.length}>
        <Button
          style={{
            margin: 10,
            marginRight: 5,
            marginLeft: 5,
          }}
          icon={<UserOutlined />}
          onClick={() => setShowPractitionerFilter(true)}
        />
      </Badge>
    </>
  );
};

export default FilterOptions;
