import {
  faBookMedical,
  faBooksMedical,
  faCalendar,
  faDoorClosed,
  faGear,
  faGears,
  faGiftCard,
  faPersonToDoor,
  faStore,
  faUser,
  faUserDoctor,
  faUsers,
  faUsersMedical,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";

interface NavigationElement {
  label: string;
  key?: string;
  path?: string;
  type?: string;
  icon?: IconDefinition;
  children?: NavigationElement[];
}

export const navigationElementsAdmin: NavigationElement[] = [
  {
    label: "Termine",
    icon: faCalendar,
    key: "appointments",
    path: "/appointments",
    type: "group",
    children: [
      {
        label: "Termine",
        key: "appointments",
        path: "/appointments",
        icon: faCalendar,
      },
    ],
  },
  {
    label: "Kunden",
    icon: faUsers,
    key: "customers",
    path: "/customers",
    type: "group",
    children: [
      {
        label: "Kunden",
        key: "customers",
        path: "/customers",
        icon: faUsers,
      },
    ],
  },
  {
    label: "Verwaltung",
    icon: faUser,
    key: "administration",
    path: "/administration",
    type: "group",
    children: [
      {
        label: "Benutzer",
        key: "users",
        path: "/users",
        icon: faUser,
      },
      {
        label: "Filialen",
        key: "stores",
        path: "/stores",
        icon: faStore,
      },
      {
        label: "Behandlerkategorien",
        key: "practitioner-categories",
        path: "/practitioner-categories",
        icon: faUsersMedical,
      },
      {
        label: "Behandler",
        key: "practitioners",
        path: "/practitioners",
        icon: faUserDoctor,
      },
      {
        label: "Leistungskategorien",
        key: "product-categories",
        path: "/product-categories",
        icon: faBooksMedical,
      },
      {
        label: "Leistungen",
        key: "products",
        path: "/products",
        icon: faBookMedical,
      },
      {
        label: "Gerätekategorien",
        key: "equipment-categories",
        path: "/equipment-categories",
        icon: faGears,
      },
      {
        label: "Geräte",
        key: "equipment",
        path: "/equipment",
        icon: faGear,
      },
      {
        label: "Raumkategorien",
        key: "room-categories",
        path: "/room-categories",
        icon: faDoorClosed,
      },
      {
        label: "Räume",
        key: "rooms",
        path: "/rooms",
        icon: faPersonToDoor,
      },
      {
        label: "Gutschein",
        key: "coupon",
        path: "/coupons",
        icon: faGiftCard,
      },
    ],
  },
];

export const navigationElementsUser: NavigationElement[] = [
  {
    label: "Kunden",
    key: "customers",
    path: "/customers",
    icon: faUser,
  },
  {
    label: "Termine",
    key: "appointments",
    path: "/appointments",
    icon: faCalendar,
  },
  {
    label: "Verfügbarkeiten",
    key: "availabilities",
    path: "/availabilities",
    icon: faCalendar,
  },
];

export default { navigationElementsUser, navigationElementsAdmin };
