import React, { useState } from "react";
import { Avatar, Button, Dropdown, Layout as AntLayout, Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorClosed,
  faGear,
  faSidebar,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import styles from "./Layout.module.scss";
import {
  navigationElementsAdmin,
  navigationElementsUser,
} from "../../navigation";
import Logo from "./Logo";
import useAuth from "../../auth/useAuth";
import { Role } from "../../graphql/schema";

const { Header, Sider } = AntLayout;

const Layout = () => {
  const navigate = useNavigate();
  const [currentKey, setCurrentKey] = useState("appointments");

  const { logout, roles } = useAuth();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const navigationElements = roles.includes(Role.Admin)
    ? navigationElementsAdmin
    : navigationElementsUser;

  return (
    <AntLayout className={styles.main}>
      <Sider trigger={null} collapsible collapsed={collapsed} width={220}>
        <Logo collapsed={collapsed} type="light" />
        <Menu
          mode="inline"
          theme="dark"
          style={{
            overflowY: "auto",
            height: "calc(100vh - 100px)",
          }}
          onClick={(e: any) => setCurrentKey(e.key)}
          selectedKeys={[currentKey]}
          defaultOpenKeys={["administration", "appointments", "customers"]}
          items={navigationElements.map(
            ({ label, children, path, icon, type }) => {
              if (!children) {
                return {
                  type,
                  key: path,
                  label,
                  icon: <FontAwesomeIcon icon={icon} />,
                  onClick: () => navigate(path),
                };
              }
              return {
                type,
                key: path,
                label,
                icon: <FontAwesomeIcon icon={icon} />,
                children: children.map((child) => {
                  return {
                    key: `${child.path}`,
                    label: child.label,
                    icon: <FontAwesomeIcon icon={child.icon} />,
                    onClick: () => navigate(child.path),
                  };
                }),
              };
            },
          )}
        />
      </Sider>
      <AntLayout>
        <Header className={styles.header}>
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faSidebar} />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />

          <div>
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Einstellungen",
                    key: "settings",
                    icon: <FontAwesomeIcon icon={faGear} />,
                    onClick: () => {
                      navigate("/userSettings");
                    },
                  },
                  {
                    label: "Abmelden",
                    key: "logout",
                    icon: <FontAwesomeIcon icon={faDoorClosed} />,
                    onClick: logout,
                  },
                ],
              }}
            >
              <Button
                type="text"
                style={{
                  width: 64,
                  height: 64,
                }}
              >
                <Avatar>
                  <FontAwesomeIcon icon={faUser} />
                </Avatar>
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Outlet />
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
