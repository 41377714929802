import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { CouponUsageType } from "../../graphql/schema";

const CouponUsageTypeSelect = (
  props: GenericEnumSelectProps<CouponUsageType>,
) => {
  return (
    <GenericEnumSelect<CouponUsageType>
      {...props}
      options={{
        [CouponUsageType.Single]: "Einfach",
        [CouponUsageType.Multiple]: "Mehrfach",
      }}
    />
  );
};

export default CouponUsageTypeSelect;
