import React, { useCallback, useState } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Modal, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import StoreForm, {
  FormFields,
  parseFormFields,
} from "../components/store/StoreForm";
import Content from "../components/layout/Content";
import {
  GetStoresDocument,
  useDeleteStoreMutation,
  useGetStoreQuery,
  useUpdateStoreMutation,
} from "../graphql/schema";

const { confirm } = Modal;

export const parseBusinessHours = (businessHours: any) => {
  const getHours = (date: string) => {
    return Number(date.split(":")[0]);
  };

  const getMinutes = (date: string) => {
    return Number(date.split(":")[1]);
  };

  const isDate = (date: string | null) => {
    return date !== null && date !== undefined;
  };

  const createInitialBusinessHours = () => {
    return [
      {
        start: null,
        end: null,
      },
    ];
  };
  if (!businessHours || businessHours.length === 0) {
    return createInitialBusinessHours();
  }

  return businessHours.map((businessHour: { start: string; end: string }) => [
    isDate(businessHour.start)
      ? dayjs(
          new Date().setHours(
            getHours(businessHour.start),
            getMinutes(businessHour.start),
            0,
            0,
          ),
        )
      : null,
    isDate(businessHour.end)
      ? dayjs(
          new Date().setHours(
            getHours(businessHour.end),
            getMinutes(businessHour.end),
            0,
            0,
          ),
        )
      : null,
  ]);
};

const Store = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetStoreQuery({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  });

  const [deleting, setDeleting] = useState(false);

  const [update] = useUpdateStoreMutation();
  const [remove] = useDeleteStoreMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      await update({
        variables: {
          id,
          dto: parseFormFields(fields),
        },
      });
      refetch();

      message.success("Die Änderungen wurden gespeichert.");

      navigate("/stores");
    },
    [id],
  );

  const handleDelete = useCallback(async () => {
    confirm({
      type: "warn",
      title: "Filiale löschen",
      content: (
        <>
          <p>
            Mit dieser Filiale werden alle ihr zugeordneten
            <br />
            <br />
            <strong>Termine</strong>
            <br />
            <strong>Virtuellen Behandler</strong>
            <br />
            <strong>Räume</strong>
            <br />
            <strong>Geräte</strong>
            <br />
            <br /> gelöscht.
          </p>
          <p>
            Möchten Sie die Filiale wirklich löschen? Diese Aktion kann nicht
            rückgängig gemacht werden.
          </p>
        </>
      ),
      onOk() {
        setDeleting(true);
        remove({
          variables: {
            id,
          },
          refetchQueries: [GetStoresDocument],
          awaitRefetchQueries: true,
          onCompleted: () => {
            setDeleting(false);
            const storesState = localStorage.getItem("__filter-state-stores");
            if (storesState) {
              const parsedState = JSON.parse(storesState);
              parsedState.page = 1;
              localStorage.setItem(
                "__filter-state-stores",
                JSON.stringify(parsedState),
              );
            }
            navigate("/stores");
          },
        });
      },
    });
  }, [id]);

  if (loading || deleting) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Filiale bearbeiten"
        singleItemTitle="Filiale"
        formRenderer={(formProps) => <StoreForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/stores");
        }}
        onDelete={handleDelete}
        initialValues={{
          name: data?.store.name,
          description: data?.store.description,
          address: data?.store.address,
          city: data?.store.city,
          slug: data?.store.slug,
          monday: parseBusinessHours(data?.store.monday),
          tuesday: parseBusinessHours(data?.store.tuesday),
          wednesday: parseBusinessHours(data?.store.wednesday),
          thursday: parseBusinessHours(data?.store.thursday),
          friday: parseBusinessHours(data?.store.friday),
          saturday: parseBusinessHours(data?.store.saturday),
          sunday: parseBusinessHours(data?.store.sunday),
          zipCode: data?.store.zipCode || "",
          productIds: data?.store.products?.map((product) => product.id) || [],
          externalId: data?.store.externalId || "",
        }}
      />
    </Content>
  );
};

export default Store;
