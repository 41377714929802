import React from "react";
import { Col, Form, FormInstance, Input, InputNumber, Row } from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import {
  GetMaterialsQuery,
  MaterialFragment,
  useCreateMaterialMutation,
  useDeleteMaterialMutation,
  useGetMaterialsLazyQuery,
  useUpdateMaterialMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";
import MaterialCategorySelect from "../components/store/MaterialCategorySelect";
import StoreSelect from "../components/store/StoreSelect";

interface FormFields {
  name: string;
  description: string;
  price: string;
  unit: string;
  manufacturer: string;
  inventory: string;
  storeId: number;
  categoryId: number;
}

const Materials = () => {
  const [create] = useCreateMaterialMutation();
  const [update] = useUpdateMaterialMutation();
  const [remove] = useDeleteMaterialMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
          description: values.description || "",
          price: String(values.price) || "",
          unit: values.unit || "",
          storeId: values.storeId || 0,
          categoryId: values.categoryId || 0,
          manufacturer: values.manufacturer || "",
          inventory: String(values.inventory) || "",
        },
      },
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
          description: values.description || "",
          price: String(values.price) || "",
          unit: values.unit || "",
          storeId: values.storeId,
          categoryId: values.categoryId,
          manufacturer: values.manufacturer || "",
          inventory: String(values.inventory) || "",
        },
      },
    });
  };

  const handleDelete = async (id: number) => {
    await remove({
      variables: {
        id,
      },
    });
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("description", "Beschreibung", true)}>
              <Input placeholder="Beschreibung" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("unit", "Einheit", true)}>
              <Input placeholder="Einheit" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("price", "Preis in €", true)}>
              <InputNumber
                style={{
                  width: "100%",
                }}
                precision={2}
                placeholder="Preis"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("categoryId", "Kategorie", true)}>
              <MaterialCategorySelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("storeId", "Filiale", true)}>
              <StoreSelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("manufacturer", "Hersteller", true)}>
              <Input placeholder="Hersteller" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("inventory", "Bestand", true)}>
              <InputNumber
                style={{
                  width: "100%",
                }}
                step="1"
                precision={2}
                placeholder="Bestand"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<FormFields, MaterialFragment, GetMaterialsQuery>
        id="materials"
        title="Material"
        singleItemTitle="Material"
        query={useGetMaterialsLazyQuery}
        editFormRenderer={formRenderer}
        prepareFormValues={(item: MaterialFragment) => ({
          name: item.name,
          description: item.description,
          price: item.price,
          unit: item.unit,
          storeId: item.store.id,
          categoryId: item.category.id,
          manufacturer: item.manufacturer,
          inventory: item.inventory,
        })}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        columns={{
          name: "Name",
          description: "Beschreibung",
          price: "Preis",
          unit: "Einheit",
          manufacturer: "Hersteller",
          inventory: "Bestand",
        }}
      />
    </Content>
  );
};

export default Materials;
