import React, { useMemo } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { isAfter, isToday } from "date-fns";
import { useAppointment } from "../context/AppointmentContext";

const { confirm } = Modal;

interface Props {
  onClick: (id: number) => void;
}

const DeleteBlockerButton = ({ onClick }: Props) => {
  const { appointment } = useAppointment();

  return (
    <Button
      style={{
        margin: 15,
      }}
      danger
      onClick={() =>
        confirm({
          title: "Zeitblocker löschen",
          icon: <ExclamationCircleFilled />,
          content: "Möchten Sie den Zeitblocker wirklich löschen?",
          onOk() {
            onClick(appointment?.id as number);
          },
          onCancel() {},
        })
      }
      block
    >
      Löschen
    </Button>
  );
};

export default DeleteBlockerButton;
