import React, { useCallback, useMemo } from "react";
import { Button, Tooltip } from "antd";
import { useAppointment } from "../context/AppointmentContext";
import prepareFormValues from "../../../../helper/form/prepareFormValues";
import { AppointmentStatus } from "../../../../graphql/schema";

interface Props {
  onClick: (input: any) => Promise<void>;
}

const CreateBlockerButton = ({ onClick }: Props) => {
  const { formValues, appointment } = useAppointment();

  const handleClick = useCallback(async () => {
    await onClick({
      input: prepareFormValues(formValues),
      extra: {
        id: appointment?.id,
        appointmentStatus: AppointmentStatus.Absent,
      },
      successMessage: "Änderungen wurden gespeichert",
    });
  }, [appointment, formValues]);

  return (
    <Tooltip title="Zeitblocker aktualisieren">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={handleClick}
      >
        Aktualisieren
      </Button>
    </Tooltip>
  );
};

export default CreateBlockerButton;
