import "./instrument";
import React from "react";
import { createRoot } from "react-dom/client";
import { CrudProvider } from "@3ts/react-ant-crud";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "./main.scss";
import "@3ts/react-ant-crud/dist/style.css";
import de_DE from "antd/locale/de_DE";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { BrowserRouter } from "react-router-dom";
import "dayjs/locale/de";

import { ConfigProvider } from "antd";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AuthProvider from "./auth/authProvider";
import App from "./App";

dayjs.locale("de");
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Berlin");

// Init GraphQL connection
const graphClient = new ApolloClient({
  uri: `${
    (window as { apiURL?: string }).apiURL || import.meta.env.VITE_SERVER_URI
  }/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ApolloProvider client={graphClient}>
      <AuthProvider>
        <CrudProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#324E61",
                colorSuccess: "#52c41a",
              },
            }}
            locale={de_DE}
          >
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </CrudProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
