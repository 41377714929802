import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AppointmentStatus } from "../../../../graphql/schema";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(customParseFormat);
dayjs.locale("de");
dayjs.tz.setDefault("Europe/Berlin");

interface CalendarContextType {
  viewDates: {
    start: Date;
    end: Date;
  };
  eventInfo: any;
  initialDate: Date;
  initialView: string;
  practitionerIds: number[];
  storeId: undefined | number;
  appointmentStatus: AppointmentStatus[];
  openDrawer: boolean;
  openCustomerDrawer: null | number;
  setDates: (dates: { start: Date; end: Date }) => void;
  setInitialDate: (date: Date) => void;
  setInitialView: (view: string) => void;
  setPractitionerIds: (ids: number[]) => void;
  setStoreId: (id: number) => void;
  setAppointmentStatus: (status: AppointmentStatus[]) => void;
  setEventInfo: (info: any) => void;
  setOpenDrawer: (open: boolean) => void;
  setOpenCustomerDrawer: (id: number | null) => void;
}

export const CalendarContext = createContext<CalendarContextType>({
  viewDates: {
    start: dayjs(new Date()).tz(undefined, true).startOf("week").toDate(),
    end: dayjs(new Date())
      .tz(undefined, true)
      .startOf("week")
      .add(7, "days")
      .toDate(),
  },
  eventInfo: {},
  initialDate: new Date(),
  initialView: "timeGridWeek",
  practitionerIds: [],
  storeId: undefined,
  appointmentStatus: [
    AppointmentStatus.New,
    AppointmentStatus.Confirmed,
    AppointmentStatus.ConfirmedWithVirtualPractitioner,
    AppointmentStatus.Accomplished,
    AppointmentStatus.NoShow,
    AppointmentStatus.CustomerArrived,
    AppointmentStatus.Absent,
  ],
  openDrawer: false,
  openCustomerDrawer: null,
  setDates: () => {},
  setInitialDate: () => {},
  setInitialView: () => {},
  setPractitionerIds: () => {},
  setStoreId: () => {},
  setAppointmentStatus: () => {},
  setEventInfo: () => {},
  setOpenDrawer: () => {},
  setOpenCustomerDrawer: () => {},
});

export const useCalendar = () => useContext(CalendarContext);

interface Props {
  children: React.ReactNode;
}

export const CalendarProvider = ({ children }: Props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openCustomerDrawer, setOpenCustomerDrawer] = React.useState<
    number | null
  >(null);
  const [filter, setFilter] = React.useState<any>({
    practitionerIds: [],
    storeId: parseInt(localStorage.getItem("storeId") || "", 10) || undefined,
    appointmentStatus: [
      AppointmentStatus.New,
      AppointmentStatus.Confirmed,
      AppointmentStatus.ConfirmedWithVirtualPractitioner,
      AppointmentStatus.Accomplished,
      AppointmentStatus.NoShow,
      AppointmentStatus.CustomerArrived,
      AppointmentStatus.Absent,
    ],
  });
  const [viewDates, setViewDates] = React.useState<any>({
    start: dayjs(new Date()).tz(undefined, true).startOf("week").toDate(),
    end: dayjs(new Date())
      .tz(undefined, true)
      .startOf("week")
      .add(7, "days")
      .toDate(),
  });
  const [initialDate, setInitialDate] = React.useState<any>(new Date());
  const [initialView, setInitialView] = React.useState<any>(
    localStorage.getItem("view") || "timeGridWeek",
  );
  const [eventInfo, setEventInfo] = React.useState<any>();

  const getView = () => {
    if (location.state) {
      const view = location?.state?.view;
      if (view) return view;
    }
    if (params.get("view")) {
      const view = params.get("view");
      return view;
    }
    if (localStorage.getItem("view")) {
      const view = localStorage.getItem("view");
      if (view) return view;
    }
    return initialView;
  };

  const getStart = () => {
    if (location.state) {
      const date = location?.state?.date;
      if (date) return new Date(date);
    }
    if (params.get("date")) {
      const date = params.get("date");
      return new Date(date);
    }
    return new Date(viewDates.start);
  };

  const getStoreId = () => {
    if (location?.state?.storeId) {
      const { storeId } = location.state;
      if (storeId) return storeId;
    }
    if (localStorage.getItem("storeId")) {
      const storeId = parseInt(localStorage.getItem("storeId") || "", 10);
      if (storeId) return storeId;
    }
    if (params.get("storeId")) {
      const storeId = parseInt(params.get("storeId") || "", 10);
      if (storeId) return storeId;
    }
    return undefined;
  };

  const getStatusFilter = () => {
    if (location.state) {
      const status = location?.state?.status;
      if (status)
        return !filter?.appointmentStatus.includes(status)
          ? [...filter.appointmentStatus, status]
          : filter.appointmentStatus;
    }
    if (params.get("status")) {
      const status = params.get("status");
      return !filter?.appointmentStatus.includes(status)
        ? [...filter.appointmentStatus, status]
        : filter.appointmentStatus;
    }
    return filter.appointmentStatus;
  };

  const setDates = useCallback((dates) => {
    setViewDates({
      start: dates.start,
      end: dates.end,
    });
  }, []);

  const handleInitialView = () => {
    const view = getView();
    const date = getStart();
    const storeId = getStoreId();
    const statusFilter = getStatusFilter();
    setInitialView(view);
    setInitialDate(date);
    setFilter({ ...filter, storeId, appointmentStatus: statusFilter });
  };

  useEffect(() => {
    handleInitialView();
  }, []);

  const handleView = (view: string) => {
    localStorage.setItem("view", view);
    setInitialView(view);
  };

  const value = useMemo(() => {
    return {
      eventInfo,
      viewDates,
      initialDate,
      initialView,
      practitionerIds: filter.practitionerIds,
      storeId: filter.storeId,
      appointmentStatus: filter.appointmentStatus,
      openDrawer,
      openCustomerDrawer,
      setDates,
      setInitialDate,
      setInitialView: handleView,
      setPractitionerIds: (ids: number[]) =>
        setFilter((prev: any) => ({ ...prev, practitionerIds: ids })),
      setStoreId: (id: number) =>
        setFilter((prev: any) => {
          localStorage.setItem("storeId", id?.toString() || "");
          return {
            ...prev,
            storeId: id,
          };
        }),
      setAppointmentStatus: (status: AppointmentStatus[]) =>
        setFilter((prev: any) => ({ ...prev, appointmentStatus: status })),
      setEventInfo: (info: any) => setEventInfo(info),
      setOpenDrawer,
      setOpenCustomerDrawer,
    };
  }, [
    initialDate,
    initialView,
    viewDates,
    eventInfo,
    filter,
    openDrawer,
    openCustomerDrawer,
  ]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};
