import { Button, Checkbox, Col, Form, Modal, Row } from "antd";
import React from "react";
import { where } from "@3ts/react-ant-crud";
import { useGetPractitionersQuery } from "../../../../../graphql/schema";
import Loading from "../../../../base/Loading";
import formItemProps from "../../../../../helper/form/formItemProps";
import { useCalendar } from "../CalendarContext";

interface Props {
  showFilter: boolean;
  setShowFilter: (open: boolean) => void;
}

const PractitionerFilterModal = ({ showFilter, setShowFilter }: Props) => {
  const { setPractitionerIds, storeId } = useCalendar();
  const [form] = Form.useForm();
  const { data: practitioners } = useGetPractitionersQuery({
    variables: {
      options: {
        ...where({
          "stores.id": storeId,
        }),
        itemsPerPage: 50,
      },
    },
  });

  const handleFilter = () => {
    const values = form.getFieldsValue();
    setPractitionerIds(
      values.practitioners
        .filter((p: any) => p.selected)
        .map((p: any, i: number) => p.id),
    );
    setShowFilter(false);
  };

  const resetFilter = () => {
    form.resetFields();
    setPractitionerIds([]);
  };

  if (!practitioners) return <Loading />;

  return (
    <Modal
      open={showFilter}
      title="Behandlerfilter"
      onOk={() => handleFilter()}
      onCancel={() => setShowFilter(false)}
      footer={
        <Row
          style={{
            marginTop: "20px",
          }}
        >
          <Col
            span={12}
            style={{
              textAlign: "left",
            }}
          >
            <Button
              type="text"
              onClick={() => {
                setShowFilter(false);
                resetFilter();
              }}
            >
              Zurücksetzen
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" onClick={() => handleFilter()}>
              Filtern
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          practitioners: [
            { id: 0, selected: false },
            ...(practitioners?.practitioners.items.map((p) => ({
              id: p.id,
              selected: false,
            })) || []),
          ],
        }}
      >
        <Form.List name="practitioners">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => {
                const practitioner = practitioners?.practitioners.items.find(
                  (p) =>
                    p.id ===
                    form.getFieldValue(["practitioners", field.key, "id"]),
                );
                if (!practitioner && field.key !== 0) return null;
                return (
                  <Form.Item
                    {...formItemProps(
                      ["practitioners", field.key, "selected"],
                      "",
                      false,
                    )}
                    key={field.key}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        form.setFieldsValue({
                          practitioners: {
                            [field.key]: {
                              id: practitioner?.id || 0,
                              selected: e.target.checked,
                            },
                          },
                        });
                      }}
                    >
                      {practitioner
                        ? `${practitioner.firstName} ${practitioner.lastName}`
                        : `Nicht zugeordnet`}
                    </Checkbox>
                  </Form.Item>
                );
              })}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default PractitionerFilterModal;
