import React from "react";
import { Col, Form, FormInstance, Input, message, Row } from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import {
  GetProductCategoriesDocument,
  GetProductCategoriesQuery,
  ProductCategoryFragment,
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoriesLazyQuery,
  useUpdateProductCategoryMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";

interface FormFields {
  name: string;
  coupon: [];
  practitionerIds: [];
  serviceIds: [];
}

const ProductCategories = () => {
  const [create] = useCreateProductCategoryMutation();
  const [update] = useUpdateProductCategoryMutation();
  const [remove] = useDeleteProductCategoryMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
          practitionerIds: values.practitionerIds || [],
          productIds: values.serviceIds || [],
        },
      },
      refetchQueries: [GetProductCategoriesDocument],
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
          practitionerIds: values.practitionerIds || [],
          productIds: values.serviceIds || [],
        },
      },
      refetchQueries: [GetProductCategoriesDocument],
    });
  };

  const handleDelete = async (id: number) => {
    try {
      await remove({
        variables: {
          id,
        },
      });
    } catch (e: any) {
      if (e.graphQLErrors?.[0]?.extensions?.code === "CONFLICT") {
        message.error(
          "Kategorie kann nicht gelöscht werden, da der Kategorie Leistungen zugewiesen sind.",
        );
        throw new Error(
          "Kategorie kann nicht gelöscht werden, da der Kategorie Leistungen zugewiesen sind.",
        );
      }
    }
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<
        FormFields,
        ProductCategoryFragment,
        GetProductCategoriesQuery
      >
        id="product-categories"
        title="Leistungskategorien"
        singleItemTitle="Leistungskategorie"
        query={useGetProductCategoriesLazyQuery}
        editFormRenderer={formRenderer}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        columns={{
          name: "Name",
        }}
      />
    </Content>
  );
};

export default ProductCategories;
