import { Button, Tooltip } from "antd";
import { useCallback, useMemo, useState } from "react";
import { format, isAfter, isToday } from "date-fns";
import dayjs from "dayjs";
import { AppointmentStatus } from "../../../../graphql/schema";
import prepareFormValues from "../../../../helper/form/prepareFormValues";
import { useAppointment } from "../context/AppointmentContext";

interface Props {
  onClick: (input: any) => Promise<void>;
}

const ConfirmAppointmentButton = ({ onClick }: Props) => {
  const { formValues, appointment, tab } = useAppointment();
  const [loading, setLoading] = useState(false);

  const showButton = useMemo(() => {
    const appointmentExists = appointment?.id;
    const appointmentIsToday = isToday(new Date(appointment?.dateFrom));
    const appointmentIsAfterToday = isAfter(
      new Date(appointment?.dateFrom),
      new Date(),
    );
    const appointmentIsNew =
      appointment?.appointmentStatus === AppointmentStatus.New;

    return !!(
      appointmentExists &&
      (appointmentIsToday || appointmentIsAfterToday) &&
      appointmentIsNew
    );
  }, [appointment]);

  const startChanged = useMemo(() => {
    if (!appointment?.dateFrom) return false;
    const start = format(new Date(appointment?.dateFrom), "dd.MM.yyyy HH:mm");
    const currentStart = format(
      dayjs(formValues.dateFrom).toDate(),
      "dd.MM.yyyy HH:mm",
    );
    if (!start) return false;
    if (start !== currentStart) return true;
    return false;
  }, [formValues, appointment]);

  const endChanged = useMemo(() => {
    if (!appointment?.dateUntil) return false;
    const end = format(new Date(appointment?.dateUntil), "dd.MM.yyyy HH:mm");
    const currentEnd = format(
      dayjs(formValues.dateUntil).toDate(),
      "dd.MM.yyyy HH:mm",
    );
    if (end !== currentEnd) return true;
    return false;
  }, [formValues, appointment]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await onClick({
      input: prepareFormValues(formValues),
      extra: {
        id: appointment?.id,
        appointmentStatus: AppointmentStatus.Confirmed,
      },
    });
    setLoading(false);
  }, [appointment, formValues]);

  if (tab !== "new") return null;
  if (!showButton) return null;
  if (startChanged || endChanged) return null;

  return (
    <Tooltip title="Termin bestätigten. Verfügbar sobald ein Behandler ausgewählt wurde.">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        loading={loading}
        onClick={handleClick}
      >
        Annehmen
      </Button>
    </Tooltip>
  );
};

export default ConfirmAppointmentButton;
