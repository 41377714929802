import React from "react";
import { Button, Space, Typography } from "antd";
import { AppointmentStatus } from "../../../../graphql/schema";
import { useAppointment } from "../context/AppointmentContext";

const AppointmentTab = () => {
  const { tab, setTab, appointment } = useAppointment();
  const isAbsent = appointment?.appointmentStatus === AppointmentStatus.Absent;

  return (
    <Space>
      {(!appointment?.id || isAbsent) && (
        <Button type="text" onClick={() => setTab("new")}>
          <Typography.Text
            style={{
              fontWeight: tab === "new" ? "bold" : "normal",
            }}
          >
            Termin
          </Typography.Text>
        </Button>
      )}
      {(!appointment?.id || isAbsent) && (
        <Button type="text" onClick={() => setTab("blocker")}>
          <Typography.Text
            style={{
              fontWeight: tab === "blocker" ? "bold" : "normal",
            }}
          >
            Zeitblocker
          </Typography.Text>
        </Button>
      )}
      {appointment?.id && (
        <Typography.Text
          style={{
            fontWeight: tab === "blocker" ? "bold" : "normal",
          }}
        >
          ID: {appointment.id}
        </Typography.Text>
      )}
    </Space>
  );
};

export default AppointmentTab;
