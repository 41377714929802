import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { CouponDiscountType } from "../../graphql/schema";

const CouponDiscountTypeSelect = (
  props: GenericEnumSelectProps<CouponDiscountType>,
) => {
  return (
    <GenericEnumSelect<CouponDiscountType>
      {...props}
      options={{
        [CouponDiscountType.Amount]: "Betrag",
        [CouponDiscountType.Percentage]: "Prozent",
      }}
    />
  );
};

export default CouponDiscountTypeSelect;
