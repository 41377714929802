import React from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
} from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import { DeleteOutlined } from "@ant-design/icons";
import {
  GetRoomDocument,
  GetRoomsDocument,
  GetRoomsQuery,
  RoomFragment,
  useCountRoomAllocationsLazyQuery,
  useCreateRoomMutation,
  useDeleteRoomMutation,
  useGetRoomsLazyQuery,
  useUpdateRoomMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";
import RoomCategorySelect from "../components/store/RoomTypeSelect";
import StoreSelect from "../components/store/StoreSelect";
import EquipmentsSelect from "../components/treatment/appointment/drawer/EquipmentsSelect";

const { confirm } = Modal;

interface FormFields {
  name: string;
  categoryId: number;
  storeId: number;
  equipmentIds: number[];
}

const RoomDrawer = ({
  id,
  drawerVisible,
  setDrawerVisible,
  form,
  onCreate,
  onUpdate,
  onDelete,
}: {
  id: number | null;
  drawerVisible: boolean;
  setDrawerVisible: (visible: boolean) => void;
  form: FormInstance;
  onCreate: (values: Partial<FormFields>) => void;
  onUpdate: (id: number, values: Partial<FormFields>) => void;
  onDelete: (id: number) => void;
}) => {
  return (
    <Drawer
      open={drawerVisible}
      title={id ? "Raum bearbeiten" : "Neuer Raum"}
      onClose={() => {
        setDrawerVisible(false);
      }}
      extra={
        id && (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              onDelete(id);
            }}
          />
        )
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Kategorie des Raumes bestimmt, welche Art von Behandlungen in diesem Raum durchgeführt werden können."
              {...formItemProps("categoryId", "Kategorie", true)}
            >
              <RoomCategorySelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Filiale, in der sich der Raum befindet."
              {...formItemProps("storeId", "Filiale", true)}
            >
              <StoreSelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Geräte, die sich in diesem Raum befinden."
              {...formItemProps("equipmentIds", "Geräte", false)}
            >
              <EquipmentsSelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => {
                if (id) {
                  onUpdate(id, form.getFieldsValue());
                } else {
                  onCreate(form.getFieldsValue());
                }
                setDrawerVisible(false);
              }}
            >
              Speichern
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

const Rooms = () => {
  const [form] = Form.useForm();
  const [create] = useCreateRoomMutation();
  const [update] = useUpdateRoomMutation();
  const [remove] = useDeleteRoomMutation();
  const [countAllocations] = useCountRoomAllocationsLazyQuery();
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [id, setId] = React.useState<number | null>(null);

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
          categoryId: values.categoryId || 1,
          storeId: values.storeId || 1,
          equipmentIds: values.equipmentIds || [],
        },
      },
      refetchQueries: [GetRoomsDocument, GetRoomDocument],
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
          categoryId: values.categoryId || 1,
          storeId: values.storeId || 1,
          equipmentIds: values.equipmentIds || [],
        },
      },
      refetchQueries: [GetRoomsDocument, GetRoomDocument],
    });
  };

  const handleDelete = async (id: number) => {
    const pastAllocations = await countAllocations({
      variables: {
        id,
        inFuture: false,
      },
    });

    const futureAllocations = await countAllocations({
      variables: {
        id,
        inFuture: true,
      },
    });
    confirm({
      type: "warn",
      title: "Raum löschen",
      content: (
        <>
          <p>
            Der Raum hat{" "}
            <strong>{pastAllocations?.data?.countRoomAllocations || 0}</strong>{" "}
            vergangene Termine und{" "}
            <strong>{futureAllocations.data?.countRoomAllocations || 0}</strong>{" "}
            zukünftige Termine.
          </p>
          <p>
            Möchten Sie den Raum wirklich löschen? Diese Aktion kann nicht
            rückgängig gemacht werden.
          </p>
        </>
      ),
      onOk() {
        remove({
          variables: {
            id,
          },
          refetchQueries: [GetRoomsDocument],
        });
        setDrawerVisible(false);
      },
    });
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Kategorie des Raumes bestimmt, welche Art von Behandlungen in diesem Raum durchgeführt werden können."
              {...formItemProps("categoryId", "Kategorie", true)}
            >
              <RoomCategorySelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Filiale, in der sich der Raum befindet."
              {...formItemProps("storeId", "Filiale", true)}
            >
              <StoreSelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Geräte, die sich in diesem Raum befinden."
              {...formItemProps("equipmentIds", "Geräte", false)}
            >
              <EquipmentsSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<FormFields, RoomFragment, GetRoomsQuery>
        id="room"
        title="Räume"
        singleItemTitle="Raum"
        query={useGetRoomsLazyQuery}
        editFormRenderer={formRenderer}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(values) => {
          return {
            name: values.name || "",
            categoryId: values.category.id,
            storeId: values.store?.id,
            equipmentIds: values.equipment.map((e) => e.id),
          };
        }}
        columns={{
          name: "Name",
          category: {
            label: "Kategorie",
            render: (status) => {
              return status.name;
            },
          },
          store: {
            label: "Filiale",
            render: (store) => store?.name ?? "gelöscht",
          },
        }}
      />
    </Content>
  );
};

export default Rooms;
