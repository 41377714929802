import { Checkbox, Form, message, Modal, Row, Space, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React, { useEffect, useMemo } from "react";
import { isAfter, isToday } from "date-fns";
import dayjs from "dayjs";
import NoShowButton from "../buttons/NoShowButton";
import ArrivedButton from "../buttons/ArrivedButton";
import AccomplishedButton from "../buttons/AccomplishedButton";
import RefuseConfirmButton from "../buttons/RefuseConfirmButton";
import ConfirmAppointmentButton from "../buttons/ConfirmAppointmentButton";
import SaveButton from "../buttons/SaveButton";
import CreateAppointmentButton from "../buttons/CreateAppointmentButton";
import {
  AppointmentStatus,
  GetAppointmentDocument,
  GetAppointmentsBetweenDatesDocument,
  useCreateAppointmentMutation,
  useDeleteAppointmentMutation,
  useUpdateAppointmentMutation,
} from "../../../../graphql/schema";
import formItemProps from "../../../../helper/form/formItemProps";
import prepareFormValues from "../../../../helper/form/prepareFormValues";
import CancelByCustomerButton from "../buttons/CancelByCustomerButton";
import { useAppointment } from "../context/AppointmentContext";
import CreateBlockerButton from "../buttons/CreateBlockerButton";
import UpdateBlockerButton from "../buttons/UpdateBlockerButton";
import DeleteBlockerButton from "../buttons/DeleteBlockerButton";

const { confirm } = Modal;

interface Props {
  tab: "blocker" | "new";
  closeDrawer: () => void;
}

const NotifyCustomerCheckbox = ({
  enabled,
  notifyCustomer,
  setNotifyCustomer,
}: {
  enabled: boolean;
  notifyCustomer: boolean;
  setNotifyCustomer: (value: boolean) => void;
}) => {
  if (!enabled) return null;
  return (
    <div>
      <Form
        initialValues={{
          notifyCustomer,
        }}
      >
        <Form.Item
          style={{
            marginBottom: 0,
          }}
          {...formItemProps("notifyCustomer", "")}
          valuePropName="checked"
        >
          <Checkbox
            checked={notifyCustomer}
            onChange={(e) => setNotifyCustomer(true)}
          >
            <Tooltip title="Die eingetragenen Kunden erhalten eine Benachrichtigung abhänging ihrer Kommunikationspräferenz">
              Kunden benachrichtigen
            </Tooltip>
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

const Footer = ({ tab, closeDrawer }: Props) => {
  const [create] = useCreateAppointmentMutation();
  const [update] = useUpdateAppointmentMutation();
  const [remove] = useDeleteAppointmentMutation();
  const { appointment, form, formValues } = useAppointment();
  const [notifyCustomer, setNotifyCustomer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const appointmentIsInFuture = useMemo(() => {
    const dateFrom = dayjs(formValues.dateFrom);
    if (dateFrom) {
      return dateFrom.isAfter(new Date()) || dateFrom.isSame(new Date());
    }
    return false;
  }, [formValues]);

  useEffect(() => {
    if (loading) {
      messageApi.open({
        content: "Lade...",
        type: "loading",
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [loading]);

  const handleDelete = async (id: number | undefined) => {
    try {
      if (!id) return;
      await remove({
        variables: {
          id,
        },
        refetchQueries: [GetAppointmentsBetweenDatesDocument],
      });
      message.success("Blocker wurde gelöscht");
      closeDrawer();
    } catch (e) {
      message.error("Blocker konnte nicht gelöscht werden");
    }
  };

  const handleUpdate = async ({
    input,
    extra = {},
    force = false,
    requirement = true,
    successMessage,
    errorMessage,
  }: {
    input: any;
    extra?: any;
    force?: boolean;
    requirement?: boolean;
    successMessage?: string;
    errorMessage?: string;
  }) => {
    setLoading(true);
    if (requirement) {
      try {
        if (tab === "new") {
          await form
            ?.validateFields([], { recursive: true })
            .catch((errorInfo: any) => {
              message.error("Bitte füllen Sie alle Pflichtfelder aus");
              throw errorInfo;
            });
        }
        if (tab === "blocker") {
          await form
            ?.validateFields(["practitionerIds"])
            .catch((errorInfo: any) => {
              message.error("Bitte füllen Sie alle Pflichtfelder aus");
              throw errorInfo;
            });
        }
        if (appointment?.id) {
          await update({
            variables: {
              id: appointment?.id,
              dto: {
                ...input,
                ...extra,
                forceCreation: force,
                notifyCustomer,
              },
            },
            refetchQueries: [
              GetAppointmentsBetweenDatesDocument,
              GetAppointmentDocument,
            ],
          });

          setLoading(false);

          closeDrawer();
          message.success(successMessage || "Änderungen wurden gespeichert");
        }
      } catch (error: any) {
        setLoading(false);

        if (
          error?.graphQLErrors &&
          error?.graphQLErrors[0]?.extensions.code === "CONFLICT"
        ) {
          confirm({
            title: "Die Kapazitäten sind ausgelastet",
            icon: <ExclamationCircleFilled />,
            content: "Möchten Sie Ihre Änderungen dennoch durchführen?",
            onOk() {
              handleUpdate({
                input,
                extra,
                force: true,
                successMessage,
                errorMessage,
              });
            },
            onCancel() {},
          });
        }
      }
    }
    setNotifyCustomer(false);
  };

  const handleConfirmNew = async (
    forceCreation = false,
    appointmentStatus = AppointmentStatus.New,
  ) => {
    setLoading(true);

    try {
      if (tab === "new") {
        await form
          ?.validateFields({ recursive: true })
          .catch((errorInfo: any) => {
            message.error("Bitte füllen Sie alle Pflichtfelder aus");
            throw errorInfo;
          });
      }
      if (tab === "blocker") {
        await form
          ?.validateFields(["practitionerIds"])
          .catch((errorInfo: any) => {
            message.error("Bitte füllen Sie alle Pflichtfelder aus");
            throw errorInfo;
          });
      }
      await create({
        variables: {
          dto: {
            ...prepareFormValues(formValues),
            forceCreation,
            notifyCustomer: true,
            appointmentStatus,
          },
        },
        refetchQueries: [
          GetAppointmentsBetweenDatesDocument,
          GetAppointmentDocument,
        ],
      });

      setLoading(false);

      closeDrawer();
      message.success("Termin wurde erstellt");
    } catch (error: any) {
      setLoading(false);

      if (
        error?.graphQLErrors &&
        error?.graphQLErrors[0]?.extensions.code === "CONFLICT"
      ) {
        confirm({
          title: "Die Kapazitäten sind ausgelastet",
          icon: <ExclamationCircleFilled />,
          content: "Möchten Sie Ihre Änderungen dennoch durchführen?",
          onOk() {
            handleConfirmNew(true, appointmentStatus);
          },
          onCancel() {},
        });
      }
    }
    setNotifyCustomer(false);
  };

  const notifyCustomerEnabled = useMemo(() => {
    if (tab === "blocker") return false;
    if (
      isToday(appointment?.dateFrom) ||
      isAfter(appointment?.dateFrom, new Date())
    )
      return true;
    return false;
  }, [tab, appointment]);

  return (
    <div>
      <Row>
        <NotifyCustomerCheckbox
          enabled={notifyCustomerEnabled}
          notifyCustomer={notifyCustomer}
          setNotifyCustomer={setNotifyCustomer}
        />
      </Row>
      <Row>
        <Space
          direction="horizontal"
          style={{
            alignContent: "space-between",
          }}
        >
          {contextHolder}
          <NoShowButton
            tab={tab}
            onClick={(input: any) => handleUpdate(input)}
          />
          <ArrivedButton onClick={(input: any) => handleUpdate(input)} />
          <AccomplishedButton onClick={(input: any) => handleUpdate(input)} />
          <CancelByCustomerButton
            onClick={(input: any) => handleUpdate(input)}
          />
          <RefuseConfirmButton onClick={(input: any) => handleUpdate(input)} />
          <ConfirmAppointmentButton
            onClick={(input: any) => handleUpdate(input)}
          />
          <SaveButton onClick={(input: any) => handleUpdate(input)} />
          {appointmentIsInFuture && (
            <>
              {tab === "new" && !appointment?.id && (
                <CreateAppointmentButton onClick={() => handleConfirmNew()} />
              )}
              {tab === "blocker" && (
                <>
                  {!appointment?.id ? (
                    <CreateBlockerButton
                      onClick={(input: any) =>
                        handleConfirmNew(false, AppointmentStatus.Absent)
                      }
                    />
                  ) : (
                    <>
                      <UpdateBlockerButton onClick={handleUpdate} />
                      <DeleteBlockerButton onClick={handleDelete} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Space>
      </Row>
    </div>
  );
};

export default Footer;
