import React from "react";
import { Col, Form, FormInstance, Input, message, Row } from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import {
  GetPractitionerCategoriesDocument,
  GetPractitionerCategoriesQuery,
  PractitionerCategoryFragment,
  useCreatePractitionerCategoryMutation,
  useDeletePractitionerCategoryMutation,
  useGetPractitionerCategoriesLazyQuery,
  useUpdatePractitionerCategoryMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";

interface FormFields {
  name: string;
}

const PractitionerCategories = () => {
  const [create] = useCreatePractitionerCategoryMutation();
  const [update] = useUpdatePractitionerCategoryMutation();
  const [remove] = useDeletePractitionerCategoryMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
        },
      },
      refetchQueries: [GetPractitionerCategoriesDocument],
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
        },
      },
    });
  };

  const handleDelete = async (id: number) => {
    try {
      await remove({
        variables: {
          id,
        },
      });
    } catch (e: any) {
      if (e.graphQLErrors?.[0]?.extensions?.code === "CONFLICT") {
        message.error(
          "Kategorie kann nicht gelöscht werden, da der Kategorie Behandler zugewiesen sind.",
        );
        throw new Error(
          "Kategorie kann nicht gelöscht werden, da der Kategorie Behandler zugewiesen sind.",
        );
      }
    }
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<
        FormFields,
        PractitionerCategoryFragment,
        GetPractitionerCategoriesQuery
      >
        id="practitoiner-categories"
        title="Behandlerkategorien"
        singleItemTitle="Behandlerkategorie"
        query={useGetPractitionerCategoriesLazyQuery}
        editFormRenderer={formRenderer}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        columns={{
          name: "Name",
        }}
      />
    </Content>
  );
};

export default PractitionerCategories;
