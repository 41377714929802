import {
  Button,
  Col,
  Drawer,
  Form,
  List,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { format, isAfter, isBefore } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { where } from "@3ts/react-ant-crud";
import StoreSelect from "../store/StoreSelect";
import formItemProps from "../../helper/form/formItemProps";
import {
  StoreFragment,
  useGetAppointmentsBetweenDatesByPractitionerQuery,
} from "../../graphql/schema";
import StaticTimezoneDatePicker from "../staticTimezoneDatePicker/StaticTimezoneDatePicker";
import AssociatedAppointmentsModal from "./AssociatedAppointmentsModal";

interface Props {
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
  availability: any;
  info: any;
  handleSubmit: (values: any) => void;
  handleRemove: (index: number) => void;
}

const AvailabilityDrawer = ({
  isDrawerVisible,
  handleCloseDrawer,
  info,
  availability,
  handleSubmit,
  handleRemove,
}: Props) => {
  const params = useParams();
  const { id } = params;
  const [form] = Form.useForm();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  useEffect(() => {
    form.resetFields();
  }, [info, availability]);

  const handleSave = () => {
    const values = {
      ...form.getFieldsValue(),
    };
    if (info?.event?.publicId) {
      values.id = info.event.publicId;
    }
    handleSubmit({ ...values, id: availability?.id });
  };

  const UpdateContentModal = () => {
    const navigate = useNavigate();

    const calculateOffset = () => {
      const defaultAvailability = {
        start: dayjs().startOf("day").toDate(),
        end: dayjs().endOf("day").toDate(),
      };
      if (!form?.getFieldsValue()?.times) return defaultAvailability;
      const inputStart: dayjs.Dayjs = form?.getFieldsValue()?.times[0];
      const inputEnd: dayjs.Dayjs = form?.getFieldsValue()?.times[1];
      if (!inputStart) return defaultAvailability;
      if (!inputEnd) return defaultAvailability;
      if (
        availability?.start &&
        isAfter(inputStart.toDate(), new Date(availability.start))
      ) {
        return {
          start: new Date(availability.start),
          end: inputStart.toDate(),
        };
      }
      if (
        availability?.end &&
        isBefore(inputEnd.toDate(), new Date(availability.end))
      ) {
        return { start: inputEnd.toDate(), end: new Date(availability.end) };
      }
      return {
        start: inputStart.startOf("day").toDate(),
        end: inputStart.startOf("day").toDate(),
      };
    };

    const { start, end } = calculateOffset();
    const { data, loading } = useGetAppointmentsBetweenDatesByPractitionerQuery(
      {
        variables: {
          practitionerId: availability?.practitioner?.id,
          startDate: start?.toISOString(),
          endDate: end?.toISOString(),
        },
        skip: !updateModalVisible,
      },
    );

    const onNavigate = (item: any) => {
      navigate(`/appointments`, {
        state: {
          id: item.id,
          storeId: item.store?.id,
          date: item.date,
          status: item.appointmentStatus,
        },
      });
    };
    return (
      <Modal
        open={updateModalVisible}
        onCancel={() => setUpdateModalVisible(false)}
        title="Verfügbarkeit anpassen"
        okText="Anpassen"
        okButtonProps={{
          disabled:
            loading || !!data?.appointmentsBetweenDatesByPractitioner.length,
        }}
        onOk={() => {
          handleSave();
          setUpdateModalVisible(false);
        }}
      >
        {loading ? <Skeleton active /> : null}{" "}
        {!loading && data?.appointmentsBetweenDatesByPractitioner.length ? (
          <Row>
            <Col span={24}>
              <p>
                Um diese Verfügbarkeit zu anzupassen, lösen Sie bitte zuerst
                alle Verknüpfungen zu existierenden Terminen auf:
              </p>
            </Col>
            <Col span={24}>
              <List
                dataSource={data.appointmentsBetweenDatesByPractitioner}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key={`delete_${item.id}`}
                        type="link"
                        onClick={() => onNavigate(item)}
                      >
                        Anpassen
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <Typography.Text>{`Datum: ${format(
                          item.dateFrom,
                          "dd.MM.yyyy",
                        )}`}</Typography.Text>
                      }
                      description={`Uhrzeit: ${format(
                        item.dateFrom,
                        "HH:mm",
                      )} - ${format(item.dateUntil, "HH:mm")} Uhr`}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <p>
                Diese Verfügbarkeit hat keine Verknüpfungen zu existierenden
                Terminen. Sie können diese Verfügbarkeit anpassen.
              </p>
            </Col>
          </Row>
        )}
      </Modal>
    );
  };

  return (
    <Drawer
      open={isDrawerVisible}
      onClose={handleCloseDrawer}
      extra={
        <Space>
          {info?.event ? (
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => setDeleteModalVisible(true)}
            />
          ) : null}
        </Space>
      }
    >
      <Form
        layout="vertical"
        onFinish={
          availability?.id ? () => setUpdateModalVisible(true) : handleSave
        }
        form={form}
        initialValues={
          availability
            ? {
                times: [
                  availability.start
                    ? dayjs(new Date(availability.start))
                    : dayjs(info.startStr),
                  availability.end
                    ? dayjs(new Date(availability.end))
                    : dayjs(info.endStr),
                ],
                storeId: availability?.store?.id,
              }
            : {
                times: [
                  info?.startStr ? dayjs(info.startStr) : null,
                  info?.endStr ? dayjs(info.endStr) : null,
                ],
              }
        }
      >
        <Space direction="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps("times", "Zeitfenster", true)}>
                <StaticTimezoneDatePicker.RangePicker
                  picker="time"
                  format="HH:mm"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item {...formItemProps("storeId", "Filiale", true)}>
                <StoreSelect
                  filter={where<StoreFragment>({
                    "practitioners.id": id,
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Speichern
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
      {deleteModalVisible && (
        <AssociatedAppointmentsModal
          id={availability?.id}
          deleteModalVisible={deleteModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          handleRemove={() => handleRemove(availability?.id)}
          practitionerId={availability?.practitioner?.id}
          startDate={availability?.start}
          endDate={availability?.end}
        />
      )}
      {updateModalVisible ? <UpdateContentModal /> : null}
    </Drawer>
  );
};

export default AvailabilityDrawer;
