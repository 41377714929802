import { Col, Popover, Row, Typography } from "antd";
import React from "react";
import { CrownFilled } from "@ant-design/icons";
import PopoverContent from "./PopoverContent";
import { CustomerType } from "../../../../../graphql/schema";

interface Props {
  info: any;
}

const EventPopover = ({ info }: Props) => {
  const { event } = info;
  return (
    <Popover
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        padding: 2,
      }}
      content={<PopoverContent event={event} />}
      trigger="hover"
      placement="right"
    >
      {event?.extendedProps?.practitioners &&
        event?.extendedProps.practitioners.map((practitioner: any) => (
          <Row key={practitioner.id}>
            <Col
              span={20}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography.Text
                style={{
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {practitioner.firstName} {practitioner.lastName}
              </Typography.Text>
            </Col>
          </Row>
        ))}
      {event.extendedProps.customers.map((customer: any) => (
        <Row key={customer.id}>
          <Col
            span={20}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography.Text
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              {customer.firstName} {customer.lastName}
            </Typography.Text>
          </Col>
        </Row>
      ))}
      {event.extendedProps.customers.some(
        (customer: any) => customer.category === CustomerType.Premium,
      ) && (
        <CrownFilled
          style={{
            fontSize: "13px",
            position: "absolute",
            bottom: 5,
            left: 5,
          }}
        />
      )}
    </Popover>
  );
};

export default EventPopover;
