import { Store } from "../../graphql/schema";

interface Props {
  store: Partial<Omit<Store, "products">> | undefined;
}

const getBusinessHours = ({ store }: Props) => {
  if (!store) return [];
  return [
    store?.monday
      ? {
          daysOfWeek: [1],
          startTime: store?.monday ? store?.monday[0]?.start : "00:00",
          endTime: store?.monday ? store?.monday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [1],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.tuesday
      ? {
          daysOfWeek: [2],
          startTime: store?.tuesday ? store?.tuesday[0]?.start : "00:00",
          endTime: store?.tuesday ? store?.tuesday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [2],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.wednesday
      ? {
          daysOfWeek: [3],
          startTime: store?.wednesday ? store?.wednesday[0]?.start : "00:00",
          endTime: store?.wednesday ? store?.wednesday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [3],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.thursday
      ? {
          daysOfWeek: [4],
          startTime: store?.thursday ? store?.thursday[0]?.start : "00:00",
          endTime: store?.thursday ? store?.thursday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [4],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.friday?.length
      ? {
          daysOfWeek: [5],
          startTime: store?.friday ? store?.friday[0]?.start : "00:00",
          endTime: store?.friday ? store?.friday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [5],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.saturday?.length
      ? {
          daysOfWeek: [6],
          startTime: store?.saturday ? store?.saturday[0]?.start : false,
          endTime: store?.saturday ? store?.saturday[0]?.end : false,
        }
      : {
          daysOfWeek: [6],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
    store?.sunday?.length
      ? {
          daysOfWeek: [0],
          startTime: store?.sunday ? store?.sunday[0]?.start : "00:00",
          endTime: store?.sunday ? store?.sunday[0]?.end : "00:00",
        }
      : {
          daysOfWeek: [0],
          startTime: "00:00",
          endTime: "00:00",
          display: "inverse-background",
        },
  ];
};

export default getBusinessHours;
