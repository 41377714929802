import React from "react";
import { Layout as AntLayout } from "antd";

const { Content: BaseContent } = AntLayout;

interface Props {
  noPadding?: boolean;
  children: any;
}

const Content = ({ children, noPadding }: Props) => {
  return (
    <BaseContent
      style={{
        padding: noPadding ? 0 : 40,
      }}
    >
      {children}
    </BaseContent>
  );
};

export default Content;
