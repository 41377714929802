import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { message, Modal, Upload, UploadFile } from "antd";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { UploadRequestOption } from "rc-upload/lib/interface";
import {
  DocumentType,
  useCreateUploadUrlMutation,
  useDeleteDocumentMutation,
  useGetDocumentsByCustomerQuery,
} from "../../graphql/schema";
import Loading from "../base/Loading";

const { Dragger } = Upload;

const { confirm } = Modal;

const DeleteFile = ({ file }: { file: UploadFile }) => {
  const params = useParams();
  const id = parseInt(params.id!, 10);

  const [loading, setLoading] = useState<boolean>(false);

  const [deleteFile] = useDeleteDocumentMutation();

  const handleDelete = useCallback(async () => {
    setLoading(true);

    const { name } = file;
    const response = await deleteFile({
      variables: {
        customerId: id,
        documentType: DocumentType.GenericImage,
        name,
      },
      refetchQueries: ["GetDocumentsByCustomer"],
    });
    if (response.data?.deleteDocument) {
      message.success("Datei gelöscht");
    } else {
      message.error("Datei konnte nicht gelöscht werden");
    }
    setLoading(false);
  }, [id, file, deleteFile]);

  return (
    <DeleteOutlined
      onClick={async () => {
        confirm({
          title: "Datei löschen",
          content: "Möchten Sie diese Datei wirklich löschen?",
          cancelText: "Abbrechen",
          icon: <ExclamationCircleOutlined />,
          okText: "Löschen",
          okButtonProps: { loading },
          onCancel: () => {},
          onOk: async () => handleDelete(),
        });
      }}
    />
  );
};

const FileUpload = () => {
  const params = useParams();
  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetDocumentsByCustomerQuery({
    variables: {
      customerId: id,
    },
  });

  const [createUploadUrl] = useCreateUploadUrlMutation();

  const handleBeforeUpload = useCallback(async (file: File) => {
    if (file.size / 1024 / 1024 > 10) {
      message.error("Dokumente dürfen maximal 10MB groß sein!");
      return false;
    }
    return true;
  }, []);

  const customRequestHandler = useCallback(
    async (options: UploadRequestOption) => {
      const file: File = options.file as File;

      if (!file) {
        return;
      }

      try {
        const response = await createUploadUrl({
          variables: {
            customerId: id,
            name: file.name,
            documentType: DocumentType.GenericImage,
          },
        });

        if (!response.data?.singleFileUploadUrl) {
          message.error("Fehler beim Abrufen der Upload-URL");
          return;
        }

        const signedUrl = response.data?.singleFileUploadUrl;

        const result = await fetch(signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (result.ok) {
          message.success(`${file.name} erfolgreich hochgeladen`);
          refetch();
        } else {
          message.error("Hochladen fehlgeschlagen");
        }
      } catch (error: any) {
        message.error(
          `${file.name} hochladen fehlgeschlagen: ${error.message}`,
        );
      }
    },
    [createUploadUrl, id, refetch],
  );

  if (loading) return <Loading />;

  return (
    <Dragger
      accept=".pdf,.jpg,.jpeg,.png,"
      multiple={false}
      fileList={(data?.listDocumentsByCustomer as any[]) || []}
      customRequest={customRequestHandler}
      beforeUpload={handleBeforeUpload}
      onPreview={(file) => {
        window.open(file.url, "_blank");
      }}
      showUploadList={{
        showRemoveIcon: true,
        showDownloadIcon: true,
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Klicken oder ziehen Sie eine Datei in dieses Feld, um sie hochzuladen
      </p>
    </Dragger>
  );
};

export default FileUpload;
