import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  Role,
  useGetUsersLazyQuery,
  UserFragment,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Users = () => {
  const navigate = useNavigate();
  return (
    <Content>
      <DataTable<UserFragment, GetUsersQuery, GetUsersQueryVariables>
        id="user"
        title="Mitarbeiter"
        singleItemTitle="Mitarbeiter"
        query={useGetUsersLazyQuery}
        columns={{
          firstName: "Vorname",
          lastName: "Nachname",
          email: "E-Mail",
          role: {
            label: "Rolle",
            render: (v) => {
              if (v === Role.Admin) return "Administrator";
              if (v === Role.User) return "Mitarbeiter";
              return "Unbekannt";
            },
          },
        }}
        onClickCreate={() => {
          navigate("/createUser");
        }}
        onClickRow={(item) => {
          navigate(`/user/${item.id}`);
        }}
      />
    </Content>
  );
};

export default Users;
