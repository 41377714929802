import { Badge, Button, Checkbox, Form, Modal, Space } from "antd";
import React from "react";
import { FilterOutlined } from "@ant-design/icons";
import { AppointmentStatus } from "../../../../../graphql/schema";
import StatusName from "../../drawer/StatusName";
import { useCalendar } from "../CalendarContext";

const StatusFilter = () => {
  const { appointmentStatus, setAppointmentStatus } = useCalendar();
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();

  const appointmentStatusOptions = Object.values(AppointmentStatus).map(
    (status) => {
      return {
        label: status,
        value: status,
      };
    },
  );

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    setAppointmentStatus(values.appointmentStatus);
    setVisible(false);
  };

  const handleReset = () => {
    const resetConfig = [
      AppointmentStatus.New,
      AppointmentStatus.Confirmed,
      AppointmentStatus.ConfirmedWithVirtualPractitioner,
      AppointmentStatus.Accomplished,
      AppointmentStatus.NoShow,
      AppointmentStatus.CustomerArrived,
      AppointmentStatus.Absent,
    ];
    setAppointmentStatus(resetConfig);
    form.setFieldsValue({
      appointmentStatus: resetConfig,
    });
    setVisible(false);
  };
  return (
    <Badge
      count={appointmentStatus?.length || 0}
      style={{
        top: 10,
        right: 10,
      }}
    >
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        title="Statusfilter"
        footer={
          <Space
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="text"
              onClick={() => {
                handleReset();
              }}
            >
              Zurücksetzen
            </Button>
            <Button type="primary" onClick={() => handleSubmit()}>
              Anwenden
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          initialValues={{
            appointmentStatus,
          }}
        >
          <Form.Item name="appointmentStatus">
            <Checkbox.Group
              style={{
                width: "100%",
              }}
            >
              {appointmentStatusOptions.map((option) => (
                <Checkbox
                  value={option.value}
                  key={option.value}
                  style={{
                    marginRight: 0,
                    marginBottom: "8px",
                    width: "100%",
                  }}
                >
                  <StatusName status={option.value} />
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={() => setVisible(true)}
        icon={<FilterOutlined />}
      />
    </Badge>
  );
};

export default StatusFilter;
