import { Select } from "antd";
import { useGetRoomCategoriesQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
  disabled?: boolean;
}

const RoomCategoriesSelect = ({ value, onChange, disabled }: Props) => {
  const { data } = useGetRoomCategoriesQuery({
    fetchPolicy: "no-cache",
  });

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.roomCategories.items.map((roomCategory) => {
          return {
            value: roomCategory.id,
            label: roomCategory.name,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default RoomCategoriesSelect;
