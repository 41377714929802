import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  GetProductDocument,
  GetProductsDocument,
  GetProductsSelectDocument,
  useCreateProductMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import ProductForm, {
  FormFields,
  parseFormFields,
} from "../components/products/ProductForm";

const CreateProduct = () => {
  const navigate = useNavigate();

  const [create] = useCreateProductMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    try {
      if (fields.available && !fields.externalId) {
        message.error(
          "Leistung ist buchbar, hat aber keine externe ID. Bitte trage eine externe ID ein.",
        );
        return;
      }
      const data = await create({
        variables: {
          dto: parseFormFields(fields),
        },
        refetchQueries: [
          GetProductsDocument,
          GetProductDocument,
          GetProductsSelectDocument,
        ],
      });
      message.success("Leistung wurde erstellt");
      navigate(`/product/${data.data?.createProduct.id}`);
    } catch (e) {
      message.error("Leistung konnte nicht erstellt werden");
    }
  }, []);

  return (
    <Content>
      <GenericForm
        title="Leistung erstellen"
        singleItemTitle="Leistung"
        formRenderer={(formProps) => <ProductForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/products");
        }}
        initialValues={{
          name: "",
          descriptionShort: "",
          descriptionLong: "",
          taxRate: "",
          blockedBetweenTreatments: 0,
          picture: "",
          isPackage: false,
          isSubProduct: false,
          packageAmount: 0,
          isGoae: false,
          requiredResources: [],
          goae: "",
          available: true,
        }}
      />
    </Content>
  );
};

export default CreateProduct;
