import { Card, Col, Form, Input, Row } from "antd";
import React from "react";
import formItemProps from "../../helper/form/formItemProps";
import { GenericFormProps } from "@3ts/react-ant-crud";

export interface FormFields {
  password: string;
}

interface Props {
  formProps: GenericFormProps<FormFields>;
}

const OwnUserForm = ({ formProps }: Props) => {
  const labelCol = {
    sm: 6,
  };

  return (
    <Form {...formProps}>
      <Row gutter={[24, 24]}>
        <Col
          md={{
            span: 11,
          }}
          sm={{
            span: 24,
          }}
          style={{
            width: "100%",
          }}
        >
          <Card title="Passwort">
            <Form.Item
              {...formItemProps("password", "Passwort")}
              labelCol={labelCol}
            >
              <Input.Password placeholder="Neues Passwort" />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default OwnUserForm;
