import { Form, message, Select, Typography } from "antd";
import { QueryTuple } from "@apollo/client/react/types/types";
import { useCallback, useEffect, useMemo } from "react";
import { where } from "@3ts/react-ant-crud";
import {
  EquipmentCategoryFragment,
  EquipmentFragment,
  GetEquipmentCategoriesQuery,
  GetEquipmentsQuery,
  GetEquipmentsQueryVariables,
  GetPractitionerCategoriesQuery,
  GetRoomCategoriesQuery,
  GetRoomsQuery,
  GetRoomsQueryVariables,
  PractitionerCategoryFragment,
  ResourceAllocationRequirementOutput,
  ResourceType,
  RoomCategoryFragment,
  RoomFragment,
  useGetEquipmentCategoriesLazyQuery,
  useGetEquipmentsLazyQuery,
  useGetPractitionerCategoriesLazyQuery,
  useGetRoomCategoriesLazyQuery,
  useGetRoomsLazyQuery,
  useValidateVacanciesMutation,
} from "../../../graphql/schema";
import PractitionersWithAvailabilitiesSelect from "../appointment/drawer/PractitionersWithAvailabilitiesSelect";
import "./AllocationsSelect.css";
import { useCalendar } from "../appointment/calendar/CalendarContext";
import useValidateVacancies from "../../../helper/hooks/useValidateVacancies";
import dayjs, { Dayjs } from "dayjs";

interface Props<ItemType, QueryType, QueryVariables> {
  onChange?: (val: number) => void;
  value?: number | null;
  query: () => QueryTuple<QueryType, any>;
  queryVariables?: QueryVariables;
  renderLabel: (item: ItemType) => string;
  dataKey: string;
}

interface LabelProps {
  start: Dayjs;
  end: Dayjs;
  name: string;
  resourceType: ResourceType;
  resourceId: number;
  resourceRequirements: ResourceAllocationRequirementOutput[];
}

const GenericAllocationCategoryLabel = <ItemType, QueryType>({
  query,
  dataKey,
  values,
}: {
  query: () => QueryTuple<QueryType, any>;
  dataKey: string;
  values: number[];
}) => {
  const [fetchData, { data, loading }] = query();

  useEffect(() => {
    fetchData({
      variables: {
        options: {
          itemsPerPage: 10000,
        },
      },
    });
  }, []);

  const getCategoryNameById = useCallback(
    (id: number) => {
      return (data as any)[dataKey]?.items?.find(
        (category: any) => category.id === id,
      )?.name;
    },
    [data],
  );

  const concatenateCategoryNames = useMemo(() => {
    if (!values || !data) {
      return "";
    }
    return values.reduce((label, value, index) => {
      const name = getCategoryNameById(value);
      if (!name) {
        return label;
      }

      return label + (index > 0 ? ` / ${name}` : name);
    }, "");
  }, [values, data]);

  return <Typography>{concatenateCategoryNames || ""}</Typography>;
};

const AllocationCategoryLabel = ({
  resourceRequirements,
  resourceType,
}: LabelProps) => {
  switch (resourceType) {
    case ResourceType.Practitioner:
      return (
        <GenericAllocationCategoryLabel<
          PractitionerCategoryFragment,
          GetPractitionerCategoriesQuery
        >
          query={useGetPractitionerCategoriesLazyQuery}
          dataKey="practitionerCategories"
          values={
            (resourceRequirements.find(
              (resourceRequirement) =>
                resourceRequirement.property === "categoryId",
            )?.value as number[]) || []
          }
        />
      );
    case ResourceType.Room:
      return (
        <GenericAllocationCategoryLabel<
          RoomCategoryFragment,
          GetRoomCategoriesQuery
        >
          query={useGetRoomCategoriesLazyQuery}
          dataKey="roomCategories"
          values={
            (resourceRequirements.find(
              (resourceRequirement) =>
                resourceRequirement.property === "categoryId",
            )?.value as number[]) || []
          }
        />
      );
    case ResourceType.Equipment:
      return (
        <GenericAllocationCategoryLabel<
          EquipmentCategoryFragment,
          GetEquipmentCategoriesQuery
        >
          query={useGetEquipmentCategoriesLazyQuery}
          dataKey="equipmentCategories"
          values={
            (resourceRequirements.find(
              (resourceRequirement) =>
                resourceRequirement.property === "categoryId",
            )?.value as number[]) || []
          }
        />
      );
    default:
      return <Typography>Unknown Category</Typography>;
  }
};

const AllocationLabel = ({
  start,
  end,
  name,
  resourceId,
  resourceRequirements,
  resourceType,
}: LabelProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          display: "flex",
        }}
      >
        <AllocationCategoryLabel
          start={start}
          end={end}
          name={name}
          resourceType={resourceType}
          resourceId={resourceId}
          resourceRequirements={resourceRequirements}
        />
        {name && (
          <span
            style={{
              marginLeft: 5,
            }}
          >
            {" - "}
            {name}
          </span>
        )}
      </Typography>
      <Typography>
        {start.format("HH:mm")}
        {" - "}
        {end.format("HH:mm")}
      </Typography>
    </div>
  );
};

const GenericAllocationSelect = <
  ItemType,
  QueryType = any,
  QueryVariables = any,
>({
  onChange,
  value,
  query,
  queryVariables,
  renderLabel,
  dataKey,
}: Props<ItemType, QueryType, QueryVariables>) => {
  const [fetchData, { data, loading }] = query();

  useEffect(() => {
    fetchData({
      variables: {
        options: {
          itemsPerPage: 10000,
          ...((queryVariables as any)?.options
            ? (queryVariables as any).options
            : {}),
        },
      },
    });
  }, []);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input: string, option: any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={
        data
          ? (data as any)[dataKey]?.items?.map((item: any) => {
              return {
                value: item.id,
                label: renderLabel(item),
              };
            })
          : []
      }
      value={value}
    />
  );
};

const AllocationCategorySelect = ({
  onChange,
  value,
  resourceType,
  allocationIndex,
  productIndex,
  resourceRequirements,
}: {
  resourceType: ResourceType;
  onChange?: (val: number) => void;
  value?: number | null;
  allocationIndex: number;
  productIndex: number;
  resourceRequirements: ResourceAllocationRequirementOutput[];
}) => {
  const { storeId } = useCalendar();

  const categories = useMemo(() => {
    return (
      resourceRequirements?.find(
        (resourceRequirement) => resourceRequirement.property === "categoryId",
      )?.value || []
    );
  }, [resourceRequirements]);

  switch (resourceType) {
    case ResourceType.Practitioner:
      return (
        <Form.Item
          noStyle
          name={[allocationIndex, "resourceId"]}
          rules={[
            {
              required: true,
              validator: async (rule, v) => {
                if (!v) {
                  return Promise.reject();
                }
                try {
                  return await Promise.resolve();
                } catch (e: any) {
                  return Promise.reject(e.message);
                }
              },
            },
          ]}
        >
          <PractitionersWithAvailabilitiesSelect
            value={value}
            onChange={onChange}
            multiple={false}
            categories={categories}
          />
        </Form.Item>
      );
    case ResourceType.Room:
      return (
        <Form.Item
          noStyle
          name={[allocationIndex, "resourceId"]}
          rules={[
            {
              required: true,
              validator: async (rule, v) => {
                if (!v) {
                  return Promise.reject();
                }
                try {
                  return await Promise.resolve();
                } catch (e: any) {
                  return Promise.reject(e.message);
                }
              },
            },
          ]}
        >
          <GenericAllocationSelect<
            RoomFragment,
            GetRoomsQuery,
            GetRoomsQueryVariables
          >
            value={value}
            onChange={onChange}
            query={useGetRoomsLazyQuery}
            queryVariables={{
              options: {
                ...where<RoomFragment>({
                  "store.id": storeId,
                }),
                itemsPerPage: 10000,
              },
            }}
            dataKey="rooms"
            renderLabel={(item) => item.name}
          />
        </Form.Item>
      );
    case ResourceType.Equipment:
      return (
        <Form.Item
          noStyle
          name={[allocationIndex, "resourceId"]}
          rules={[
            {
              required: true,
              validator: async (rule, v) => {
                if (!v) {
                  return Promise.reject();
                }
                try {
                  return await Promise.resolve();
                } catch (e: any) {
                  return Promise.reject(e.message);
                }
              },
            },
          ]}
        >
          <GenericAllocationSelect<
            EquipmentFragment,
            GetEquipmentsQuery,
            GetEquipmentsQueryVariables
          >
            value={value}
            onChange={onChange}
            query={useGetEquipmentsLazyQuery}
            queryVariables={{
              options: {
                ...where<EquipmentFragment>({
                  "store.id": storeId,
                }),
                itemsPerPage: 10000,
              },
            }}
            dataKey="equipments"
            renderLabel={(item) => item.name}
          />
        </Form.Item>
      );
    default:
      return <Typography>Unknown Category</Typography>;
  }
};

const AllocationSelect = ({
  id,
  productIndex,
  allocationIndex,
}: {
  id: number;
  productIndex: number;
  allocationIndex: number;
}) => {
  const form = Form.useFormInstance();
  const { removeTypename } = useValidateVacancies({ form });

  const [validate] = useValidateVacanciesMutation();

  const handleValidateVacancies = useCallback(
    async (index: number) => {
      const product = form?.getFieldValue(["products", productIndex]);
      const values = form?.getFieldsValue();
      const day = values.dateFrom.tz().date();
      const month = values.dateFrom.tz().month();
      const year = values.dateFrom.tz().year();
      const date = product.requiredResources[allocationIndex]?.start
        ? product.requiredResources[allocationIndex]?.start
        : new Date(
            year,
            month,
            day,
            values.dateFrom.tz().hour(),
            values.dateFrom.tz().minute(),
          ).toString();

      try {
        const products = [
          {
            id: product.id,
            productId: product.productId,
            requiredResources: product.requiredResources.map(
              (r, index: number) => {
                const preferredResourceId = product.allocations.filter(
                  (a) => a.resourceAllocatorId === product.id,
                )[index]?.resourceId;

                if (preferredResourceId) {
                  r.resourceRequirements.push({
                    property: "id",
                    value: [preferredResourceId],
                  });
                }
                return {
                  resourceRequirements: r.resourceRequirements,
                  resourceType: r.resourceType,
                };
              },
            ),
            variationId: product.variationId,
            bookedPrice: String(product.bookedPrice) || "0",
          },
        ];
        await validate({
          variables: {
            appointmentId: id,
            date,
            storeId: values.storeId,
            products: removeTypename(products, "__typename"),
          },
        });
        message.success("Die angefragten Ressource steht zur Verfügung.");
      } catch (e: any) {
        message.error("Die geänderte Ressource steht nicht zur Verfügung.");
      }
    },
    [form, id, allocationIndex, validate],
  );

  const onChange = useCallback(
    async (val: number) => {
      form.setFieldValue(
        [
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "resourceId",
        ],
        val,
      );

      await handleValidateVacancies(allocationIndex);
    },
    [form, productIndex, allocationIndex],
  );
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const resourceRequirements = getFieldValue([
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "resourceRequirements",
        ]);

        const resourceType = getFieldValue([
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "resourceType",
        ]);

        const resourceId = getFieldValue([
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "resourceId",
        ]);

        const start = getFieldValue([
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "start",
        ]);

        const end = getFieldValue([
          "products",
          productIndex,
          "allocations",
          allocationIndex,
          "end",
        ]);

        const name = getFieldValue(["products", productIndex, "name"]);

        return (
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <AllocationLabel
              start={dayjs(start).tz() || dayjs().tz()}
              end={dayjs(end).tz() || dayjs().tz()}
              resourceRequirements={resourceRequirements}
              resourceId={resourceId}
              name={name}
              resourceType={resourceType}
            />
            <AllocationCategorySelect
              resourceType={resourceType}
              resourceRequirements={resourceRequirements}
              value={resourceId}
              onChange={onChange}
              allocationIndex={allocationIndex}
              productIndex={productIndex}
            />
          </div>
        );
      }}
    </Form.Item>
  );
};

export default AllocationSelect;
