import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Error404 from "./pages/Error404";
import Users from "./pages/Users";
import useAuth from "./auth/useAuth";
import Loading from "./components/base/Loading";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserSettings from "./pages/UserSettings";
import LoginLayout from "./components/login/LoginLayout";
import Practitioners from "./pages/Practitioners";
import Stores from "./pages/Stores";
import Equipment from "./pages/Equipment";
import Rooms from "./pages/Rooms";
import Materials from "./pages/Materials";
import MaterialCategories from "./pages/MaterialCategories";
import Coupons from "./pages/Coupons";
import Customers from "./pages/Customers";
import Appointments from "./pages/Appointments";
import CreateCustomer from "./pages/CreateCustomer";
import Customer from "./pages/Customer";
import CreateCoupon from "./pages/CreateCoupon";
import ProductCategories from "./pages/ProductCategories";
import Products from "./pages/Products";
import CreateProduct from "./pages/CreateProduct";
import Product from "./pages/Product";
import Coupon from "./pages/Coupon";
import AppointmentType from "./pages/AppointmentType";
import PractitionerCategories from "./pages/PractitionerCategories";
import EquipmentCategories from "./pages/EquipmentCategory";
import RoomCategories from "./pages/RoomCategories";
import User from "./pages/User";
import CreateUser from "./pages/CreateUser";
import Treatment from "./pages/Treatment";
import CreatePractitioner from "./pages/CreatePractitioner";
import Practitioner from "./pages/Practitioner";
import Dev from "./pages/Dev";
import CreateStore from "./pages/CreateStore";
import Store from "./pages/Store";

const App = () => {
  const { authIsLoading, isAuthenticated, roles } = useAuth();

  if (authIsLoading) return <Loading />;

  return isAuthenticated ? (
    <Routes>
      {(roles.includes("ADMIN") && (
        <Route element={<Layout />}>
          <Route element={<Dashboard />} path="/" />
          <Route element={<Users />} path="users" />
          <Route element={<Stores />} path="stores" />
          <Route element={<Practitioners />} path="practitioners" />
          <Route element={<CreatePractitioner />} path="createPractitioner" />
          <Route element={<Practitioner />} path="practitioner/:id" />
          <Route element={<Equipment />} path="equipment" />
          <Route element={<Rooms />} path="rooms" />
          <Route element={<Customers />} path="customers" />
          <Route element={<Appointments />} path="appointments" />
          <Route element={<Materials />} path="material" />
          <Route element={<MaterialCategories />} path="material-categories" />
          <Route element={<Coupons />} path="coupons" />
          <Route element={<Coupon />} path="coupon/:id" />
          <Route element={<ProductCategories />} path="product-categories" />
          <Route element={<Products />} path="products" />
          <Route element={<CreateProduct />} path="createProduct" />
          <Route element={<Product />} path="product/:id" />
          <Route element={<CreateCustomer />} path="createCustomer" />
          <Route element={<CreateStore />} path="createStore" />
          <Route element={<Store />} path="store/:id" />
          <Route element={<CreateCoupon />} path="createCoupon" />
          <Route element={<Treatment />} path="treatment/:id" />
          <Route element={<CreateUser />} path="createUser" />
          <Route element={<AppointmentType />} path="appointment-types" />
          <Route element={<Dev />} path="dev" />
          <Route
            element={<EquipmentCategories />}
            path="equipment-categories"
          />
          <Route
            element={<PractitionerCategories />}
            path="practitioner-categories"
          />
          <Route element={<RoomCategories />} path="room-categories" />
          <Route element={<Customer />} path="customer/:id" />
          <Route element={<UserSettings />} path="userSettings" />
          <Route element={<User />} path="user/:id" />
          <Route element={<Error404 />} path="*" />
        </Route>
      )) ||
        (roles.includes("USER") && (
          <Route element={<Layout />}>
            <Route element={<Dashboard />} path="" />
            <Route element={<Equipment />} path="equipment" />
            <Route element={<Customers />} path="customers" />
            <Route element={<Appointments />} path="appointments" />
            <Route element={<Treatment />} path="treatment/:id" />
            <Route element={<Materials />} path="material" />
            <Route element={<CreateCustomer />} path="createCustomer" />
            <Route element={<Customer />} path="customer/:id" />
            <Route element={<User />} path="user/:id" />
            <Route element={<UserSettings />} path="userSettings" />
            <Route element={<Error404 />} path="*" />
          </Route>
        ))}
    </Routes>
  ) : (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route element={<Login />} path="" />
        <Route element={<Login />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
