const getStoreColor = (id: number) => {
  if (id === 1) return "#1677ff";
  if (id === 2) return "#C0392B";
  if (id === 3) return "#9B59B6";
  if (id === 4) return "#2980B9";
  if (id === 5) return "#1ABC9C";
  if (id === 6) return "#27AE60";
  if (id === 7) return "#F1C40F";
  if (id === 8) return "#E67E22";
  if (id === 9) return "#ECF0F1";
  if (id === 10) return "#95A5A6";
  if (id === 11) return "#34495E";
  if (id === 12) return "#8000ff";
  if (id === 13) return "#E74C3C";
  if (id === 14) return "#8E44AD";
  if (id === 15) return "#3498DB";
  if (id === 16) return "#16A085";
  if (id === 17) return "#2ECC71";
  if (id === 18) return "#F39C12";
  if (id === 19) return "#D35400";
  if (id === 20) return "#BDC3C7";
  return "#7F8C8D";
};
export default getStoreColor;
