import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import CouponForm, {
  FormFields,
  parseFormFields,
} from "../components/coupons/CouponForm";
import Content from "../components/layout/Content";
import {
  CustomerType,
  useDeleteCouponMutation,
  useGetCouponQuery,
  useUpdateCouponMutation,
} from "../graphql/schema";

const Coupon = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetCouponQuery({
    variables: {
      id,
    },
  });

  const [update] = useUpdateCouponMutation();
  const [remove] = useDeleteCouponMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      await update({
        variables: {
          id,
          dto: parseFormFields(fields),
        },
      });
      refetch();

      message.success("Die Änderungen wurden gespeichert.");

      navigate("/coupons");
    },
    [id],
  );

  const handleDelete = useCallback(async () => {
    try {
      await remove({
        variables: {
          id,
        },
      });
      message.success("Der Gutschein wurde gelöscht.");
      navigate("/coupons");
    } catch (error) {
      message.error("Der Gutschein konnte nicht gelöscht werden.");
    }
  }, [id]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Gutschein bearbeiten"
        singleItemTitle="Gutschein"
        formRenderer={(formProps) => <CouponForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/coupons");
        }}
        onDelete={handleDelete}
        initialValues={{
          name: data?.coupon.name || "",
          code: data?.coupon.code || "",
          startDate: data?.coupon.startDate
            ? dayjs(data.coupon.startDate)
            : undefined,
          expirationDate: data?.coupon.expirationDate
            ? dayjs(data.coupon.expirationDate)
            : undefined,
          description: data?.coupon.description || "",
          customerType: data?.coupon.customerType || CustomerType.Standard,
          usageType: data?.coupon.usageType,
          realCoupon: data?.coupon.realCoupon || false,
          combinable: data?.coupon.combinable || false,
          storeIds: data?.coupon.stores.map((store) => store.id) || [],
          couponType: data?.coupon.couponType,
          value: data?.coupon.value || "",
          variationIds:
            data?.coupon.variations.map((variation) => variation.id) || [],
          productIds: data?.coupon.products.map((product) => product.id) || [],
          productCategoryIds: data?.coupon.productCategories.map(
            (productCategory) => productCategory.id,
          ),
        }}
      />
    </Content>
  );
};

export default Coupon;
