import { useNavigate } from "react-router-dom";
import { Button, Col, List, Modal, Row, Skeleton, Typography } from "antd";
import { format } from "date-fns";
import { useMemo } from "react";
import { useGetAppointmentsBetweenDatesByPractitionerQuery } from "../../graphql/schema";

interface Props {
  title?: string;
  id?: number;
  deleteModalVisible: boolean;
  setDeleteModalVisible: (visible: boolean) => void;
  startDate: string;
  endDate: string;
  practitionerId: number;
  storeId: number;
  handleRemove: () => void;
  handleCancel?: () => void;
}

const AssociatedAppointmentsModal = ({
  id,
  title = "Verfügbarkeit löschen",
  deleteModalVisible,
  setDeleteModalVisible,
  startDate,
  endDate,
  practitionerId,
  handleRemove,
  storeId,
  handleCancel,
}: Props) => {
  const navigate = useNavigate();
  const { data, loading, refetch } =
    useGetAppointmentsBetweenDatesByPractitionerQuery({
      variables: {
        practitionerId,
        startDate,
        endDate,
      },
      skip: !deleteModalVisible,
    });

  const onNavigate = (item: any) => {
    const queryParams = new URLSearchParams({
      id: item.id,
      storeId: item.store?.id,
      date: item.dateFrom,
      status: item.appointmentStatus,
    }).toString();

    const url = `/appointments?${queryParams}`;
    window.open(url, "_blank");
  };

  const onCancel = () => {
    setDeleteModalVisible(false);
    if (handleCancel) {
      handleCancel();
    }
  };

  const appointments = useMemo(() => {
    return (
      data?.appointmentsBetweenDatesByPractitioner.filter((item) =>
        storeId ? item.store?.id === storeId : true,
      ) || []
    );
  }, [data, storeId]);

  const disabled = useMemo(() => {
    if (
      storeId &&
      !data?.appointmentsBetweenDatesByPractitioner.some(
        (item) => item.store?.id === storeId,
      )
    )
      return false;
    return (
      loading ||
      !!appointments.length ||
      !!data?.appointmentsBetweenDatesByPractitioner?.length
    );
  }, [loading, appointments, data]);

  const onOk = () => {
    setDeleteModalVisible(false);
    handleRemove();
  };

  return (
    <Modal
      open={deleteModalVisible}
      onCancel={onCancel}
      title={title}
      okButtonProps={{
        danger: !storeId,
        disabled,
      }}
      cancelText="Aktualisieren"
      onOk={onOk}
      footer={[
        <Button key="close" onClick={onCancel}>
          Abbrechen
        </Button>,
        <Button
          key="refetch"
          type="primary"
          loading={loading}
          onClick={() => refetch()}
        >
          Aktualisieren
        </Button>,
        <Button key="delete" danger onClick={onOk} disabled={disabled}>
          Löschen
        </Button>,
      ]}
    >
      {loading ? <Skeleton active /> : null}{" "}
      {!loading && appointments.length ? (
        <Row>
          <Col span={24}>
            <p>
              Lösen Sie bitte zuerst alle Verknüpfungen zu existierenden
              Terminen auf:
            </p>
          </Col>
          <Col span={24}>
            <List
              dataSource={appointments}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      key={`delete_${item.id}`}
                      type="link"
                      onClick={() => onNavigate(item)}
                    >
                      Anpassen
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Typography.Text>{`Datum: ${format(
                        item.dateFrom,
                        "dd.MM.yyyy",
                      )}`}</Typography.Text>
                    }
                    description={`Uhrzeit: ${format(
                      item.dateFrom,
                      "HH:mm",
                    )} - ${format(item.dateUntil, "HH:mm")} Uhr`}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <p>
              Diese Verfügbarkeit hat keine Verknüpfungen zu existierenden
              Terminen. Sie können diese Verfügbarkeit löschen.
            </p>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default AssociatedAppointmentsModal;
