import { Flex, Select, Tooltip } from "antd";
import { where } from "@3ts/react-ant-crud";
import { WarningOutlined } from "@ant-design/icons";
import {
  AppointmentProductInput,
  ProductFragment,
  useGetProductQuery,
  useGetProductsSelectQuery,
} from "../../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  filter?: any;
  products?: AppointmentProductInput[];
}

const SelectCompatibleProduct = ({
  value,
  onChange,
  filter = {},
  products = [],
}: Props) => {
  const { data: labelData } = useGetProductQuery({
    variables: {
      id: value ?? Number.MIN_SAFE_INTEGER,
    },
    skip: !value,
  });
  const { data } = useGetProductsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductFragment>(filter),
        itemsPerPage: 1000,
      },
    },
  });

  const validateIsIncompatible = (productId: number) => {
    if (productId) {
      if (
        products.some((p: AppointmentProductInput) => {
          const product = data?.products.items.find(
            (item) => item.id === p.productId,
          );
          return product?.incompatibleProducts?.some(
            (incompatibleProduct) => incompatibleProduct.id === productId,
          );
        })
      ) {
        return true;
      }
      const product = data?.products.items.find(
        (item) => item.id === productId,
      );
      if (
        product?.incompatibleProducts?.some((incompatibleProduct) => {
          return products.some((p) => p.productId === incompatibleProduct.id);
        })
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      showSearch
      filterOption={(input: string, option: any) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      labelRender={(value) => (
        <Flex justify="space-between">
          {labelData?.product.name}
          {labelData?.product.nextVersionId && (
            <Tooltip title="Veraltete Version">
              <WarningOutlined
                color="yellow"
                style={{
                  fontSize: 16,
                  color: "red",
                }}
              />
            </Tooltip>
          )}
        </Flex>
      )}
      options={
        data?.products.items
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((product) => {
            return {
              value: product.id,
              label: product.name,
              disabled: validateIsIncompatible(product.id),
            };
          }) || []
      }
      allowClear
    />
  );
};

export default SelectCompatibleProduct;
