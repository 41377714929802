import { Button, Card, Form, Input, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/pro-light-svg-icons";
import Logo from "../layout/Logo";
import styles from "./LoginForm.module.scss";
import useAuth from "../../auth/useAuth";
import { LoginResult } from "../../auth/authContext";

interface FormFields {
  username: string;
  password: string;
}

const LoginForm = () => {
  const [form] = Form.useForm<FormFields>();
  const auth = useAuth();

  const handleLogin = async () => {
    const { username, password } = await form.validateFields();

    const loginResult = await auth.signIn(username, password);
    if (loginResult !== LoginResult.successful) {
      message.error("Invalid credentials");
    }
  };

  return (
    <Card className={styles.container}>
      <Logo type="dark" />

      <br />

      <Form size="large" form={form} onSubmitCapture={handleLogin}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihre E-Mail Adresse ein!",
            },
          ]}
        >
          <Input
            prefix={<FontAwesomeIcon icon={faEnvelope} />}
            placeholder="E-Mail Adresse"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Bitte geben Sie Ihr Passwort ein!" },
          ]}
        >
          <Input.Password
            prefix={<FontAwesomeIcon icon={faLock} />}
            type="password"
            placeholder="Passwort"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Anmelden
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginForm;
