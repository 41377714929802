import {
  AppointmentProductInput,
  Product,
  Variation,
} from "../../graphql/schema";

const getBaseProduct = (
  product: AppointmentProductInput,
  products: Product[],
): Product | Variation | undefined => {
  const baseProduct = products?.find((item) => item.id === product.productId);
  const variation = baseProduct?.variations?.find(
    (item) => item.id === product?.variationId,
  );
  return variation || baseProduct;
};

export default getBaseProduct;
