import { Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useEffect } from "react";
import { useGetPractitionersLazyQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
  storeId?: number;
}

const PractitionersSelect = ({ value, onChange, storeId }: Props) => {
  const [fetchData, { data, loading }] = useGetPractitionersLazyQuery();
  useEffect(() => {
    fetchData(
      storeId
        ? {
            fetchPolicy: "no-cache",
            variables: {
              options: {
                ...where({
                  "stores.id": storeId,
                }),
                itemsPerPage: 10000,
              },
            },
          }
        : {
            fetchPolicy: "no-cache",
            variables: {
              options: {
                itemsPerPage: 10000,
              },
            },
          },
    );
  }, []);

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      showSearch
      filterOption={(input: string, option: any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={
        data?.practitioners.items.map((user) => {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default PractitionersSelect;
