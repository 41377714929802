import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { ResourceType } from "../../graphql/schema";

const ResourceTypeSelect = (props: GenericEnumSelectProps<ResourceType>) => {
  return (
    <GenericEnumSelect<ResourceType>
      {...props}
      options={{
        [ResourceType.Room]: "Raum",
        [ResourceType.Equipment]: "Gerät",
        [ResourceType.Practitioner]: "Behandler",
      }}
    />
  );
};

export default ResourceTypeSelect;
