import { Select } from "antd";
import { useGetCouponsSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
}

const CouponsSelect = ({ value, onChange }: Props) => {
  const { data } = useGetCouponsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 1000,
      },
    },
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      showSearch
      filterOption={(input: string, option: any) =>
        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={
        data?.coupons.items.map((coupon) => {
          return {
            value: coupon.id,
            key: coupon.name,
            label: coupon.code,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default CouponsSelect;
