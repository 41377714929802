import React from "react";
import Content from "../components/layout/Content";
import CalendarForm from "../components/treatment/appointment/calendar/CalendarForm";
import { CalendarProvider } from "../components/treatment/appointment/calendar/CalendarContext";
import ErrorBoundary from "../components/treatment/ErrorBoundary";

const Appointments = () => {
  return (
    <Content>
      <ErrorBoundary>
        <CalendarProvider>
          <CalendarForm />
        </CalendarProvider>
      </ErrorBoundary>
    </Content>
  );
};

export default Appointments;
