import { Button } from "antd";
import { useCallback, useMemo } from "react";
import { format } from "date-fns";
import dayjs from "dayjs";
import { AppointmentStatus } from "../../../../graphql/schema";
import validateSameValues from "../../../../helper/common/validateSameValues";
import { useAppointment } from "../context/AppointmentContext";
import prepareFormValues from "../../../../helper/form/prepareFormValues";

interface Props {
  onClick: (input: any) => Promise<void>;
}

const SaveButton = ({ onClick }: Props) => {
  const { appointment, formValues, tab } = useAppointment();
  const {
    dateFrom,
    dateUntil,
    practitionerIds,
    customerIds,
    storeId,
    message,
    appointmentProducts,
    internalNote,
    bookedPrice,
    notifyForEarlierAppointment,
    appointmentStatus,
    rooms,
    equipment,
  } = formValues;

  const startChanged = useMemo(() => {
    if (!appointment?.dateFrom) return false;
    const start = format(new Date(appointment?.dateFrom), "dd.MM.yyyy HH:mm");
    const currentStart = format(
      dayjs(formValues.dateFrom).toDate(),
      "dd.MM.yyyy HH:mm",
    );
    if (!start) return false;
    if (start !== currentStart) return true;
    return false;
  }, [formValues, appointment]);

  const endChanged = useMemo(() => {
    if (!appointment?.dateUntil) return false;
    const end = format(new Date(appointment?.dateUntil), "dd.MM.yyyy HH:mm");
    const currentEnd = format(
      dayjs(formValues.dateUntil).toDate(),
      "dd.MM.yyyy HH:mm",
    );
    if (end !== currentEnd) return true;
    return false;
  }, [formValues, appointment]);

  const getStoreChanged = useCallback(() => {
    if (!storeId) return false;
    if (appointment?.store?.id !== storeId) return true;
    return false;
  }, [storeId, appointment]);

  const getPractitionerChanged = useCallback(() => {
    if (!practitionerIds?.length) return false;
    const appointmentPractitionerIds =
      appointment?.practitioners?.map((practitioner: any) => practitioner.id) ||
      [];
    return !validateSameValues(practitionerIds, appointmentPractitionerIds);
  }, [practitionerIds, appointment]);

  const getCustomerChanged = useCallback(() => {
    if (!customerIds?.length) return false;
    const appointmentCustomerIds =
      appointment?.customers?.map((customer: any) => customer.id) || [];
    return !validateSameValues(customerIds, appointmentCustomerIds);
  }, [customerIds, appointment]);

  const getMessageChanged = useCallback(() => {
    const prevMessage = appointment?.message;
    if (message === undefined) return false;
    const currentMessage = message;
    if (prevMessage !== currentMessage) return true;
    return false;
  }, [message, appointment]);

  const getInternalNoticeChanged = useCallback(() => {
    const prevInternalNote = appointment?.internalNote;
    if (internalNote === undefined) return false;
    const currentInternalNote = internalNote;
    if (prevInternalNote !== currentInternalNote) return true;
    return false;
  }, [internalNote, appointment]);

  const getBookedPrice = useCallback(() => {
    const prevPrice = appointment?.bookedPrice;
    if (bookedPrice === undefined) return false;
    const currentPrice = bookedPrice;
    if (Number(prevPrice) !== Number(currentPrice)) return true;
    return false;
  }, [bookedPrice, appointment]);

  const getNotifyForEarlierAppointment = useCallback(() => {
    const prevNotify = appointment?.notifyForEarlierAppointment;
    if (prevNotify !== notifyForEarlierAppointment) return true;
    return false;
  }, [notifyForEarlierAppointment, appointment]);

  const showButton = useCallback(() => {
    if (
      appointment?.id &&
      appointment?.appointmentStatus !== AppointmentStatus.NoShow &&
      appointment?.appointmentStatus !==
        AppointmentStatus.CancelledByCustomer &&
      appointment?.appointmentStatus !== AppointmentStatus.Cancelled &&
      appointment?.appointmentStatus !==
        AppointmentStatus.RescheduledByKalialab &&
      appointment?.appointmentStatus !== AppointmentStatus.RescheduledByCustomer
    ) {
      return true;
    }
    return false;
  }, [appointment, appointmentStatus]);

  const isDisabled = useCallback(() => {
    if (
      appointment?.appointmentStatus === AppointmentStatus.New &&
      getPractitionerChanged()
    )
      return true;

    if (
      !getStoreChanged() &&
      !getCustomerChanged() &&
      !getMessageChanged() &&
      !getInternalNoticeChanged() &&
      !getBookedPrice() &&
      !(
        JSON.stringify(appointment?.products) !==
        JSON.stringify(appointmentProducts)
      ) &&
      !startChanged &&
      !endChanged &&
      !getNotifyForEarlierAppointment()
    )
      return true;
    return false;
  }, [
    appointment,
    practitionerIds,
    customerIds,
    dateFrom,
    dateUntil,
    rooms,
    storeId,
    notifyForEarlierAppointment,
    message,
    appointmentProducts,
    internalNote,
    equipment,
    internalNote,
  ]);

  const handleClick = useCallback(async () => {
    await onClick({
      input: prepareFormValues(formValues),
      extra: {
        id: appointment?.id,
        appointmentStatus:
          startChanged || endChanged
            ? AppointmentStatus.RescheduledByKalialab
            : appointment?.appointmentStatus,
      },
      successMessage: "Änderungen wurden gespeichert",
    });
  }, [appointment, formValues, storeId]);

  if (tab !== "new") return null;
  if (!appointment) return null;
  if (!showButton()) return null;
  return (
    <Button
      style={{
        margin: 10,
        marginRight: 5,
        marginLeft: 5,
      }}
      disabled={isDisabled()}
      onClick={handleClick}
    >
      Speichern
    </Button>
  );
};

export default SaveButton;
