import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import TreatmentForm, {
  FormFields,
  parseFormFields,
} from "../components/treatment/TreatmentForm";
import Content from "../components/layout/Content";
import {
  useDeleteTreatmentMutation,
  useGetTreatmentQuery,
  useUpdateTreatmentMutation,
} from "../graphql/schema";

const Treatment = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetTreatmentQuery({
    variables: {
      id,
    },
  });

  const [update] = useUpdateTreatmentMutation();
  const [remove] = useDeleteTreatmentMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      await update({
        variables: {
          id,
          dto: parseFormFields(fields),
        },
      });
      refetch();

      message.success("Die Änderungen wurden gespeichert.");

      navigate("/customers");
    },
    [id],
  );

  const handleDelete = useCallback(async () => {
    await remove({
      variables: {
        id,
      },
    });
    navigate("/customers");
  }, [id]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Behandlung bearbeiten"
        singleItemTitle="Behandlung"
        formRenderer={(formProps) => <TreatmentForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/customers");
        }}
        onDelete={handleDelete}
        initialValues={{
          storeId: data.treatment.store?.id,
          customerIds: data.treatment.customers.map((c) => c.id),
          date: data.treatment.date ? dayjs(data.treatment.date) : undefined,
          dateFrom: data.treatment.dateFrom
            ? dayjs(data.treatment.dateFrom)
            : undefined,
          dateUntil: data.treatment.dateUntil
            ? dayjs(data.treatment.dateUntil)
            : undefined,
          materialIds: data.treatment.material.map((m) => m.id),
          roomIds: data.treatment.rooms.map((r) => r.id),
          practitionerIds: data.treatment.practitioners.map((p) => p.id),
          price: data.treatment.price,
          paymentDate: data.treatment.paymentDate,
          invoiceNumber: data.treatment.invoiceNumber,
          equipmentIds: data.treatment.equipment.map((e) => e.id),
          bookedPrice: data.treatment.price,
          discount: data.treatment.discount,
          paymentStatus: data.treatment.paymentStatus,
          treatmentProducts: data.treatment.products.map((tp) => ({
            id: tp.id,
            product: tp.product?.id,
            variation: tp.variation?.id,
            amount: tp.amount,
          })),
        }}
      />
    </Content>
  );
};

export default Treatment;
