import { Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useGetVariationsSelectQuery } from "../../graphql/schema";

interface Props {
  filter?: any;
  value?: number | null;
  disabled?: boolean;
  onChange?: (value: number, totalDuration: number | undefined) => void;
}

const VariationSelect = ({
  value,
  onChange,
  filter,
  disabled = false,
}: Props) => {
  const queryFilter = filter
    ? {
        variables: {
          options: {
            ...where(filter),
            itemsPerPage: 1000,
          },
        },
      }
    : {
        itemsPerPage: 1000,
      };
  const { data } = useGetVariationsSelectQuery(queryFilter);
  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(val) => {
        const variation = data?.variations.items.find((v) => v.id === val);
        if (onChange) onChange(val, variation?.totalDuration || 0);
      }}
      options={
        data?.variations.items.map((variation) => {
          return {
            value: variation.id,
            label: variation.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default VariationSelect;
