import { AppointmentProduct } from "../../graphql/schema";

const getProductsTotalDuration = (products: AppointmentProduct[]) => {
  return (
    products?.reduce(
      (total: number, item: any) => total + (item?.totalDuration || 0),
      0,
    ) || 0
  );
};

export default getProductsTotalDuration;
