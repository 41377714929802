import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Logo.module.scss";
import KaliaLogo from "../../assets/kalia_logo.png";

interface Props {
  collapsed?: boolean;
  type?: "light" | "dark";
}

const Logo = ({ collapsed, type }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={[
        collapsed ? styles.collapsedLogo : styles.logo,
        type === "light" ? styles.light : styles.dark,
      ].join(" ")}
    >
      {!collapsed && (
        <img
          src={KaliaLogo}
          alt="Kalia Lab"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      )}
      {collapsed && (
        <img
          src={KaliaLogo}
          alt="Kalia Lab"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
};

export default Logo;
