import { Form } from "antd";
import React from "react";
import AllocationSelect from "./AllocationSelect";

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};

interface Props {
  id: number;
  name: number;
  products: any;
}

const AllocationsConfigList = ({ name, id }: Props) => {
  return (
    <Form.List name={[name, "allocations"]}>
      {(fields, _, { errors }) => {
        return (
          <>
            {fields.map(({ key, name: configName, ...restField }) => {
              return (
                <AllocationSelect
                  id={id}
                  key={key}
                  productIndex={name}
                  allocationIndex={configName}
                />
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};

export default AllocationsConfigList;
