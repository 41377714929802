import dayjs, { Dayjs } from "dayjs";
import { noteOnce } from "rc-util/lib/warning";

dayjs.extend((o, c) => {
  // todo support Wo (ISO week)
  const proto = c.prototype;
  const oldFormat = proto.format;
  proto.format = function f(formatStr: string) {
    const str = (formatStr || "").replace("Wo", "wo");
    return oldFormat.bind(this)(str);
  };
});

const parseLocale = (locale: string) => {
  return "de_DE";
};

const parseNoMatchNotice = () => {
  /* istanbul ignore next */
  noteOnce(
    false,
    "Not match any format. Please help to fire a issue about this.",
  );
};

const generateConfig = {
  // get
  getNow: () => dayjs().tz(),
  getFixedDate: (string: string) =>
    dayjs(string, ["YYYY-M-DD", "YYYY-MM-DD"]).tz(),
  getEndDate: (date: Dayjs) => date.tz().endOf("month"),
  getWeekDay: (date: Dayjs) => {
    const clone = date.tz().locale("de");
    return clone.weekday() + clone.localeData().firstDayOfWeek();
  },
  getYear: (date: Dayjs) => dayjs(date).tz().year(),
  getMonth: (date: Dayjs) => dayjs(date).tz().month(),
  getDate: (date: Dayjs) => dayjs(date).tz().date(),
  getHour: (date: Dayjs) => {
    return dayjs(date).tz().hour();
  },
  getMinute: (date: Dayjs) => dayjs(date).tz().minute(),
  getSecond: (date: Dayjs) => dayjs(date).tz().second(),
  getMillisecond: (date: Dayjs) => dayjs(date).tz().millisecond(),

  addYear: (date: Dayjs, diff: number) => dayjs(date).tz().add(diff, "year"),
  addMonth: (date: Dayjs, diff: number) => dayjs(date).tz().add(diff, "month"),
  addDate: (date: Dayjs, diff: number) => dayjs(date).tz().add(diff, "day"),
  setYear: (date: Dayjs, year: number) => dayjs(date).tz().year(year),
  setMonth: (date: Dayjs, month: number) => dayjs(date).tz().month(month),
  setDate: (date: Dayjs, num: number) => dayjs(date).tz().date(num),
  setHour: (date: Dayjs, hour: number) => {
    return dayjs(date).tz().hour(hour);
  },
  setMinute: (date: Dayjs, minute: number) => date.tz().minute(minute),
  setSecond: (date: Dayjs, second: number) => date.tz().second(second),
  setMillisecond: (date: Dayjs, milliseconds: number) =>
    date.tz().millisecond(milliseconds),

  isAfter: (date1: Dayjs, date2: Dayjs) =>
    dayjs(date1).tz().isAfter(dayjs(date2).tz()),
  isValidate: (date: Dayjs) => dayjs(date).isValid(),

  locale: {
    getWeekFirstDay: (locale: string) =>
      dayjs().tz().locale(parseLocale(locale)).localeData().firstDayOfWeek(),
    getWeekFirstDate: (locale: string, date: Dayjs) =>
      date.tz().locale(parseLocale(locale)).weekday(0),
    getWeek: (locale: string, date: Dayjs) =>
      date.tz().locale(parseLocale(locale)).week(),
    getShortWeekDays: (locale: string) =>
      dayjs().tz().locale(parseLocale(locale)).localeData().weekdaysMin(),
    getShortMonths: (locale: string) =>
      dayjs().tz().locale(parseLocale(locale)).localeData().monthsShort(),
    format: (locale: string, date: Dayjs, format: string) => {
      return dayjs(date).tz().locale(parseLocale(locale)).format(format);
    },
    // gets executed when typing in input field
    parse: (locale: string, text: string, formats: string[]) => {
      for (let i = 0; i < formats.length; i += 1) {
        const format = formats[i];
        const date = dayjs(text, format, true).tz(undefined, true);
        if (date.isValid()) {
          return date;
        }
      }

      if (text) {
        parseNoMatchNotice();
      }
      return null;
    },
  },
};

export default generateConfig;
