import GenericEnumSelect, {
  GenericEnumSelectProps,
} from "../form/GenericEnumSelect";
import { EquipmentStatus } from "../../graphql/schema";

const EquipmentStatusTypeSelect = (
  props: GenericEnumSelectProps<EquipmentStatus>,
) => {
  return (
    <GenericEnumSelect<EquipmentStatus>
      {...props}
      options={{
        [EquipmentStatus.Available]: "Verfügbar",
        [EquipmentStatus.Defect]: "Defekt",
      }}
    />
  );
};

export default EquipmentStatusTypeSelect;
