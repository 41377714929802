import React from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
} from "antd";
import { DataTableWithDrawer } from "@3ts/react-ant-crud";
import dayjs, { Dayjs } from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";
import {
  EquipmentFragment,
  EquipmentStatus,
  GetEquipmentDocument,
  GetEquipmentsDocument,
  GetEquipmentsQuery,
  useCountEquipmentAllocationsLazyQuery,
  useCreateEquipmentMutation,
  useDeleteEquipmentMutation,
  useGetEquipmentsLazyQuery,
  useUpdateEquipmentMutation,
} from "../graphql/schema";
import formItemProps from "../helper/form/formItemProps";
import Content from "../components/layout/Content";
import EquipmentStatusTypeSelect from "../components/store/EquipmentStatusTypeSelect";
import StoreSelect from "../components/store/StoreSelect";
import EquipmentCategorySelect from "../components/store/EquipmentCategorySelect";
import StaticTimezoneDatePicker from "../components/staticTimezoneDatePicker/StaticTimezoneDatePicker";

const { confirm } = Modal;

interface FormFields {
  name: string;
  dateOfPurchase: Dayjs | undefined;
  serialNumber: string;
  equipmentCategory: number;
  status: EquipmentStatus;
  storeId: number | undefined;
  roomId: number | undefined;
}

const EquipmentDrawer = ({
  id,
  drawerVisible,
  setDrawerVisible,
  form,
  onCreate,
  onUpdate,
  onDelete,
}: {
  id: number | null;
  drawerVisible: boolean;
  setDrawerVisible: (visible: boolean) => void;
  form: FormInstance;
  onCreate: (values: Partial<FormFields>) => void;
  onUpdate: (id: number, values: Partial<FormFields>) => void;
  onDelete: (id: number) => void;
}) => {
  return (
    <Drawer
      open={drawerVisible}
      title={id ? "Gerät bearbeiten" : "Neues Gerät"}
      onClose={() => {
        setDrawerVisible(false);
      }}
      extra={
        id && (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              onDelete(id);
            }}
          />
        )
      }
    />
  );
};

const Equipments = () => {
  const [create] = useCreateEquipmentMutation();
  const [update] = useUpdateEquipmentMutation();
  const [remove] = useDeleteEquipmentMutation();
  const [countAllocations] = useCountEquipmentAllocationsLazyQuery();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || "",
          dateOfPurchase: values.dateOfPurchase || new Date(),
          serialNumber: values.serialNumber || "",
          equipmentCategoryId: values.equipmentCategory || 0,
          status: values.status || EquipmentStatus.Available,
          storeId: values.storeId || 1,
        },
      },
      refetchQueries: [GetEquipmentsDocument, GetEquipmentDocument],
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || "",
          dateOfPurchase: values.dateOfPurchase || new Date(),
          serialNumber: values.serialNumber || "",
          equipmentCategoryId: values.equipmentCategory,
          status: values.status,
          storeId: values.storeId,
        },
      },
      refetchQueries: [GetEquipmentsDocument, GetEquipmentDocument],
    });
  };

  const handleDelete = async (id: number) => {
    const pastAllocations = await countAllocations({
      variables: {
        id,
        inFuture: false,
      },
    });

    const futureAllocations = await countAllocations({
      variables: {
        id,
        inFuture: true,
      },
    });
    confirm({
      type: "warn",
      title: "Gerät löschen",
      content: (
        <>
          <p>
            Das Gerät hat{" "}
            <strong>
              {pastAllocations?.data?.countEquipmentAllocations || 0}
            </strong>{" "}
            vergangene Termine und{" "}
            <strong>
              {futureAllocations.data?.countEquipmentAllocations || 0}
            </strong>{" "}
            zukünftige Termine.
          </p>
          <p>
            Möchten Sie das Gerät wirklich löschen? Diese Aktion kann nicht
            rückgängig gemacht werden.
          </p>
        </>
      ),
      onOk() {
        remove({
          variables: {
            id,
          },
          refetchQueries: [GetEquipmentsDocument, GetEquipmentDocument],
        });
      },
    });
  };

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formItemProps(
                "dateOfPurchase",
                "Datum der Anschaffung",
                true,
              )}
            >
              <StaticTimezoneDatePicker
                style={{ width: "100%" }}
                format="DD.MM.YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formItemProps("serialNumber", "Seriennummer", false)}
            >
              <Input placeholder="Seriennummer" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps("status", "Status", true)}>
              <EquipmentStatusTypeSelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Gerätekategorie wird verwendet, um die Art des Geräts zu definieren."
              {...formItemProps("equipmentCategory", "Gerätekategorie", true)}
            >
              <EquipmentCategorySelect />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Die Filiale in der das Gerät sich befindet."
              {...formItemProps("storeId", "Filiale", true)}
            >
              <StoreSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Content>
      <DataTableWithDrawer<FormFields, EquipmentFragment, GetEquipmentsQuery>
        singleItemTitle="Gerät"
        query={useGetEquipmentsLazyQuery}
        id="equipment"
        title="Geräte"
        editFormRenderer={formRenderer}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(values) => {
          return {
            name: values.name,
            dateOfPurchase: dayjs(values.dateOfPurchase),
            serialNumber: values.serialNumber,
            equipmentCategory: values.category?.id,
            status: values.status,
            storeId: values.store?.id,
            roomId: values.room?.id,
          };
        }}
        columns={{
          name: "Name",
          dateOfPurchase: {
            label: "Datum der Anschaffung",
            render: (date) =>
              dayjs(date) ? dayjs(date).format("DD.MM.YYYY") : undefined,
          },
          serialNumber: "Seriennummer",
          category: {
            label: "Kategorie",
            render: (category) => {
              return category.name;
            },
          },
          status: {
            label: "Kategorie",
            render: (status) => {
              if (status === EquipmentStatus.Available) return "Verfügbar";
              if (status === EquipmentStatus.Defect) return "Defekt";
              return "Unbekannt";
            },
          },
          store: {
            label: "Filiale",
            render: (store) => store?.name || "-",
          },
        }}
      />
    </Content>
  );
};

export default Equipments;
