import React, { useEffect } from "react";
import { Flex, Modal, Space, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  ProductFragment,
  useGetAppointmentsByProductIdQuery,
} from "../../graphql/schema";
import Loading from "../base/Loading";

interface Props {
  product: Partial<ProductFragment>;
}

const UsedProductModal = ({ product }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const { data, loading } = useGetAppointmentsByProductIdQuery({
    variables: {
      productId: product.id ?? Number.MIN_SAFE_INTEGER,
    },
    skip: !product.nextVersionId,
  });

  useEffect(() => {
    if (data?.appointmentsByProductId?.length) {
      setOpen(true);
    }
  }, [data]);

  const handleSelect = (id: number) => {
    const appointment = data?.appointmentsByProductId?.find(
      (app) => app.id === id,
    );
    if (appointment) {
      const queryParams = new URLSearchParams({
        id: appointment.id.toString(),
        storeId: appointment.store.id.toString(),
        date: appointment.date,
        status: appointment.appointmentStatus,
      }).toString();

      const url = `/appointments?${queryParams}`;
      window.open(url, "_blank");
    }
  };

  if (loading) return <Loading />;

  return (
    <Modal
      title="Dieses Produkt wird in noch stattfindenden Terminen verwendet"
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
    >
      <Typography.Text>
        Unten sehen Sie die zukünftigen Termine, in denen dieses Produkt
        verwendet wird. Klicken Sie auf einen Termin, um zum jeweiligen Termin
        zu springen.
      </Typography.Text>
      <Typography.Text>
        Hinweis: Die neue Version dieses Produkts wird in den zukünftigen
        Terminen verwendet. Es wird empfohlen, die neue Version zu auch bei
        bestehenden Terminen zu verwenden da sich die Anforderungen der Leistung
        möglicherweise geändert haben
      </Typography.Text>
      <Typography.Text />
      <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
        {data?.appointmentsByProductId?.map((appointment) => (
          <Flex
            style={{
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            key={appointment.id}
            onClick={() => handleSelect(appointment.id)}
            justify="space-between"
          >
            <Typography.Text>
              {format(appointment?.date, "dd.MM.yyyy")}
            </Typography.Text>
            <ArrowRightOutlined />
          </Flex>
        ))}
      </Space>
    </Modal>
  );
};

export default UsedProductModal;
