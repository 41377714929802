import { FormInstance, Modal } from "antd";
import dayjs from "dayjs";
import { addMinutes } from "date-fns";
import {
  AppointmentProductInput,
  useValidateVacanciesMutation,
} from "../../graphql/schema";
import { omitDeepArrayWalk } from "../form/removeTypename";
import getProductsTotalDuration from "../common/getProductsTotalDuration";

const { confirm } = Modal;

interface Props {
  form: FormInstance;
}

export interface ValidateVacanciesProps {
  values: any;
  appointmentId?: number;
  index?: number;
  force?: boolean;
  restore?: boolean;
}

const useValidateVacancies = ({ form }: Props) => {
  const [validate] = useValidateVacanciesMutation();

  const handleAppointmentDuration = () => {
    const dateFrom = dayjs(form?.getFieldValue("dateFrom"));
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalDuration = getProductsTotalDuration(appointmentProducts);
    if (dateFrom) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        dateUntil: dayjs(addMinutes(dateFrom.toDate(), totalDuration)),
      });
    }
  };

  const validateVacancies = async ({
    values,
    appointmentId,
    index,
    force,
    restore,
  }: ValidateVacanciesProps) => {
    const date = dayjs(form?.getFieldValue("dateFrom"));
    try {
      if (!date.isValid()) throw new Error("Date is not valid");
      const result = await validate({
        variables: {
          appointmentId,
          date: date.toString(),
          storeId: values.storeId,
          products: omitDeepArrayWalk(
            values.products.map((product: AppointmentProductInput) => ({
              id: product.id,
              productId: product.productId,
              variationId: product?.variationId,
              packageId: product?.packageId,
              requiredResources:
                product?.requiredResources?.map(
                  (resourceConfiguration, i: number) => {
                    const preferredResourceId = product?.allocations?.filter(
                      (a: any) => a.resourceAllocatorId === product.id,
                    )[i]?.resourceId;

                    if (preferredResourceId) {
                      resourceConfiguration.resourceRequirements.push({
                        property: "id",
                        value: [preferredResourceId],
                      });
                    }
                    return {
                      resourceRequirements:
                        resourceConfiguration.resourceRequirements,
                      resourceType: resourceConfiguration.resourceType,
                    };
                  },
                ) || [],
              bookedPrice: String(product.bookedPrice) || "0.00",
            })),
            "__typename",
          ),
          force,
        },
      });
      if (
        result?.data?.validateVacancies &&
        result?.data?.validateVacancies?.length > 0
      ) {
        form?.setFieldsValue({
          ...values,
          products: result?.data?.validateVacancies.map((product: any) => {
            const foundProduct = values.products.find(
              (p: AppointmentProductInput) => p.id === product.id,
            );
            if (!foundProduct) return product;
            if (restore && foundProduct) return foundProduct;
            return product;
          }),
        });
        handleAppointmentDuration();
      }
    } catch (e: any) {
      if (e.graphQLErrors?.[0]?.extensions?.code !== "CONFLICT") {
        console.log("conflict");
        throw e;
      }
      confirm({
        title: "Für die ausgewählten Produkte sind keine Kapazitäten frei",
        content: "Möchten Sie die Produkte trotzdem hinzufügen?",
        onOk() {
          validateVacancies({
            values,
            appointmentId,
            index,
            force: true,
            restore,
          });
        },
        onCancel() {
          form?.setFieldsValue({
            ...values,
            products: values.products.map(
              (p: AppointmentProductInput, i: number) => {
                if (i === index) {
                  return {
                    ...p,
                    productId: null,
                    variationId: null,
                    bookedPrice: null,
                    requiredResources: [],
                  };
                }
                return p;
              },
            ),
          });
        },
      });
    }
  };
  return {
    validateVacancies,
    removeTypename: omitDeepArrayWalk,
  };
};

export default useValidateVacancies;
