import { Typography } from "antd";
import { AppointmentStatus } from "../../../../graphql/schema";

interface Props {
  status: AppointmentStatus;
}

const StatusName = ({ status }: Props) => {
  const getName = () => {
    switch (status) {
      case AppointmentStatus.New:
        return "Neu";
      case AppointmentStatus.Present:
        return "Anwesend";
      case AppointmentStatus.NoShow:
        return "Nicht erschienen";
      case AppointmentStatus.Cancelled:
        return "Abgesagt";
      case AppointmentStatus.CancelledByCustomer:
        return "Abgesagt durch Kunde";
      case AppointmentStatus.Absent:
        return "Zeitblocker";
      case AppointmentStatus.RescheduledByKalialab:
        return "Verschoben durch Kalialab";
      case AppointmentStatus.RescheduledByCustomer:
        return "Verschoben durch Kunde";
      case AppointmentStatus.Confirmed:
        return "Bestätigt";
      case AppointmentStatus.ConfirmedWithVirtualPractitioner:
        return "Bestätigt mit virtuellem Behandler";
      case AppointmentStatus.CustomerArrived:
        return "Kunde ist eingetroffen";
      case AppointmentStatus.Accomplished:
        return "Termin abgeschlossen";
      default:
        return "Unbekannt";
    }
  };

  return <Typography.Text>{getName()}</Typography.Text>;
};

export default StatusName;
