import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentStatus: AppointmentStatus;
  appointmentType?: Maybe<AppointmentType>;
  arrivalCustomer?: Maybe<Scalars['DateTime']['output']>;
  bookedPrice: Scalars['String']['output'];
  bookingChannel: BookingChannel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  customers?: Maybe<Array<Customer>>;
  dateFrom: Scalars['DateTime']['output'];
  dateOfBooking: Scalars['DateTime']['output'];
  dateUntil: Scalars['DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  factualBegin?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  lastTouchpointServersideGtm?: Maybe<GtmLastTouchpointOutput>;
  message: Scalars['String']['output'];
  notifyForEarlierAppointment: Scalars['Boolean']['output'];
  practitioners: Array<Practitioner>;
  products: Array<AppointmentProduct>;
  store?: Maybe<Store>;
  treatments: Array<Treatment>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  utmParameters: Scalars['String']['output'];
};

export type AppointmentList = {
  __typename?: 'AppointmentList';
  items: Array<Appointment>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type AppointmentProduct = {
  __typename?: 'AppointmentProduct';
  allocations: Array<ResourceAllocation>;
  appointment: Appointment;
  bookedPrice: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  externalProductId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  package?: Maybe<AppointmentProduct>;
  product?: Maybe<Product>;
  requiredResources?: Maybe<Array<ResourceConfigurationOutput>>;
  totalDuration: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  usedCoupons?: Maybe<Array<Coupon>>;
  variation?: Maybe<Variation>;
};

export type AppointmentProductInput = {
  allocations?: InputMaybe<Array<ResourceAllocationInput>>;
  bookedPrice: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  packageId?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['Int']['input'];
  requiredResources?: InputMaybe<Array<ResourceConfigurationInput>>;
  totalDuration: Scalars['Float']['input'];
  usedCouponIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type AppointmentProductList = {
  __typename?: 'AppointmentProductList';
  items: Array<AppointmentProduct>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export enum AppointmentStatus {
  Absent = 'ABSENT',
  Accomplished = 'ACCOMPLISHED',
  Cancelled = 'CANCELLED',
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  Confirmed = 'CONFIRMED',
  ConfirmedWithVirtualPractitioner = 'CONFIRMED_WITH_VIRTUAL_PRACTITIONER',
  CustomerArrived = 'CUSTOMER_ARRIVED',
  New = 'NEW',
  NoShow = 'NO_SHOW',
  Present = 'PRESENT',
  RescheduledByCustomer = 'RESCHEDULED_BY_CUSTOMER',
  RescheduledByKalialab = 'RESCHEDULED_BY_KALIALAB',
  TreatmentPrepared = 'TREATMENT_PREPARED'
}

export type AppointmentType = {
  __typename?: 'AppointmentType';
  appointments: Array<Appointment>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type AppointmentTypeList = {
  __typename?: 'AppointmentTypeList';
  items: Array<AppointmentType>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type AvailabilitiesDayInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type AvailabilityInput = {
  friday: Array<AvailabilitiesDayInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  monday: Array<AvailabilitiesDayInput>;
  saturday: Array<AvailabilitiesDayInput>;
  storeId: Scalars['Int']['input'];
  sunday: Array<AvailabilitiesDayInput>;
  thursday: Array<AvailabilitiesDayInput>;
  tuesday: Array<AvailabilitiesDayInput>;
  wednesday: Array<AvailabilitiesDayInput>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export enum BookingChannel {
  BookingTrack = 'BOOKING_TRACK',
  Personal = 'PERSONAL',
  SocialMedia = 'SOCIAL_MEDIA',
  Telephone = 'TELEPHONE',
  WalkIn = 'WALK_IN'
}

export type BookingInput = {
  price: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type BusinessHoursInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessHoursOutput = {
  __typename?: 'BusinessHoursOutput';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export enum CommunicationType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export enum Country {
  Austria = 'AUSTRIA',
  Belgium = 'BELGIUM',
  Canada = 'CANADA',
  Denmark = 'DENMARK',
  England = 'ENGLAND',
  Finland = 'FINLAND',
  France = 'FRANCE',
  Germany = 'GERMANY',
  Ireland = 'IRELAND',
  Italy = 'ITALY',
  Luxembourg = 'LUXEMBOURG',
  Netherlands = 'NETHERLANDS',
  NorthernIreland = 'NORTHERN_IRELAND',
  Norway = 'NORWAY',
  Portugal = 'PORTUGAL',
  Scotland = 'SCOTLAND',
  Spain = 'SPAIN',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
  Wales = 'WALES'
}

export type Coupon = {
  __typename?: 'Coupon';
  appointmentProducts?: Maybe<Array<AppointmentProduct>>;
  code: Scalars['String']['output'];
  combinable: Scalars['Boolean']['output'];
  couponType: CouponDiscountType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  customerType: CustomerType;
  description?: Maybe<Scalars['String']['output']>;
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productCategories: Array<ProductCategory>;
  products: Array<Product>;
  realCoupon: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  stores: Array<Store>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  usageType: CouponUsageType;
  value: Scalars['String']['output'];
  variations: Array<Variation>;
};

export enum CouponDiscountType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export type CouponList = {
  __typename?: 'CouponList';
  items: Array<Coupon>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export enum CouponUsageType {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE'
}

export type CouponVerificationInput = {
  couponCodes: Array<Scalars['String']['input']>;
  customer: CustomerInput;
  products: Array<ProductInput>;
};

export type CouponVerificationOutput = {
  __typename?: 'CouponVerificationOutput';
  price?: Maybe<Scalars['String']['output']>;
  productId: Scalars['Float']['output'];
  usedCoupons: Array<Coupon>;
  variationId?: Maybe<Scalars['Int']['output']>;
};

export type CreateAppointmentByBookingRouteInput = {
  additionalCustomerIds: Array<Scalars['Int']['input']>;
  appointment: Scalars['DateTime']['input'];
  couponCodes: Array<Scalars['String']['input']>;
  customerId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  notifyForEarlierAppointment: Scalars['Boolean']['input'];
  products: Array<BookingInput>;
  scuid?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['Int']['input'];
};

export type CreateAppointmentInput = {
  appointmentStatus: Scalars['String']['input'];
  appointmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  arrivalCustomer?: InputMaybe<Scalars['DateTime']['input']>;
  bookedPrice: Scalars['String']['input'];
  bookingChannel: BookingChannel;
  customerIds: Array<Scalars['Int']['input']>;
  dateFrom: Scalars['DateTime']['input'];
  dateOfBooking: Scalars['DateTime']['input'];
  dateUntil: Scalars['DateTime']['input'];
  factualBegin?: InputMaybe<Scalars['DateTime']['input']>;
  forceCreation: Scalars['Boolean']['input'];
  internalNote: Scalars['String']['input'];
  message: Scalars['String']['input'];
  notifyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  notifyForEarlierAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  products?: InputMaybe<Array<AppointmentProductInput>>;
  storeId: Scalars['Int']['input'];
  utmParameters: Scalars['String']['input'];
};

export type CreateAppointmentTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateAvailabilityInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  practitionerId: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['Int']['input'];
};

export type CreateCouponInput = {
  code: Scalars['String']['input'];
  combinable: Scalars['Boolean']['input'];
  couponType: CouponDiscountType;
  customerType: CustomerType;
  description: Scalars['String']['input'];
  expirationDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  productCategoryIds: Array<Scalars['Int']['input']>;
  productIds: Array<Scalars['Int']['input']>;
  realCoupon: Scalars['Boolean']['input'];
  startDate: Scalars['DateTime']['input'];
  storeIds: Array<Scalars['Int']['input']>;
  usageType: CouponUsageType;
  value: Scalars['String']['input'];
  variationIds: Array<Scalars['Int']['input']>;
};

export type CreateCustomerInput = {
  alternativePhone?: InputMaybe<Scalars['String']['input']>;
  category: CustomerType;
  city?: InputMaybe<Scalars['String']['input']>;
  cooperation: Scalars['Boolean']['input'];
  country?: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  preferredCommunicationType?: CommunicationType;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEquipmentCategoryInput = {
  name: Scalars['String']['input'];
};

export type CreateEquipmentInput = {
  dateOfPurchase: Scalars['DateTime']['input'];
  equipmentCategoryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  status: EquipmentStatus;
  storeId: Scalars['Int']['input'];
};

export type CreateMaterialCategoryInput = {
  name: Scalars['String']['input'];
};

export type CreateMaterialInput = {
  categoryId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  inventory: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  storeId: Scalars['Int']['input'];
  unit: Scalars['String']['input'];
};

export type CreatePackageProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePractitionerCategoryInput = {
  name: Scalars['String']['input'];
};

export type CreatePractitionerInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  availabilitiesConfiguration?: InputMaybe<Array<AvailabilityInput>>;
  categoryId: Scalars['Int']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  productCategoryIds: Array<Scalars['Int']['input']>;
  storeIds: Array<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type CreateProductCategoryInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  practitionerIds: Array<Scalars['Int']['input']>;
  productIds: Array<Scalars['Int']['input']>;
};

export type CreateProductInput = {
  available: Scalars['Boolean']['input'];
  blockedBetweenTreatments: Scalars['Float']['input'];
  descriptionLong: Scalars['String']['input'];
  descriptionShort: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  goae: Scalars['String']['input'];
  incompatibleProductIds: Array<Scalars['Int']['input']>;
  index: Scalars['Int']['input'];
  isGoae: Scalars['Boolean']['input'];
  isPackage: Scalars['Boolean']['input'];
  isSubProduct: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nextVersionId?: InputMaybe<Scalars['Int']['input']>;
  packageAmount: Scalars['Float']['input'];
  packageProducts?: InputMaybe<Array<CreatePackageProductInput>>;
  picture: Scalars['String']['input'];
  previousVersionId?: InputMaybe<Scalars['Int']['input']>;
  prices?: InputMaybe<Array<CreateProductPriceInput>>;
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  requiredResources: Array<ResourceConfigurationInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  taxRate: Scalars['String']['input'];
  totalDuration: Scalars['Int']['input'];
  variationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateProductPriceInput = {
  customerType: CustomerType;
  id?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateRoomCategoryInput = {
  name: Scalars['String']['input'];
};

export type CreateRoomInput = {
  categoryId: Scalars['Int']['input'];
  equipmentIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  storeId: Scalars['Int']['input'];
};

export type CreateStoreInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  description: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  friday?: InputMaybe<Array<BusinessHoursInput>>;
  monday?: InputMaybe<Array<BusinessHoursInput>>;
  name: Scalars['String']['input'];
  productIds: Array<Scalars['Float']['input']>;
  saturday?: InputMaybe<Array<BusinessHoursInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sunday?: InputMaybe<Array<BusinessHoursInput>>;
  thursday?: InputMaybe<Array<BusinessHoursInput>>;
  tuesday?: InputMaybe<Array<BusinessHoursInput>>;
  wednesday?: InputMaybe<Array<BusinessHoursInput>>;
  zipCode: Scalars['String']['input'];
};

export type CreateTreatmentInput = {
  appointmentId: Array<Scalars['Int']['input']>;
  customerIds: Array<Scalars['Int']['input']>;
  dateFrom: Scalars['DateTime']['input'];
  dateUntil: Scalars['DateTime']['input'];
  discount: Scalars['String']['input'];
  equipmentIds: Array<Scalars['Int']['input']>;
  invoiceNumber: Scalars['String']['input'];
  materialIds: Array<Scalars['Int']['input']>;
  paymentDate: Scalars['DateTime']['input'];
  paymentStatus: Scalars['String']['input'];
  practitionerIds: Array<Scalars['Int']['input']>;
  price: Scalars['String']['input'];
  productIds: Array<Scalars['Int']['input']>;
  roomIds: Array<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  storeId: Scalars['Int']['input'];
};

export type CreateTreatmentProductInput = {
  productId: Scalars['Int']['input'];
  treatmentId: Scalars['Int']['input'];
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserInput = {
  active?: Scalars['Boolean']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  role: Role;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVariationInput = {
  name: Scalars['String']['input'];
  prices?: InputMaybe<Array<CreateProductPriceInput>>;
  productId: Scalars['Int']['input'];
  requiredResources: Array<ResourceConfigurationInput>;
  totalDuration: Scalars['Int']['input'];
};

export type Customer = {
  __typename?: 'Customer';
  alternativePhone?: Maybe<Scalars['String']['output']>;
  appointments: Array<Appointment>;
  category: CustomerType;
  city?: Maybe<Scalars['String']['output']>;
  cooperation: Scalars['Boolean']['output'];
  country: Country;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  preferredCommunicationType: CommunicationType;
  role: Role;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  termsAccepted: Scalars['Boolean']['output'];
  treatments: Array<Treatment>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  utmParameter?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CustomerAppointmentsOutput = {
  __typename?: 'CustomerAppointmentsOutput';
  appointmentStatus: AppointmentStatus;
  bookedPrice: Scalars['String']['output'];
  dateFrom: Scalars['DateTime']['output'];
  dateUntil: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  products?: Maybe<Array<AppointmentProduct>>;
};

export type CustomerEmailInput = {
  email: Scalars['String']['input'];
};

export type CustomerIdInput = {
  email: Scalars['String']['input'];
};

export type CustomerInput = {
  id: Scalars['Int']['input'];
};

export type CustomerList = {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type CustomerLoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<CustomerLoginOptionsInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerLoginOptionsInput = {
  appointment?: InputMaybe<Scalars['DateTime']['input']>;
  bookedProducts?: InputMaybe<Array<ProductOptions>>;
  customer: CustomerOptions;
  notifyForEarlierAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<StoreOptions>;
};

export type CustomerLoginOutput = {
  __typename?: 'CustomerLoginOutput';
  customer?: Maybe<Customer>;
  mailSent?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CustomerOptions = {
  category?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPhoneInput = {
  phone: Scalars['String']['input'];
};

export type CustomerRegisterInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  preferredCommunicationType?: InputMaybe<CommunicationType>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerResetPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerSetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export enum CustomerType {
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export type DocumentOutput = {
  __typename?: 'DocumentOutput';
  createdAt: Scalars['DateTime']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum DocumentType {
  GenericImage = 'GENERIC_IMAGE'
}

export type Equipment = {
  __typename?: 'Equipment';
  category?: Maybe<EquipmentCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dateOfPurchase: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Room>;
  serialNumber: Scalars['String']['output'];
  status: EquipmentStatus;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['Float']['output']>;
  treatments: Array<Treatment>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type EquipmentCategory = {
  __typename?: 'EquipmentCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  equipment: Array<Equipment>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type EquipmentCategoryList = {
  __typename?: 'EquipmentCategoryList';
  items: Array<EquipmentCategory>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type EquipmentList = {
  __typename?: 'EquipmentList';
  items: Array<Equipment>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export enum EquipmentStatus {
  Available = 'AVAILABLE',
  Defect = 'DEFECT'
}

export type GtmLastTouchpointOutput = {
  __typename?: 'GtmLastTouchpointOutput';
  medium: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type ListContext = {
  filterExpression?: InputMaybe<Scalars['JSONObject']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<SearchQuery>;
  sort?: InputMaybe<Array<SortType>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  access_token: Scalars['String']['output'];
};

export type Material = {
  __typename?: 'Material';
  category?: Maybe<MaterialCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inventory: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  store: Store;
  treatments: Array<Treatment>;
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialCategory = {
  __typename?: 'MaterialCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Int']['output'];
  material: Array<Material>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type MaterialCategoryList = {
  __typename?: 'MaterialCategoryList';
  items: Array<MaterialCategory>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type MaterialList = {
  __typename?: 'MaterialList';
  items: Array<Material>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveProduct: Product;
  cancelAppointment: Scalars['Boolean']['output'];
  createAppointment: Appointment;
  createAppointmentByBookingRoute: Appointment;
  createAppointmentType: AppointmentType;
  createCoupon: Coupon;
  createCustomer: Customer;
  createEquipment: Equipment;
  createEquipmentCategory: EquipmentCategory;
  createMaterial: Material;
  createMaterialCategory: MaterialCategory;
  createPackageProduct: PackageProduct;
  createPractitioner: Practitioner;
  createPractitionerAvailability: PractitionerAvailabilityOutput;
  createPractitionerCategory: PractitionerCategory;
  createProduct: Product;
  createProductCategory: ProductCategory;
  createRoom: Room;
  createRoomCategory: RoomCategory;
  createStore: Store;
  createTreatment: Treatment;
  createTreatmentProduct: Scalars['Boolean']['output'];
  createUser: User;
  createVariation: Variation;
  customerLoginApp: CustomerLoginOutput;
  customerLoginBookingRoute: CustomerLoginOutput;
  deleteAllAppointments: Scalars['Boolean']['output'];
  deleteAppointment: Scalars['Boolean']['output'];
  deleteAppointmentProduct: Scalars['Boolean']['output'];
  deleteAppointmentProducts: Scalars['Boolean']['output'];
  deleteAppointmentType: Scalars['Boolean']['output'];
  deleteCoupon: Scalars['Boolean']['output'];
  deleteCustomer: Scalars['Boolean']['output'];
  deleteCustomerComplete: Scalars['Boolean']['output'];
  deleteDocument: Scalars['Boolean']['output'];
  deleteEquipment: Scalars['Boolean']['output'];
  deleteEquipmentCategory: Scalars['Boolean']['output'];
  deleteMaterial: Scalars['Boolean']['output'];
  deleteMaterialCategory: Scalars['Boolean']['output'];
  deleteOwnCustomer: Scalars['Boolean']['output'];
  deletePackageProduct: Scalars['Boolean']['output'];
  deletePractitioner: Scalars['Boolean']['output'];
  deletePractitionerAvailability: PractitionerAvailabilityOutput;
  deletePractitionerCategory: Scalars['Boolean']['output'];
  deleteProduct: Scalars['Boolean']['output'];
  deleteProductCategory: Scalars['Boolean']['output'];
  deleteProductPrice: Scalars['Boolean']['output'];
  deleteRoom: Scalars['Boolean']['output'];
  deleteRoomCategory: Scalars['Boolean']['output'];
  deleteStore: Scalars['Boolean']['output'];
  deleteTreatment: Scalars['Boolean']['output'];
  deleteTreatmentProduct: Scalars['Boolean']['output'];
  deleteUser: User;
  deleteVariation: Scalars['Boolean']['output'];
  getCustomerIdByEmail: Scalars['Int']['output'];
  login: LoginResponse;
  registerCustomer: CustomerLoginOutput;
  rescheduleAppointmentByCustomer: Scalars['Boolean']['output'];
  resetCustomerPasswordApp: Scalars['Boolean']['output'];
  resetCustomerPasswordBookingRoute: Scalars['Boolean']['output'];
  setCustomerPassword: CustomerLoginOutput;
  singleFileUploadUrl: Scalars['String']['output'];
  updateAppointment: Appointment;
  updateAppointmentType: AppointmentType;
  updateCoupon: Coupon;
  updateCustomer: Customer;
  updateEquipment: Equipment;
  updateEquipmentCategory: EquipmentCategory;
  updateMaterial: Material;
  updateMaterialCategory: MaterialCategory;
  updateOwnCustomer: Customer;
  updateOwnUser: User;
  updatePackageProduct: PackageProduct;
  updatePractitioner: Practitioner;
  updatePractitionerAvailability: PractitionerAvailabilityOutput;
  updatePractitionerCategory: PractitionerCategory;
  updateProduct: Product;
  updateProductCategory: ProductCategory;
  updateRoom: Room;
  updateRoomCategory: RoomCategory;
  updateStore: Store;
  updateTreatment: Treatment;
  updateTreatmentProduct: Scalars['Boolean']['output'];
  updateUser: User;
  updateVariation: Variation;
  userExists: Scalars['Boolean']['output'];
  validateEmail: Scalars['Boolean']['output'];
  validatePhone: Scalars['Boolean']['output'];
  validateVacancies: Array<ValidateVacanciesOutput>;
  verifyCoupons: Array<CouponVerificationOutput>;
};


export type MutationArchiveProductArgs = {
  dto: UpdateProductInput;
  id: Scalars['ID']['input'];
};


export type MutationCancelAppointmentArgs = {
  id: Scalars['Float']['input'];
};


export type MutationCreateAppointmentArgs = {
  dto: CreateAppointmentInput;
};


export type MutationCreateAppointmentByBookingRouteArgs = {
  dto: CreateAppointmentByBookingRouteInput;
};


export type MutationCreateAppointmentTypeArgs = {
  dto: CreateAppointmentTypeInput;
};


export type MutationCreateCouponArgs = {
  dto: CreateCouponInput;
};


export type MutationCreateCustomerArgs = {
  dto: CreateCustomerInput;
};


export type MutationCreateEquipmentArgs = {
  dto: CreateEquipmentInput;
};


export type MutationCreateEquipmentCategoryArgs = {
  dto: CreateEquipmentCategoryInput;
};


export type MutationCreateMaterialArgs = {
  dto: CreateMaterialInput;
};


export type MutationCreateMaterialCategoryArgs = {
  dto: CreateMaterialCategoryInput;
};


export type MutationCreatePackageProductArgs = {
  dto: CreatePackageProductInput;
};


export type MutationCreatePractitionerArgs = {
  dto: CreatePractitionerInput;
};


export type MutationCreatePractitionerAvailabilityArgs = {
  dto: CreateAvailabilityInput;
};


export type MutationCreatePractitionerCategoryArgs = {
  dto: CreatePractitionerCategoryInput;
};


export type MutationCreateProductArgs = {
  dto: CreateProductInput;
};


export type MutationCreateProductCategoryArgs = {
  dto: CreateProductCategoryInput;
};


export type MutationCreateRoomArgs = {
  dto: CreateRoomInput;
};


export type MutationCreateRoomCategoryArgs = {
  dto: CreateRoomCategoryInput;
};


export type MutationCreateStoreArgs = {
  dto: CreateStoreInput;
};


export type MutationCreateTreatmentArgs = {
  dto: CreateTreatmentInput;
};


export type MutationCreateTreatmentProductArgs = {
  dto: CreateTreatmentProductInput;
};


export type MutationCreateUserArgs = {
  dto: CreateUserInput;
};


export type MutationCreateVariationArgs = {
  dto: CreateVariationInput;
};


export type MutationCustomerLoginAppArgs = {
  dto: CustomerLoginInput;
};


export type MutationCustomerLoginBookingRouteArgs = {
  dto: CustomerLoginInput;
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAppointmentProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAppointmentProductsArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type MutationDeleteAppointmentTypeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomerCompleteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDocumentArgs = {
  customerId: Scalars['Float']['input'];
  documentType: DocumentType;
  name: Scalars['String']['input'];
};


export type MutationDeleteEquipmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteEquipmentCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMaterialArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteMaterialCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePackageProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePractitionerArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePractitionerAvailabilityArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePractitionerCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteProductPriceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRoomArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRoomCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteStoreArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTreatmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTreatmentProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteVariationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationGetCustomerIdByEmailArgs = {
  dto: CustomerIdInput;
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRegisterCustomerArgs = {
  dto: CustomerRegisterInput;
};


export type MutationRescheduleAppointmentByCustomerArgs = {
  dto: RescheduleByCustomerInput;
};


export type MutationResetCustomerPasswordAppArgs = {
  dto: CustomerResetPasswordInput;
};


export type MutationResetCustomerPasswordBookingRouteArgs = {
  dto: CustomerResetPasswordInput;
};


export type MutationSetCustomerPasswordArgs = {
  dto: CustomerSetPasswordInput;
};


export type MutationSingleFileUploadUrlArgs = {
  customerId: Scalars['Float']['input'];
  documentType: DocumentType;
  name: Scalars['String']['input'];
};


export type MutationUpdateAppointmentArgs = {
  dto: UpdateAppointmentInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateAppointmentTypeArgs = {
  dto: UpdateAppointmentTypeInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateCouponArgs = {
  dto: UpdateCouponInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateCustomerArgs = {
  dto: UpdateCustomerInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateEquipmentArgs = {
  dto: UpdateEquipmentInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateEquipmentCategoryArgs = {
  dto: UpdateEquipmentCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateMaterialArgs = {
  dto: UpdateMaterialInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateMaterialCategoryArgs = {
  dto: UpdateMaterialCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateOwnCustomerArgs = {
  dto: UpdateOwnCustomerInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateOwnUserArgs = {
  dto: UpdateOwnUserInput;
};


export type MutationUpdatePackageProductArgs = {
  dto: UpdatePackageProductInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdatePractitionerArgs = {
  dto: UpdatePractitionerInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdatePractitionerAvailabilityArgs = {
  dto: UpdateAvailabilityInput;
  id: Scalars['Int']['input'];
};


export type MutationUpdatePractitionerCategoryArgs = {
  dto: UpdatePractitionerCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateProductArgs = {
  dto: UpdateProductInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateProductCategoryArgs = {
  dto: UpdateProductCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateRoomArgs = {
  dto: UpdateRoomInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateRoomCategoryArgs = {
  dto: UpdateRoomCategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateStoreArgs = {
  dto: UpdateStoreInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateTreatmentArgs = {
  dto: UpdateTreatmentInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateTreatmentProductArgs = {
  dto: UpdateTreatmentProductInput;
};


export type MutationUpdateUserArgs = {
  dto: UpdateUserInput;
  id: Scalars['Int']['input'];
};


export type MutationUpdateVariationArgs = {
  dto: UpdateVariationInput;
  id: Scalars['ID']['input'];
};


export type MutationUserExistsArgs = {
  email: Scalars['String']['input'];
};


export type MutationValidateEmailArgs = {
  dto: CustomerEmailInput;
};


export type MutationValidatePhoneArgs = {
  dto: CustomerPhoneInput;
};


export type MutationValidateVacanciesArgs = {
  appointmentId?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['String']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
  products: Array<VacanciesValidateInput>;
  storeId: Scalars['Int']['input'];
};


export type MutationVerifyCouponsArgs = {
  dto: CouponVerificationInput;
};

export type OwnCustomerOutput = {
  __typename?: 'OwnCustomerOutput';
  category?: Maybe<CustomerType>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type PackageProduct = {
  __typename?: 'PackageProduct';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Float']['output'];
  parentProduct: Product;
  product: Product;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  variation?: Maybe<Variation>;
};

export type PackageProductList = {
  __typename?: 'PackageProductList';
  items: Array<PackageProduct>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type Practitioner = {
  __typename?: 'Practitioner';
  active: Scalars['Boolean']['output'];
  appointments: Array<Appointment>;
  availabilitiesConfiguration?: Maybe<Array<PractitionerAvailabilities>>;
  category?: Maybe<PractitionerCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  presenceType: ResourcePresenceType;
  productCategories?: Maybe<Array<ProductCategory>>;
  storeId?: Maybe<Scalars['Float']['output']>;
  stores: Array<Store>;
  title: Scalars['String']['output'];
  treatments?: Maybe<Array<Treatment>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PractitionerAvailabilities = {
  __typename?: 'PractitionerAvailabilities';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  friday?: Maybe<Array<AvailabilityOutput>>;
  id: Scalars['Int']['output'];
  monday?: Maybe<Array<AvailabilityOutput>>;
  practitioner: Practitioner;
  saturday?: Maybe<Array<AvailabilityOutput>>;
  store: Store;
  sunday?: Maybe<Array<AvailabilityOutput>>;
  thursday?: Maybe<Array<AvailabilityOutput>>;
  tuesday?: Maybe<Array<AvailabilityOutput>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  wednesday?: Maybe<Array<AvailabilityOutput>>;
};

export type PractitionerAvailabilityOutput = {
  __typename?: 'PractitionerAvailabilityOutput';
  end: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  practitioner?: Maybe<PractitionerOutput>;
  start: Scalars['DateTime']['output'];
  store?: Maybe<StoreOutput>;
};

export type PractitionerCategory = {
  __typename?: 'PractitionerCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  practitioners: Array<Practitioner>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PractitionerCategoryList = {
  __typename?: 'PractitionerCategoryList';
  items: Array<PractitionerCategory>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type PractitionerList = {
  __typename?: 'PractitionerList';
  items: Array<Practitioner>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type PractitionerOutput = {
  __typename?: 'PractitionerOutput';
  active: Scalars['Boolean']['output'];
  appointments?: Maybe<Array<Appointment>>;
  category?: Maybe<PractitionerCategory>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  presenceType: ResourcePresenceType;
  productCategories?: Maybe<Array<ProductCategory>>;
  stores?: Maybe<Array<Store>>;
  title: Scalars['String']['output'];
  treatments?: Maybe<Array<Treatment>>;
};

export type Product = {
  __typename?: 'Product';
  available: Scalars['Boolean']['output'];
  blockedBetweenTreatments: Scalars['Float']['output'];
  coupons: Array<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  descriptionLong: Scalars['String']['output'];
  descriptionShort: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  goae: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  incompatibleProducts?: Maybe<Array<Product>>;
  index?: Maybe<Scalars['Float']['output']>;
  isGoae: Scalars['Boolean']['output'];
  isPackage: Scalars['Boolean']['output'];
  isSubProduct: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nextVersionId?: Maybe<Scalars['Float']['output']>;
  packageAmount: Scalars['Float']['output'];
  packageProducts: Array<PackageProduct>;
  picture: Scalars['String']['output'];
  previousVersionId?: Maybe<Scalars['Float']['output']>;
  prices: Array<ProductPrice>;
  productCategory?: Maybe<ProductCategory>;
  requiredResources?: Maybe<Array<ResourceConfigurationOutput>>;
  slug?: Maybe<Scalars['String']['output']>;
  stores: Array<Store>;
  taxRate: Scalars['String']['output'];
  totalDuration: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  variations: Array<Variation>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  coupon: Array<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  practitioners: Array<Practitioner>;
  products: Array<Product>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ProductCategoryList = {
  __typename?: 'ProductCategoryList';
  items: Array<ProductCategory>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type ProductCategoryOutput = {
  __typename?: 'ProductCategoryOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ProductInput = {
  price?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  items: Array<Product>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type ProductOptions = {
  baseName?: InputMaybe<Scalars['String']['input']>;
  category: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  netPrice?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  variation?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductOutput = {
  __typename?: 'ProductOutput';
  available: Scalars['Boolean']['output'];
  blockedBetweenTreatments: Scalars['Float']['output'];
  coupons: Array<Coupon>;
  descriptionLong: Scalars['String']['output'];
  descriptionShort: Scalars['String']['output'];
  goae: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  incompatibleProducts?: Maybe<Array<ProductOutput>>;
  index: Scalars['Float']['output'];
  isGoae: Scalars['Boolean']['output'];
  isPackage: Scalars['Boolean']['output'];
  isSubProduct: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  packageAmount: Scalars['Float']['output'];
  packageProduct: PackageProduct;
  packageProducts?: Maybe<Array<PackageProduct>>;
  picture: Scalars['String']['output'];
  prices: Array<ProductPriceOutput>;
  productCategory?: Maybe<ProductCategoryOutput>;
  requiredResources?: Maybe<Array<ResourceConfigurationOutput>>;
  taxRate: Scalars['String']['output'];
  totalDuration: Scalars['Int']['output'];
  variations: Array<VariationOutput>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  customerType: CustomerType;
  id: Scalars['Float']['output'];
  price: Scalars['String']['output'];
  product?: Maybe<Product>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
  variation?: Maybe<Variation>;
};

export type ProductPriceOutput = {
  __typename?: 'ProductPriceOutput';
  customerType: CustomerType;
  id: Scalars['Int']['output'];
  price: Scalars['String']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  accomplishedCustomerAppointments: Array<Appointment>;
  appointment: Appointment;
  appointmentProduct: AppointmentProduct;
  appointmentProducts: AppointmentProductList;
  appointmentType: AppointmentType;
  appointmentTypes: AppointmentTypeList;
  appointments: AppointmentList;
  appointmentsBetweenDates: Array<Appointment>;
  appointmentsBetweenDatesByPractitioner: Array<Appointment>;
  appointmentsByAppointmentStatus: Array<Appointment>;
  appointmentsByProductId: Array<Appointment>;
  countEquipmentAllocations: Scalars['Int']['output'];
  countPractitionerAllocations: Scalars['Int']['output'];
  countRoomAllocations: Scalars['Int']['output'];
  coupon: Coupon;
  couponByCode: Coupon;
  coupons: CouponList;
  customer: Customer;
  customerAppointment: Appointment;
  customerAppointments: Array<CustomerAppointmentsOutput>;
  customers: CustomerList;
  equipment: Equipment;
  equipmentCategories: EquipmentCategoryList;
  equipmentCategory: EquipmentCategory;
  equipments: EquipmentList;
  isExistingCustomer: Scalars['Boolean']['output'];
  listDocumentsByCustomer: Array<DocumentOutput>;
  material: Material;
  materialCategories: MaterialCategoryList;
  materialCategory: MaterialCategory;
  materials: MaterialList;
  ownCustomer?: Maybe<OwnCustomerOutput>;
  ownDocuments: Array<DocumentOutput>;
  ownUser: User;
  packageProduct: PackageProduct;
  packageProducts: PackageProductList;
  practitioner: Practitioner;
  practitionerAvailabilitiesByPractitionerId: Array<PractitionerAvailabilityOutput>;
  practitionerCategories: PractitionerCategoryList;
  practitionerCategory: PractitionerCategory;
  practitioners: PractitionerList;
  practitionersAvailabilitiesByStoreId: Array<PractitionerAvailabilityOutput>;
  product: Product;
  productCategories: ProductCategoryList;
  productCategory: ProductCategory;
  products: ProductList;
  publicProductCategories: Array<ProductCategoryOutput>;
  publicStore: Store;
  publicStores: Array<StoreOutput>;
  publicVariation: Variation;
  resourceAllocationsBetweenDatesForType: Array<ResourceAllocationOutput>;
  room: Room;
  roomCategories: RoomCategoryList;
  roomCategory: RoomCategory;
  rooms: RoomList;
  store: Store;
  stores: StoreList;
  treatment: Treatment;
  treatmentProduct: TreatmentProduct;
  treatmentProducts: TreatmentProductList;
  treatments: TreatmentList;
  upcomingCustomerAppointments: Array<Appointment>;
  user: User;
  users: UserList;
  vacanciesSearch: VacanciesSearchOutput;
  validateProductSelection: ValidatedProductSelection;
  variation: Variation;
  variations: VariationList;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentProductsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryAppointmentTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentTypesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryAppointmentsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryAppointmentsBetweenDatesArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryAppointmentsBetweenDatesByPractitionerArgs = {
  endDate: Scalars['String']['input'];
  practitionerId: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryAppointmentsByAppointmentStatusArgs = {
  appointmentStatus: Scalars['String']['input'];
  filteredId?: InputMaybe<Scalars['Float']['input']>;
  storeId: Scalars['Float']['input'];
};


export type QueryAppointmentsByProductIdArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryCountEquipmentAllocationsArgs = {
  id: Scalars['Int']['input'];
  inFuture: Scalars['Boolean']['input'];
};


export type QueryCountPractitionerAllocationsArgs = {
  id: Scalars['Int']['input'];
  inFuture: Scalars['Boolean']['input'];
};


export type QueryCountRoomAllocationsArgs = {
  id: Scalars['Int']['input'];
  inFuture: Scalars['Boolean']['input'];
};


export type QueryCouponArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCouponByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryCouponsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomersArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryEquipmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentCategoriesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryEquipmentCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryIsExistingCustomerArgs = {
  email: Scalars['String']['input'];
};


export type QueryListDocumentsByCustomerArgs = {
  customerId: Scalars['Float']['input'];
};


export type QueryMaterialArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMaterialCategoriesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryMaterialCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMaterialsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryPackageProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPackageProductsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryPractitionerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPractitionerAvailabilitiesByPractitionerIdArgs = {
  end: Scalars['DateTime']['input'];
  practitionerId: Scalars['Int']['input'];
  start: Scalars['DateTime']['input'];
};


export type QueryPractitionerCategoriesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryPractitionerCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPractitionersArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryPractitionersAvailabilitiesByStoreIdArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
  storeId: Scalars['Int']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCategoriesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryProductCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryPublicStoreArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPublicVariationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResourceAllocationsBetweenDatesForTypeArgs = {
  end: Scalars['DateTime']['input'];
  resourceType: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};


export type QueryRoomArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomCategoriesArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryRoomCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryStoreArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStoresArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryTreatmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTreatmentProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTreatmentProductsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryTreatmentsArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUsersArgs = {
  options?: InputMaybe<ListContext>;
};


export type QueryVacanciesSearchArgs = {
  input: VacanciesSearchInput;
};


export type QueryValidateProductSelectionArgs = {
  products: Array<ValidateProductSelectionInput>;
  store: Scalars['Int']['input'];
};


export type QueryVariationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVariationsArgs = {
  options?: InputMaybe<ListContext>;
};

export type RescheduleByCustomerInput = {
  additionalCustomerIds: Array<Scalars['Int']['input']>;
  appointment: Scalars['DateTime']['input'];
  couponCodes: Array<Scalars['String']['input']>;
  customerId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  notifyForEarlierAppointment: Scalars['Boolean']['input'];
  products: Array<BookingInput>;
  rescheduleId?: InputMaybe<Scalars['Int']['input']>;
  scuid?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['Int']['input'];
};

export type ResourceAllocation = {
  __typename?: 'ResourceAllocation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  end: Scalars['DateTime']['output'];
  externalResourceBlockerId?: Maybe<Scalars['String']['output']>;
  externalResourceBlockerName?: Maybe<Scalars['String']['output']>;
  externalResourceId?: Maybe<Scalars['String']['output']>;
  externalResourceName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  resourceAllocatorId: Scalars['Float']['output'];
  resourceAllocatorType: ResourceAllocatorType;
  resourceBlockerId: Scalars['Float']['output'];
  resourceBlockerType: ResourceBlockerType;
  resourceId: Scalars['Float']['output'];
  resourcePresenceType?: Maybe<ResourcePresenceType>;
  resourceRequirements?: Maybe<Array<ResourceAllocationRequirementOutput>>;
  resourceType: ResourceType;
  start: Scalars['DateTime']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ResourceAllocationInput = {
  end: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  resourceBlockerId: Scalars['Int']['input'];
  resourceBlockerType: ResourceBlockerType;
  resourceId?: InputMaybe<Scalars['Int']['input']>;
  resourceRequirements: Array<ResourceAllocationRequirementInput>;
  resourceType: ResourceType;
  start: Scalars['String']['input'];
};

export type ResourceAllocationOutput = {
  __typename?: 'ResourceAllocationOutput';
  end: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['Float']['output']>;
  resourceAllocatorId: Scalars['Float']['output'];
  resourceBlockerId: Scalars['Float']['output'];
  resourceBlockerType: ResourceBlockerType;
  resourceId?: Maybe<Scalars['Float']['output']>;
  resourceRequirements: Array<ResourceAllocationRequirementOutput>;
  resourceType: ResourceType;
  start: Scalars['DateTime']['output'];
};

export type ResourceAllocationRequirementInput = {
  operator?: InputMaybe<Scalars['String']['input']>;
  property: Scalars['String']['input'];
  value: Array<Scalars['Float']['input']>;
};

export type ResourceAllocationRequirementOutput = {
  __typename?: 'ResourceAllocationRequirementOutput';
  property: Scalars['String']['output'];
  value: Array<Scalars['Float']['output']>;
};

export enum ResourceAllocatorType {
  Appointment = 'APPOINTMENT'
}

export enum ResourceBlockerType {
  AppointmentProduct = 'APPOINTMENT_PRODUCT',
  Blocker = 'BLOCKER'
}

export type ResourceConfigurationInput = {
  duration?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  resourceRequirements: Array<ResourceAllocationRequirementInput>;
  resourceType: ResourceType;
};

export type ResourceConfigurationOutput = {
  __typename?: 'ResourceConfigurationOutput';
  duration?: Maybe<Scalars['Float']['output']>;
  offset?: Maybe<Scalars['Float']['output']>;
  resourceRequirements?: Maybe<Array<ResourceAllocationRequirementOutput>>;
  resourceType: ResourceType;
};

export enum ResourcePresenceType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export enum ResourceType {
  Equipment = 'EQUIPMENT',
  Practitioner = 'PRACTITIONER',
  Room = 'ROOM'
}

export enum Role {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  User = 'USER'
}

export type Room = {
  __typename?: 'Room';
  category?: Maybe<RoomCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  equipment: Array<Equipment>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['Float']['output']>;
  treatments: Array<Treatment>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type RoomCategory = {
  __typename?: 'RoomCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rooms: Array<Room>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type RoomCategoryList = {
  __typename?: 'RoomCategoryList';
  items: Array<RoomCategory>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type RoomList = {
  __typename?: 'RoomList';
  items: Array<Room>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type SearchQuery = {
  fields?: InputMaybe<Array<Scalars['String']['input']>>;
  term: Scalars['String']['input'];
};

export type SortType = {
  field: Scalars['String']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
};

export type Store = {
  __typename?: 'Store';
  address: Scalars['String']['output'];
  appointments: Array<Appointment>;
  city: Scalars['String']['output'];
  coupons: Array<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  equipment: Array<Equipment>;
  externalId?: Maybe<Scalars['String']['output']>;
  friday?: Maybe<Array<BusinessHoursOutput>>;
  id: Scalars['Int']['output'];
  material: Array<Material>;
  monday?: Maybe<Array<BusinessHoursOutput>>;
  name: Scalars['String']['output'];
  practitioners: Array<Practitioner>;
  products: Array<Product>;
  rooms: Array<Room>;
  saturday?: Maybe<Array<BusinessHoursOutput>>;
  slug?: Maybe<Scalars['String']['output']>;
  sunday?: Maybe<Array<BusinessHoursOutput>>;
  thursday?: Maybe<Array<BusinessHoursOutput>>;
  treatments: Array<Treatment>;
  tuesday?: Maybe<Array<BusinessHoursOutput>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  wednesday?: Maybe<Array<BusinessHoursOutput>>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type StoreList = {
  __typename?: 'StoreList';
  items: Array<Store>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type StoreOptions = {
  id?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type StoreOutput = {
  __typename?: 'StoreOutput';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  description: Scalars['String']['output'];
  friday?: Maybe<Array<BusinessHoursOutput>>;
  id: Scalars['Int']['output'];
  monday?: Maybe<Array<BusinessHoursOutput>>;
  name: Scalars['String']['output'];
  products: Array<ProductOutput>;
  saturday?: Maybe<Array<BusinessHoursOutput>>;
  slug?: Maybe<Scalars['String']['output']>;
  sunday?: Maybe<Array<BusinessHoursOutput>>;
  thursday?: Maybe<Array<BusinessHoursOutput>>;
  tuesday?: Maybe<Array<BusinessHoursOutput>>;
  wednesday?: Maybe<Array<BusinessHoursOutput>>;
  zipCode: Scalars['String']['output'];
};

export type Treatment = {
  __typename?: 'Treatment';
  appointments: Array<Appointment>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  customers: Array<Customer>;
  dateFrom: Scalars['DateTime']['output'];
  dateUntil: Scalars['DateTime']['output'];
  discount?: Maybe<Scalars['String']['output']>;
  equipment: Array<Equipment>;
  id: Scalars['Float']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  material: Array<Material>;
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  practitioners: Array<Practitioner>;
  price: Scalars['String']['output'];
  products: Array<TreatmentProduct>;
  rooms: Array<Room>;
  status: Scalars['String']['output'];
  store?: Maybe<Store>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TreatmentList = {
  __typename?: 'TreatmentList';
  items: Array<Treatment>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type TreatmentProduct = {
  __typename?: 'TreatmentProduct';
  amount: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Float']['output'];
  product?: Maybe<Product>;
  treatment: Treatment;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  variation?: Maybe<Variation>;
};

export type TreatmentProductList = {
  __typename?: 'TreatmentProductList';
  items: Array<TreatmentProduct>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type UpdateAppointmentInput = {
  appointmentStatus?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  arrivalCustomer?: InputMaybe<Scalars['DateTime']['input']>;
  bookedPrice?: InputMaybe<Scalars['String']['input']>;
  bookingChannel?: InputMaybe<BookingChannel>;
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBooking?: InputMaybe<Scalars['DateTime']['input']>;
  dateUntil?: InputMaybe<Scalars['DateTime']['input']>;
  factualBegin?: InputMaybe<Scalars['DateTime']['input']>;
  forceCreation?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  notifyForEarlierAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  products?: InputMaybe<Array<AppointmentProductInput>>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
  utmParameters?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAppointmentTypeInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvailabilityInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCouponInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  combinable?: InputMaybe<Scalars['Boolean']['input']>;
  couponType?: InputMaybe<CouponDiscountType>;
  customerType?: InputMaybe<CustomerType>;
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  realCoupon?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  usageType?: InputMaybe<CouponUsageType>;
  value?: InputMaybe<Scalars['String']['input']>;
  variationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateCustomerInput = {
  alternativePhone?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<CustomerType>;
  city?: InputMaybe<Scalars['String']['input']>;
  cooperation?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationType?: InputMaybe<CommunicationType>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  termsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEquipmentCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEquipmentInput = {
  dateOfPurchase?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentCategoryId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EquipmentStatus>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMaterialCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMaterialInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  inventory?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOwnCustomerInput = {
  city: Scalars['String']['input'];
  country: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  preferredCommunicationType: CommunicationType;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type UpdateOwnUserInput = {
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePackageProductInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePractitionerCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePractitionerInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  availabilitiesConfiguration?: InputMaybe<Array<AvailabilityInput>>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  productCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductCategoryInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateProductInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blockedBetweenTreatments?: InputMaybe<Scalars['Float']['input']>;
  descriptionLong?: InputMaybe<Scalars['String']['input']>;
  descriptionShort?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  goae?: InputMaybe<Scalars['String']['input']>;
  incompatibleProductIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isGoae?: InputMaybe<Scalars['Boolean']['input']>;
  isPackage?: InputMaybe<Scalars['Boolean']['input']>;
  isSubProduct?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextVersionId?: InputMaybe<Scalars['Int']['input']>;
  packageAmount?: InputMaybe<Scalars['Float']['input']>;
  packageProducts?: InputMaybe<Array<CreatePackageProductInput>>;
  picture?: InputMaybe<Scalars['String']['input']>;
  previousVersionId?: InputMaybe<Scalars['Int']['input']>;
  prices?: InputMaybe<Array<CreateProductPriceInput>>;
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  requiredResources?: InputMaybe<Array<ResourceConfigurationInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  storeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  taxRate?: InputMaybe<Scalars['String']['input']>;
  totalDuration?: InputMaybe<Scalars['Int']['input']>;
  variationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateRoomCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoomInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStoreInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  friday?: InputMaybe<Array<BusinessHoursInput>>;
  monday?: InputMaybe<Array<BusinessHoursInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  saturday?: InputMaybe<Array<BusinessHoursInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sunday?: InputMaybe<Array<BusinessHoursInput>>;
  thursday?: InputMaybe<Array<BusinessHoursInput>>;
  tuesday?: InputMaybe<Array<BusinessHoursInput>>;
  wednesday?: InputMaybe<Array<BusinessHoursInput>>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTreatmentInput = {
  appointmentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateUntil?: InputMaybe<Scalars['DateTime']['input']>;
  discount?: InputMaybe<Scalars['String']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  materialIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentStatus?: InputMaybe<Scalars['String']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  price?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  roomIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTreatmentProductInput = {
  id: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
  treatmentId?: InputMaybe<Scalars['Int']['input']>;
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  role?: InputMaybe<Role>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVariationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  prices?: InputMaybe<Array<CreateProductPriceInput>>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  requiredResources?: InputMaybe<Array<ResourceConfigurationInput>>;
  totalDuration?: InputMaybe<Scalars['Int']['input']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  role: Role;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserList = {
  __typename?: 'UserList';
  items: Array<User>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type VacanciesSearchInput = {
  end: Scalars['DateTime']['input'];
  products: Array<VacanciesSearchProductInput>;
  start: Scalars['DateTime']['input'];
  store: Scalars['Int']['input'];
};

export type VacanciesSearchOutput = {
  __typename?: 'VacanciesSearchOutput';
  slots: Array<Scalars['Float']['output']>;
  totalSlots: Scalars['Float']['output'];
};

export type VacanciesSearchProductInput = {
  bookedPrice?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  packageId?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  requiredResources?: InputMaybe<Array<ResourceConfigurationInput>>;
  variationId?: InputMaybe<Scalars['Int']['input']>;
};

export type VacanciesValidateInput = {
  bookedPrice: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  packageId?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['Float']['input'];
  requiredResources: Array<ResourceConfigurationInput>;
  variationId?: InputMaybe<Scalars['Float']['input']>;
};

export type ValidateProductSelectionInput = {
  id: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['String']['input']>;
  variation?: InputMaybe<Scalars['Int']['input']>;
};

export type ValidateProductSelectionOutput = {
  __typename?: 'ValidateProductSelectionOutput';
  id: Scalars['Int']['output'];
  price: Scalars['String']['output'];
  variation?: Maybe<Scalars['Int']['output']>;
};

export type ValidateVacanciesOutput = {
  __typename?: 'ValidateVacanciesOutput';
  allocations: Array<ResourceAllocationOutput>;
  bookedPrice: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  packageId?: Maybe<Scalars['Float']['output']>;
  productId: Scalars['Float']['output'];
  requiredResources: Array<ResourceConfigurationOutput>;
  totalDuration: Scalars['Float']['output'];
  variationId?: Maybe<Scalars['Float']['output']>;
};

export type ValidatedProductSelection = {
  __typename?: 'ValidatedProductSelection';
  products: Array<ValidateProductSelectionOutput>;
};

export type Variation = {
  __typename?: 'Variation';
  appointmentProducts: Array<AppointmentProduct>;
  coupons: Array<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  prices: Array<ProductPrice>;
  product: Product;
  requiredResources: Array<ResourceConfigurationOutput>;
  totalDuration: Scalars['Float']['output'];
  treatmentProducts: Array<TreatmentProduct>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type VariationList = {
  __typename?: 'VariationList';
  items: Array<Variation>;
  itemsPerPage: Scalars['Int']['output'];
  numberOfItems: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type VariationOutput = {
  __typename?: 'VariationOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prices: Array<ProductPriceOutput>;
  requiredResources?: Maybe<Array<ResourceConfigurationOutput>>;
  totalDuration: Scalars['Float']['output'];
};

export type CouponFragment = { __typename?: 'Coupon', id: number, name: string, code: string, description?: string | null, expirationDate: any, startDate: any, customerType: CustomerType, usageType: CouponUsageType, realCoupon: boolean, combinable: boolean, couponType: CouponDiscountType, value: string, variations: Array<{ __typename?: 'Variation', id: number, name: string }>, stores: Array<{ __typename?: 'Store', id: number, name: string }>, products: Array<{ __typename?: 'Product', id: number, name: string }>, productCategories: Array<{ __typename?: 'ProductCategory', id: number, name: string }> };

export type CouponVerificationOutputFragment = { __typename?: 'CouponVerificationOutput', productId: number, variationId?: number | null, price?: string | null, usedCoupons: Array<{ __typename?: 'Coupon', id: number }> };

export type VerifyCouponsMutationVariables = Exact<{
  dto: CouponVerificationInput;
}>;


export type VerifyCouponsMutation = { __typename?: 'Mutation', verifyCoupons: Array<{ __typename?: 'CouponVerificationOutput', productId: number, variationId?: number | null, price?: string | null, usedCoupons: Array<{ __typename?: 'Coupon', id: number }> }> };

export type GetCouponByCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type GetCouponByCodeQuery = { __typename?: 'Query', couponByCode: { __typename?: 'Coupon', id: number, code: string, description?: string | null } };

export type CustomerFragment = { __typename?: 'Customer', id: number, firstName: string, lastName: string, email: string, phone: string, street?: string | null, streetNumber?: string | null, city?: string | null, zipCode?: string | null, country: Country, preferredCommunicationType: CommunicationType, cooperation: boolean, category: CustomerType, termsAccepted: boolean, utmParameter?: string | null, appointments: Array<{ __typename?: 'Appointment', id: number }>, treatments: Array<{ __typename?: 'Treatment', id: number }> };

export type CustomerLoginOutputFragment = { __typename?: 'Customer', id: number, email: string, firstName: string, lastName: string };

export type CustomerLoginMutationVariables = Exact<{
  dto: CustomerLoginInput;
}>;


export type CustomerLoginMutation = { __typename?: 'Mutation', customerLoginBookingRoute: { __typename?: 'CustomerLoginOutput', token?: string | null, mailSent?: boolean | null, customer?: { __typename?: 'Customer', id: number, firstName: string, lastName: string, category: CustomerType } | null } };

export type DeleteOwnCustomerMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteOwnCustomerMutation = { __typename?: 'Mutation', deleteOwnCustomer: boolean };

export type GetCustomerIdByEmailMutationVariables = Exact<{
  dto: CustomerIdInput;
}>;


export type GetCustomerIdByEmailMutation = { __typename?: 'Mutation', getCustomerIdByEmail: number };

export type RegisterCustomerMutationVariables = Exact<{
  dto: CustomerRegisterInput;
}>;


export type RegisterCustomerMutation = { __typename?: 'Mutation', registerCustomer: { __typename?: 'CustomerLoginOutput', token?: string | null, customer?: { __typename?: 'Customer', id: number, firstName: string, lastName: string, category: CustomerType, email: string, phone: string } | null } };

export type ResetCustomerPasswordMutationVariables = Exact<{
  dto: CustomerResetPasswordInput;
}>;


export type ResetCustomerPasswordMutation = { __typename?: 'Mutation', resetCustomerPasswordBookingRoute: boolean };

export type SetCustomerPasswordMutationVariables = Exact<{
  dto: CustomerSetPasswordInput;
}>;


export type SetCustomerPasswordMutation = { __typename?: 'Mutation', setCustomerPassword: { __typename?: 'CustomerLoginOutput', token?: string | null } };

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  dto: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: number, firstName: string, lastName: string, email: string, phone: string, street?: string | null, streetNumber?: string | null, city?: string | null, zipCode?: string | null, country: Country, preferredCommunicationType: CommunicationType, cooperation: boolean, category: CustomerType, termsAccepted: boolean, utmParameter?: string | null, appointments: Array<{ __typename?: 'Appointment', id: number }>, treatments: Array<{ __typename?: 'Treatment', id: number }> } };

export type ValidateCustomerEmailMutationVariables = Exact<{
  dto: CustomerEmailInput;
}>;


export type ValidateCustomerEmailMutation = { __typename?: 'Mutation', validateEmail: boolean };

export type ValidateCustomerPhoneMutationVariables = Exact<{
  dto: CustomerPhoneInput;
}>;


export type ValidateCustomerPhoneMutation = { __typename?: 'Mutation', validatePhone: boolean };

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: number, firstName: string, lastName: string, email: string, phone: string, street?: string | null, streetNumber?: string | null, city?: string | null, zipCode?: string | null, country: Country, preferredCommunicationType: CommunicationType, cooperation: boolean, category: CustomerType, termsAccepted: boolean, utmParameter?: string | null, appointments: Array<{ __typename?: 'Appointment', id: number }>, treatments: Array<{ __typename?: 'Treatment', id: number }> } };

export type GetOwnCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnCustomerQuery = { __typename?: 'Query', ownCustomer?: { __typename?: 'OwnCustomerOutput', id?: number | null, category?: CustomerType | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null } | null };

export type GetOwnDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnDocumentsQuery = { __typename?: 'Query', ownDocuments: Array<{ __typename?: 'DocumentOutput', name: string, mimeType: string, url: string, createdAt: any }> };

export type GetIsExistingCustomerQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetIsExistingCustomerQuery = { __typename?: 'Query', isExistingCustomer: boolean };

export type ProductFragment = { __typename?: 'Product', id: number, name: string, index?: number | null, descriptionShort: string, descriptionLong: string, isPackage: boolean, totalDuration: number, prices: Array<{ __typename?: 'ProductPrice', id: number, price: string, validFrom?: any | null, validTo?: any | null, customerType: CustomerType }>, variations: Array<{ __typename?: 'Variation', id: number, name: string, prices: Array<{ __typename?: 'ProductPrice', id: number, price: string, validFrom?: any | null, validTo?: any | null, customerType: CustomerType }> }>, packageProducts: Array<{ __typename?: 'PackageProduct', id: number }>, requiredResources?: Array<{ __typename?: 'ResourceConfigurationOutput', resourceType: ResourceType, resourceRequirements?: Array<{ __typename?: 'ResourceAllocationRequirementOutput', property: string, value: Array<number> }> | null }> | null, productCategory?: { __typename?: 'ProductCategory', id: number, name: string } | null };

export type PublicProductCategoryFragment = { __typename?: 'ProductCategoryOutput', id: number, name: string };

export type ValidatedProductFragment = { __typename?: 'ValidateProductSelectionOutput', id: number, variation?: number | null, price: string };

export type VariationFragment = { __typename?: 'Variation', id: number, name: string, prices: Array<{ __typename?: 'ProductPrice', id: number, customerType: CustomerType, validFrom?: any | null, validTo?: any | null, price: string }> };

export type GetPublicProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicProductCategoriesQuery = { __typename?: 'Query', publicProductCategories: Array<{ __typename?: 'ProductCategoryOutput', id: number, name: string }> };

export type GetPublicVariationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPublicVariationQuery = { __typename?: 'Query', variation: { __typename?: 'Variation', id: number, name: string, prices: Array<{ __typename?: 'ProductPrice', id: number, customerType: CustomerType, validFrom?: any | null, validTo?: any | null, price: string }> } };

export type ValidateProductSelectionQueryVariables = Exact<{
  store: Scalars['Int']['input'];
  products: Array<ValidateProductSelectionInput> | ValidateProductSelectionInput;
}>;


export type ValidateProductSelectionQuery = { __typename?: 'Query', validateProductSelection: { __typename?: 'ValidatedProductSelection', products: Array<{ __typename?: 'ValidateProductSelectionOutput', id: number, variation?: number | null, price: string }> } };

export type StoreOutputFragment = { __typename?: 'StoreOutput', id: number, name: string, address: string, description: string, zipCode: string, city: string, slug?: string | null, monday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, tuesday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, wednesday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, thursday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, friday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, saturday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, sunday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null };

export type GetPublicStoreQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetPublicStoreQuery = { __typename?: 'Query', publicStore: { __typename?: 'Store', id: number, name: string, address: string, description: string, city: string, slug?: string | null, monday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, tuesday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, wednesday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, thursday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, friday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, saturday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, sunday?: Array<{ __typename?: 'BusinessHoursOutput', start?: string | null, end?: string | null }> | null, products: Array<{ __typename?: 'Product', id: number, name: string, slug?: string | null, index?: number | null, taxRate: string, descriptionShort: string, descriptionLong: string, totalDuration: number, incompatibleProducts?: Array<{ __typename?: 'Product', id: number, name: string }> | null, prices: Array<{ __typename?: 'ProductPrice', id: number, price: string, validFrom?: any | null, validTo?: any | null, customerType: CustomerType }>, variations: Array<{ __typename?: 'Variation', id: number, name: string, totalDuration: number, prices: Array<{ __typename?: 'ProductPrice', id: number, price: string, validFrom?: any | null, validTo?: any | null, customerType: CustomerType }> }>, productCategory?: { __typename?: 'ProductCategory', id: number, name: string } | null }> } };

export type GetPublicStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicStoresQuery = { __typename?: 'Query', publicStores: Array<{ __typename?: 'StoreOutput', id: number, name: string, address: string, description: string, city: string, slug?: string | null, zipCode: string }> };

export type AppointmentFragment = { __typename?: 'Appointment', id: number, dateFrom: any, dateUntil: any, arrivalCustomer?: any | null, factualBegin?: any | null, dateOfBooking: any, bookedPrice: string, appointmentStatus: AppointmentStatus, bookingChannel: BookingChannel, utmParameters: string, customers?: Array<{ __typename?: 'Customer', id: number, firstName: string, lastName: string }> | null, store?: { __typename?: 'Store', id: number, name: string, address: string, city: string, zipCode?: string | null } | null, appointmentType?: { __typename?: 'AppointmentType', id: number, name: string } | null, practitioners: Array<{ __typename?: 'Practitioner', id: number, title: string, firstName: string, lastName: string, presenceType: ResourcePresenceType }>, treatments: Array<{ __typename?: 'Treatment', id: number }>, products: Array<{ __typename?: 'AppointmentProduct', id: number, name?: string | null, bookedPrice: string, package?: { __typename?: 'AppointmentProduct', id: number } | null, product?: { __typename?: 'Product', id: number, taxRate: string, name: string, productCategory?: { __typename?: 'ProductCategory', id: number, name: string } | null } | null, variation?: { __typename?: 'Variation', id: number, name: string } | null }> };

export type CustomerAppointmentFragment = { __typename?: 'CustomerAppointmentsOutput', id: number, dateFrom: any, dateUntil: any, appointmentStatus: AppointmentStatus, bookedPrice: string, products?: Array<{ __typename?: 'AppointmentProduct', id: number, product?: { __typename?: 'Product', id: number, name: string } | null, variation?: { __typename?: 'Variation', id: number, name: string } | null }> | null };

export type CancelAppointmentByCustomerMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type CancelAppointmentByCustomerMutation = { __typename?: 'Mutation', cancelAppointment: boolean };

export type CreateAppointmentByBookingRouteMutationVariables = Exact<{
  dto: CreateAppointmentByBookingRouteInput;
}>;


export type CreateAppointmentByBookingRouteMutation = { __typename?: 'Mutation', createAppointmentByBookingRoute: { __typename?: 'Appointment', id: number, bookedPrice: string } };

export type RescheduleAppointmentByCustomerMutationVariables = Exact<{
  dto: RescheduleByCustomerInput;
}>;


export type RescheduleAppointmentByCustomerMutation = { __typename?: 'Mutation', rescheduleAppointmentByCustomer: boolean };

export type UpdateAppointmentMutationVariables = Exact<{
  dto: UpdateAppointmentInput;
  id: Scalars['ID']['input'];
}>;


export type UpdateAppointmentMutation = { __typename?: 'Mutation', updateAppointment: { __typename?: 'Appointment', id: number } };

export type GetCustomerAppointmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomerAppointmentQuery = { __typename?: 'Query', customerAppointment: { __typename?: 'Appointment', id: number, dateFrom: any, dateUntil: any, arrivalCustomer?: any | null, factualBegin?: any | null, dateOfBooking: any, bookedPrice: string, appointmentStatus: AppointmentStatus, bookingChannel: BookingChannel, utmParameters: string, customers?: Array<{ __typename?: 'Customer', id: number, firstName: string, lastName: string }> | null, store?: { __typename?: 'Store', id: number, name: string, address: string, city: string, zipCode?: string | null } | null, appointmentType?: { __typename?: 'AppointmentType', id: number, name: string } | null, practitioners: Array<{ __typename?: 'Practitioner', id: number, title: string, firstName: string, lastName: string, presenceType: ResourcePresenceType }>, treatments: Array<{ __typename?: 'Treatment', id: number }>, products: Array<{ __typename?: 'AppointmentProduct', id: number, name?: string | null, bookedPrice: string, package?: { __typename?: 'AppointmentProduct', id: number } | null, product?: { __typename?: 'Product', id: number, taxRate: string, name: string, productCategory?: { __typename?: 'ProductCategory', id: number, name: string } | null } | null, variation?: { __typename?: 'Variation', id: number, name: string } | null }> } };

export type GetCustomerAppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerAppointmentsQuery = { __typename?: 'Query', customerAppointments: Array<{ __typename?: 'CustomerAppointmentsOutput', id: number, dateFrom: any, dateUntil: any, appointmentStatus: AppointmentStatus, bookedPrice: string, products?: Array<{ __typename?: 'AppointmentProduct', id: number, product?: { __typename?: 'Product', id: number, name: string } | null, variation?: { __typename?: 'Variation', id: number, name: string } | null }> | null }> };

export type VacancyFragment = { __typename?: 'VacanciesSearchOutput', slots: Array<number>, totalSlots: number };

export type VacanciesSearchQueryVariables = Exact<{
  dto: VacanciesSearchInput;
}>;


export type VacanciesSearchQuery = { __typename?: 'Query', vacanciesSearch: { __typename?: 'VacanciesSearchOutput', slots: Array<number>, totalSlots: number } };

export const CouponFragmentDoc = gql`
    fragment Coupon on Coupon {
  id
  name
  code
  description
  expirationDate
  startDate
  customerType
  usageType
  realCoupon
  combinable
  couponType
  variations {
    id
    name
  }
  value
  stores {
    id
    name
  }
  products {
    id
    name
  }
  productCategories {
    id
    name
  }
}
    `;
export const CouponVerificationOutputFragmentDoc = gql`
    fragment CouponVerificationOutput on CouponVerificationOutput {
  productId
  variationId
  price
  usedCoupons {
    id
  }
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  firstName
  lastName
  email
  phone
  street
  streetNumber
  city
  zipCode
  country
  preferredCommunicationType
  cooperation
  category
  termsAccepted
  utmParameter
  appointments {
    id
  }
  treatments {
    id
  }
}
    `;
export const CustomerLoginOutputFragmentDoc = gql`
    fragment CustomerLoginOutput on Customer {
  id
  email
  firstName
  lastName
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  name
  index
  descriptionShort
  descriptionLong
  prices {
    id
    price
    validFrom
    validTo
    customerType
  }
  variations {
    id
    name
    prices {
      id
      price
      validFrom
      validTo
      customerType
    }
  }
  isPackage
  packageProducts {
    id
  }
  requiredResources {
    resourceRequirements {
      property
      value
    }
    resourceType
  }
  productCategory {
    id
    name
  }
  totalDuration
}
    `;
export const PublicProductCategoryFragmentDoc = gql`
    fragment PublicProductCategory on ProductCategoryOutput {
  id
  name
}
    `;
export const ValidatedProductFragmentDoc = gql`
    fragment ValidatedProduct on ValidateProductSelectionOutput {
  id
  variation
  price
}
    `;
export const VariationFragmentDoc = gql`
    fragment Variation on Variation {
  id
  name
  prices {
    id
    customerType
    validFrom
    validTo
    price
  }
}
    `;
export const StoreOutputFragmentDoc = gql`
    fragment StoreOutput on StoreOutput {
  id
  name
  address
  description
  zipCode
  city
  monday {
    start
    end
  }
  slug
  tuesday {
    start
    end
  }
  wednesday {
    start
    end
  }
  thursday {
    start
    end
  }
  friday {
    start
    end
  }
  saturday {
    start
    end
  }
  sunday {
    start
    end
  }
}
    `;
export const AppointmentFragmentDoc = gql`
    fragment Appointment on Appointment {
  id
  dateFrom
  dateUntil
  customers {
    id
    firstName
    lastName
  }
  store {
    id
    name
    address
    city
    zipCode
  }
  appointmentType {
    id
    name
  }
  arrivalCustomer
  factualBegin
  dateOfBooking
  bookedPrice
  appointmentStatus
  bookingChannel
  utmParameters
  practitioners {
    id
    title
    firstName
    lastName
    presenceType
  }
  treatments {
    id
  }
  products {
    id
    package {
      id
    }
    name
    bookedPrice
    product {
      id
      taxRate
      name
      productCategory {
        id
        name
      }
    }
    variation {
      id
      name
    }
  }
  treatments {
    id
  }
}
    `;
export const CustomerAppointmentFragmentDoc = gql`
    fragment CustomerAppointment on CustomerAppointmentsOutput {
  id
  dateFrom
  dateUntil
  products {
    id
    product {
      id
      name
    }
    variation {
      id
      name
    }
  }
  appointmentStatus
  bookedPrice
}
    `;
export const VacancyFragmentDoc = gql`
    fragment Vacancy on VacanciesSearchOutput {
  slots
  totalSlots
}
    `;
export const VerifyCouponsDocument = gql`
    mutation VerifyCoupons($dto: CouponVerificationInput!) {
  verifyCoupons(dto: $dto) {
    ...CouponVerificationOutput
  }
}
    ${CouponVerificationOutputFragmentDoc}`;
export type VerifyCouponsMutationFn = Apollo.MutationFunction<VerifyCouponsMutation, VerifyCouponsMutationVariables>;

/**
 * __useVerifyCouponsMutation__
 *
 * To run a mutation, you first call `useVerifyCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCouponsMutation, { data, loading, error }] = useVerifyCouponsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useVerifyCouponsMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCouponsMutation, VerifyCouponsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCouponsMutation, VerifyCouponsMutationVariables>(VerifyCouponsDocument, options);
      }
export type VerifyCouponsMutationHookResult = ReturnType<typeof useVerifyCouponsMutation>;
export type VerifyCouponsMutationResult = Apollo.MutationResult<VerifyCouponsMutation>;
export type VerifyCouponsMutationOptions = Apollo.BaseMutationOptions<VerifyCouponsMutation, VerifyCouponsMutationVariables>;
export const GetCouponByCodeDocument = gql`
    query GetCouponByCode($code: String!) {
  couponByCode(code: $code) {
    id
    code
    description
  }
}
    `;

/**
 * __useGetCouponByCodeQuery__
 *
 * To run a query within a React component, call `useGetCouponByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCouponByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetCouponByCodeQuery, GetCouponByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponByCodeQuery, GetCouponByCodeQueryVariables>(GetCouponByCodeDocument, options);
      }
export function useGetCouponByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponByCodeQuery, GetCouponByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponByCodeQuery, GetCouponByCodeQueryVariables>(GetCouponByCodeDocument, options);
        }
export type GetCouponByCodeQueryHookResult = ReturnType<typeof useGetCouponByCodeQuery>;
export type GetCouponByCodeLazyQueryHookResult = ReturnType<typeof useGetCouponByCodeLazyQuery>;
export type GetCouponByCodeQueryResult = Apollo.QueryResult<GetCouponByCodeQuery, GetCouponByCodeQueryVariables>;
export const CustomerLoginDocument = gql`
    mutation CustomerLogin($dto: CustomerLoginInput!) {
  customerLoginBookingRoute(dto: $dto) {
    customer {
      id
      firstName
      lastName
      category
    }
    token
    mailSent
  }
}
    `;
export type CustomerLoginMutationFn = Apollo.MutationFunction<CustomerLoginMutation, CustomerLoginMutationVariables>;

/**
 * __useCustomerLoginMutation__
 *
 * To run a mutation, you first call `useCustomerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerLoginMutation, { data, loading, error }] = useCustomerLoginMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCustomerLoginMutation(baseOptions?: Apollo.MutationHookOptions<CustomerLoginMutation, CustomerLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerLoginMutation, CustomerLoginMutationVariables>(CustomerLoginDocument, options);
      }
export type CustomerLoginMutationHookResult = ReturnType<typeof useCustomerLoginMutation>;
export type CustomerLoginMutationResult = Apollo.MutationResult<CustomerLoginMutation>;
export type CustomerLoginMutationOptions = Apollo.BaseMutationOptions<CustomerLoginMutation, CustomerLoginMutationVariables>;
export const DeleteOwnCustomerDocument = gql`
    mutation DeleteOwnCustomer {
  deleteOwnCustomer
}
    `;
export type DeleteOwnCustomerMutationFn = Apollo.MutationFunction<DeleteOwnCustomerMutation, DeleteOwnCustomerMutationVariables>;

/**
 * __useDeleteOwnCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteOwnCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnCustomerMutation, { data, loading, error }] = useDeleteOwnCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteOwnCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnCustomerMutation, DeleteOwnCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOwnCustomerMutation, DeleteOwnCustomerMutationVariables>(DeleteOwnCustomerDocument, options);
      }
export type DeleteOwnCustomerMutationHookResult = ReturnType<typeof useDeleteOwnCustomerMutation>;
export type DeleteOwnCustomerMutationResult = Apollo.MutationResult<DeleteOwnCustomerMutation>;
export type DeleteOwnCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteOwnCustomerMutation, DeleteOwnCustomerMutationVariables>;
export const GetCustomerIdByEmailDocument = gql`
    mutation GetCustomerIdByEmail($dto: CustomerIdInput!) {
  getCustomerIdByEmail(dto: $dto)
}
    `;
export type GetCustomerIdByEmailMutationFn = Apollo.MutationFunction<GetCustomerIdByEmailMutation, GetCustomerIdByEmailMutationVariables>;

/**
 * __useGetCustomerIdByEmailMutation__
 *
 * To run a mutation, you first call `useGetCustomerIdByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIdByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCustomerIdByEmailMutation, { data, loading, error }] = useGetCustomerIdByEmailMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetCustomerIdByEmailMutation(baseOptions?: Apollo.MutationHookOptions<GetCustomerIdByEmailMutation, GetCustomerIdByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCustomerIdByEmailMutation, GetCustomerIdByEmailMutationVariables>(GetCustomerIdByEmailDocument, options);
      }
export type GetCustomerIdByEmailMutationHookResult = ReturnType<typeof useGetCustomerIdByEmailMutation>;
export type GetCustomerIdByEmailMutationResult = Apollo.MutationResult<GetCustomerIdByEmailMutation>;
export type GetCustomerIdByEmailMutationOptions = Apollo.BaseMutationOptions<GetCustomerIdByEmailMutation, GetCustomerIdByEmailMutationVariables>;
export const RegisterCustomerDocument = gql`
    mutation RegisterCustomer($dto: CustomerRegisterInput!) {
  registerCustomer(dto: $dto) {
    customer {
      id
      firstName
      lastName
      category
      email
      phone
    }
    token
  }
}
    `;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<RegisterCustomerMutation, RegisterCustomerMutationVariables>;

/**
 * __useRegisterCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerMutation, { data, loading, error }] = useRegisterCustomerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRegisterCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(RegisterCustomerDocument, options);
      }
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export type RegisterCustomerMutationOptions = Apollo.BaseMutationOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>;
export const ResetCustomerPasswordDocument = gql`
    mutation ResetCustomerPassword($dto: CustomerResetPasswordInput!) {
  resetCustomerPasswordBookingRoute(dto: $dto)
}
    `;
export type ResetCustomerPasswordMutationFn = Apollo.MutationFunction<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>;

/**
 * __useResetCustomerPasswordMutation__
 *
 * To run a mutation, you first call `useResetCustomerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCustomerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCustomerPasswordMutation, { data, loading, error }] = useResetCustomerPasswordMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useResetCustomerPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>(ResetCustomerPasswordDocument, options);
      }
export type ResetCustomerPasswordMutationHookResult = ReturnType<typeof useResetCustomerPasswordMutation>;
export type ResetCustomerPasswordMutationResult = Apollo.MutationResult<ResetCustomerPasswordMutation>;
export type ResetCustomerPasswordMutationOptions = Apollo.BaseMutationOptions<ResetCustomerPasswordMutation, ResetCustomerPasswordMutationVariables>;
export const SetCustomerPasswordDocument = gql`
    mutation SetCustomerPassword($dto: CustomerSetPasswordInput!) {
  setCustomerPassword(dto: $dto) {
    token
  }
}
    `;
export type SetCustomerPasswordMutationFn = Apollo.MutationFunction<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>;

/**
 * __useSetCustomerPasswordMutation__
 *
 * To run a mutation, you first call `useSetCustomerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerPasswordMutation, { data, loading, error }] = useSetCustomerPasswordMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSetCustomerPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>(SetCustomerPasswordDocument, options);
      }
export type SetCustomerPasswordMutationHookResult = ReturnType<typeof useSetCustomerPasswordMutation>;
export type SetCustomerPasswordMutationResult = Apollo.MutationResult<SetCustomerPasswordMutation>;
export type SetCustomerPasswordMutationOptions = Apollo.BaseMutationOptions<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: ID!, $dto: UpdateCustomerInput!) {
  updateCustomer(id: $id, dto: $dto) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const ValidateCustomerEmailDocument = gql`
    mutation ValidateCustomerEmail($dto: CustomerEmailInput!) {
  validateEmail(dto: $dto)
}
    `;
export type ValidateCustomerEmailMutationFn = Apollo.MutationFunction<ValidateCustomerEmailMutation, ValidateCustomerEmailMutationVariables>;

/**
 * __useValidateCustomerEmailMutation__
 *
 * To run a mutation, you first call `useValidateCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCustomerEmailMutation, { data, loading, error }] = useValidateCustomerEmailMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useValidateCustomerEmailMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCustomerEmailMutation, ValidateCustomerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateCustomerEmailMutation, ValidateCustomerEmailMutationVariables>(ValidateCustomerEmailDocument, options);
      }
export type ValidateCustomerEmailMutationHookResult = ReturnType<typeof useValidateCustomerEmailMutation>;
export type ValidateCustomerEmailMutationResult = Apollo.MutationResult<ValidateCustomerEmailMutation>;
export type ValidateCustomerEmailMutationOptions = Apollo.BaseMutationOptions<ValidateCustomerEmailMutation, ValidateCustomerEmailMutationVariables>;
export const ValidateCustomerPhoneDocument = gql`
    mutation ValidateCustomerPhone($dto: CustomerPhoneInput!) {
  validatePhone(dto: $dto)
}
    `;
export type ValidateCustomerPhoneMutationFn = Apollo.MutationFunction<ValidateCustomerPhoneMutation, ValidateCustomerPhoneMutationVariables>;

/**
 * __useValidateCustomerPhoneMutation__
 *
 * To run a mutation, you first call `useValidateCustomerPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCustomerPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCustomerPhoneMutation, { data, loading, error }] = useValidateCustomerPhoneMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useValidateCustomerPhoneMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCustomerPhoneMutation, ValidateCustomerPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateCustomerPhoneMutation, ValidateCustomerPhoneMutationVariables>(ValidateCustomerPhoneDocument, options);
      }
export type ValidateCustomerPhoneMutationHookResult = ReturnType<typeof useValidateCustomerPhoneMutation>;
export type ValidateCustomerPhoneMutationResult = Apollo.MutationResult<ValidateCustomerPhoneMutation>;
export type ValidateCustomerPhoneMutationOptions = Apollo.BaseMutationOptions<ValidateCustomerPhoneMutation, ValidateCustomerPhoneMutationVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: ID!) {
  customer(id: $id) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetOwnCustomerDocument = gql`
    query GetOwnCustomer {
  ownCustomer {
    id
    category
    firstName
    lastName
    email
    phone
  }
}
    `;

/**
 * __useGetOwnCustomerQuery__
 *
 * To run a query within a React component, call `useGetOwnCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnCustomerQuery, GetOwnCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnCustomerQuery, GetOwnCustomerQueryVariables>(GetOwnCustomerDocument, options);
      }
export function useGetOwnCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnCustomerQuery, GetOwnCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnCustomerQuery, GetOwnCustomerQueryVariables>(GetOwnCustomerDocument, options);
        }
export type GetOwnCustomerQueryHookResult = ReturnType<typeof useGetOwnCustomerQuery>;
export type GetOwnCustomerLazyQueryHookResult = ReturnType<typeof useGetOwnCustomerLazyQuery>;
export type GetOwnCustomerQueryResult = Apollo.QueryResult<GetOwnCustomerQuery, GetOwnCustomerQueryVariables>;
export const GetOwnDocumentsDocument = gql`
    query GetOwnDocuments {
  ownDocuments {
    name
    mimeType
    url
    createdAt
  }
}
    `;

/**
 * __useGetOwnDocumentsQuery__
 *
 * To run a query within a React component, call `useGetOwnDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnDocumentsQuery, GetOwnDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnDocumentsQuery, GetOwnDocumentsQueryVariables>(GetOwnDocumentsDocument, options);
      }
export function useGetOwnDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnDocumentsQuery, GetOwnDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnDocumentsQuery, GetOwnDocumentsQueryVariables>(GetOwnDocumentsDocument, options);
        }
export type GetOwnDocumentsQueryHookResult = ReturnType<typeof useGetOwnDocumentsQuery>;
export type GetOwnDocumentsLazyQueryHookResult = ReturnType<typeof useGetOwnDocumentsLazyQuery>;
export type GetOwnDocumentsQueryResult = Apollo.QueryResult<GetOwnDocumentsQuery, GetOwnDocumentsQueryVariables>;
export const GetIsExistingCustomerDocument = gql`
    query GetIsExistingCustomer($email: String!) {
  isExistingCustomer(email: $email)
}
    `;

/**
 * __useGetIsExistingCustomerQuery__
 *
 * To run a query within a React component, call `useGetIsExistingCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsExistingCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsExistingCustomerQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetIsExistingCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetIsExistingCustomerQuery, GetIsExistingCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsExistingCustomerQuery, GetIsExistingCustomerQueryVariables>(GetIsExistingCustomerDocument, options);
      }
export function useGetIsExistingCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsExistingCustomerQuery, GetIsExistingCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsExistingCustomerQuery, GetIsExistingCustomerQueryVariables>(GetIsExistingCustomerDocument, options);
        }
export type GetIsExistingCustomerQueryHookResult = ReturnType<typeof useGetIsExistingCustomerQuery>;
export type GetIsExistingCustomerLazyQueryHookResult = ReturnType<typeof useGetIsExistingCustomerLazyQuery>;
export type GetIsExistingCustomerQueryResult = Apollo.QueryResult<GetIsExistingCustomerQuery, GetIsExistingCustomerQueryVariables>;
export const GetPublicProductCategoriesDocument = gql`
    query GetPublicProductCategories {
  publicProductCategories {
    ...PublicProductCategory
  }
}
    ${PublicProductCategoryFragmentDoc}`;

/**
 * __useGetPublicProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPublicProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>(GetPublicProductCategoriesDocument, options);
      }
export function useGetPublicProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>(GetPublicProductCategoriesDocument, options);
        }
export type GetPublicProductCategoriesQueryHookResult = ReturnType<typeof useGetPublicProductCategoriesQuery>;
export type GetPublicProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetPublicProductCategoriesLazyQuery>;
export type GetPublicProductCategoriesQueryResult = Apollo.QueryResult<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>;
export const GetPublicVariationDocument = gql`
    query GetPublicVariation($id: ID!) {
  variation(id: $id) {
    ...Variation
  }
}
    ${VariationFragmentDoc}`;

/**
 * __useGetPublicVariationQuery__
 *
 * To run a query within a React component, call `useGetPublicVariationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicVariationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicVariationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicVariationQuery(baseOptions: Apollo.QueryHookOptions<GetPublicVariationQuery, GetPublicVariationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicVariationQuery, GetPublicVariationQueryVariables>(GetPublicVariationDocument, options);
      }
export function useGetPublicVariationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicVariationQuery, GetPublicVariationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicVariationQuery, GetPublicVariationQueryVariables>(GetPublicVariationDocument, options);
        }
export type GetPublicVariationQueryHookResult = ReturnType<typeof useGetPublicVariationQuery>;
export type GetPublicVariationLazyQueryHookResult = ReturnType<typeof useGetPublicVariationLazyQuery>;
export type GetPublicVariationQueryResult = Apollo.QueryResult<GetPublicVariationQuery, GetPublicVariationQueryVariables>;
export const ValidateProductSelectionDocument = gql`
    query ValidateProductSelection($store: Int!, $products: [ValidateProductSelectionInput!]!) {
  validateProductSelection(store: $store, products: $products) {
    products {
      id
      variation
      price
    }
  }
}
    `;

/**
 * __useValidateProductSelectionQuery__
 *
 * To run a query within a React component, call `useValidateProductSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateProductSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateProductSelectionQuery({
 *   variables: {
 *      store: // value for 'store'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useValidateProductSelectionQuery(baseOptions: Apollo.QueryHookOptions<ValidateProductSelectionQuery, ValidateProductSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateProductSelectionQuery, ValidateProductSelectionQueryVariables>(ValidateProductSelectionDocument, options);
      }
export function useValidateProductSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateProductSelectionQuery, ValidateProductSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateProductSelectionQuery, ValidateProductSelectionQueryVariables>(ValidateProductSelectionDocument, options);
        }
export type ValidateProductSelectionQueryHookResult = ReturnType<typeof useValidateProductSelectionQuery>;
export type ValidateProductSelectionLazyQueryHookResult = ReturnType<typeof useValidateProductSelectionLazyQuery>;
export type ValidateProductSelectionQueryResult = Apollo.QueryResult<ValidateProductSelectionQuery, ValidateProductSelectionQueryVariables>;
export const GetPublicStoreDocument = gql`
    query GetPublicStore($id: Int!) {
  publicStore(id: $id) {
    id
    name
    address
    description
    monday {
      start
      end
    }
    tuesday {
      start
      end
    }
    wednesday {
      start
      end
    }
    thursday {
      start
      end
    }
    friday {
      start
      end
    }
    saturday {
      start
      end
    }
    sunday {
      start
      end
    }
    city
    slug
    products {
      id
      name
      slug
      index
      taxRate
      descriptionShort
      descriptionLong
      incompatibleProducts {
        id
        name
      }
      prices {
        id
        price
        validFrom
        validTo
        customerType
      }
      totalDuration
      variations {
        id
        name
        prices {
          id
          price
          validFrom
          validTo
          customerType
        }
        totalDuration
      }
      productCategory {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPublicStoreQuery__
 *
 * To run a query within a React component, call `useGetPublicStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicStoreQuery(baseOptions: Apollo.QueryHookOptions<GetPublicStoreQuery, GetPublicStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicStoreQuery, GetPublicStoreQueryVariables>(GetPublicStoreDocument, options);
      }
export function useGetPublicStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicStoreQuery, GetPublicStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicStoreQuery, GetPublicStoreQueryVariables>(GetPublicStoreDocument, options);
        }
export type GetPublicStoreQueryHookResult = ReturnType<typeof useGetPublicStoreQuery>;
export type GetPublicStoreLazyQueryHookResult = ReturnType<typeof useGetPublicStoreLazyQuery>;
export type GetPublicStoreQueryResult = Apollo.QueryResult<GetPublicStoreQuery, GetPublicStoreQueryVariables>;
export const GetPublicStoresDocument = gql`
    query GetPublicStores {
  publicStores {
    id
    name
    address
    description
    city
    slug
    zipCode
  }
}
    `;

/**
 * __useGetPublicStoresQuery__
 *
 * To run a query within a React component, call `useGetPublicStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicStoresQuery, GetPublicStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicStoresQuery, GetPublicStoresQueryVariables>(GetPublicStoresDocument, options);
      }
export function useGetPublicStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicStoresQuery, GetPublicStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicStoresQuery, GetPublicStoresQueryVariables>(GetPublicStoresDocument, options);
        }
export type GetPublicStoresQueryHookResult = ReturnType<typeof useGetPublicStoresQuery>;
export type GetPublicStoresLazyQueryHookResult = ReturnType<typeof useGetPublicStoresLazyQuery>;
export type GetPublicStoresQueryResult = Apollo.QueryResult<GetPublicStoresQuery, GetPublicStoresQueryVariables>;
export const CancelAppointmentByCustomerDocument = gql`
    mutation CancelAppointmentByCustomer($id: Float!) {
  cancelAppointment(id: $id)
}
    `;
export type CancelAppointmentByCustomerMutationFn = Apollo.MutationFunction<CancelAppointmentByCustomerMutation, CancelAppointmentByCustomerMutationVariables>;

/**
 * __useCancelAppointmentByCustomerMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentByCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentByCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentByCustomerMutation, { data, loading, error }] = useCancelAppointmentByCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentByCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentByCustomerMutation, CancelAppointmentByCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentByCustomerMutation, CancelAppointmentByCustomerMutationVariables>(CancelAppointmentByCustomerDocument, options);
      }
export type CancelAppointmentByCustomerMutationHookResult = ReturnType<typeof useCancelAppointmentByCustomerMutation>;
export type CancelAppointmentByCustomerMutationResult = Apollo.MutationResult<CancelAppointmentByCustomerMutation>;
export type CancelAppointmentByCustomerMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentByCustomerMutation, CancelAppointmentByCustomerMutationVariables>;
export const CreateAppointmentByBookingRouteDocument = gql`
    mutation CreateAppointmentByBookingRoute($dto: CreateAppointmentByBookingRouteInput!) {
  createAppointmentByBookingRoute(dto: $dto) {
    id
    bookedPrice
  }
}
    `;
export type CreateAppointmentByBookingRouteMutationFn = Apollo.MutationFunction<CreateAppointmentByBookingRouteMutation, CreateAppointmentByBookingRouteMutationVariables>;

/**
 * __useCreateAppointmentByBookingRouteMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentByBookingRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentByBookingRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentByBookingRouteMutation, { data, loading, error }] = useCreateAppointmentByBookingRouteMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateAppointmentByBookingRouteMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentByBookingRouteMutation, CreateAppointmentByBookingRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentByBookingRouteMutation, CreateAppointmentByBookingRouteMutationVariables>(CreateAppointmentByBookingRouteDocument, options);
      }
export type CreateAppointmentByBookingRouteMutationHookResult = ReturnType<typeof useCreateAppointmentByBookingRouteMutation>;
export type CreateAppointmentByBookingRouteMutationResult = Apollo.MutationResult<CreateAppointmentByBookingRouteMutation>;
export type CreateAppointmentByBookingRouteMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentByBookingRouteMutation, CreateAppointmentByBookingRouteMutationVariables>;
export const RescheduleAppointmentByCustomerDocument = gql`
    mutation rescheduleAppointmentByCustomer($dto: RescheduleByCustomerInput!) {
  rescheduleAppointmentByCustomer(dto: $dto)
}
    `;
export type RescheduleAppointmentByCustomerMutationFn = Apollo.MutationFunction<RescheduleAppointmentByCustomerMutation, RescheduleAppointmentByCustomerMutationVariables>;

/**
 * __useRescheduleAppointmentByCustomerMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentByCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentByCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentByCustomerMutation, { data, loading, error }] = useRescheduleAppointmentByCustomerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRescheduleAppointmentByCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleAppointmentByCustomerMutation, RescheduleAppointmentByCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleAppointmentByCustomerMutation, RescheduleAppointmentByCustomerMutationVariables>(RescheduleAppointmentByCustomerDocument, options);
      }
export type RescheduleAppointmentByCustomerMutationHookResult = ReturnType<typeof useRescheduleAppointmentByCustomerMutation>;
export type RescheduleAppointmentByCustomerMutationResult = Apollo.MutationResult<RescheduleAppointmentByCustomerMutation>;
export type RescheduleAppointmentByCustomerMutationOptions = Apollo.BaseMutationOptions<RescheduleAppointmentByCustomerMutation, RescheduleAppointmentByCustomerMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation UpdateAppointment($dto: UpdateAppointmentInput!, $id: ID!) {
  updateAppointment(dto: $dto, id: $id) {
    id
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const GetCustomerAppointmentDocument = gql`
    query GetCustomerAppointment($id: ID!) {
  customerAppointment(id: $id) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;

/**
 * __useGetCustomerAppointmentQuery__
 *
 * To run a query within a React component, call `useGetCustomerAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAppointmentQuery, GetCustomerAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAppointmentQuery, GetCustomerAppointmentQueryVariables>(GetCustomerAppointmentDocument, options);
      }
export function useGetCustomerAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAppointmentQuery, GetCustomerAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAppointmentQuery, GetCustomerAppointmentQueryVariables>(GetCustomerAppointmentDocument, options);
        }
export type GetCustomerAppointmentQueryHookResult = ReturnType<typeof useGetCustomerAppointmentQuery>;
export type GetCustomerAppointmentLazyQueryHookResult = ReturnType<typeof useGetCustomerAppointmentLazyQuery>;
export type GetCustomerAppointmentQueryResult = Apollo.QueryResult<GetCustomerAppointmentQuery, GetCustomerAppointmentQueryVariables>;
export const GetCustomerAppointmentsDocument = gql`
    query GetCustomerAppointments {
  customerAppointments {
    ...CustomerAppointment
  }
}
    ${CustomerAppointmentFragmentDoc}`;

/**
 * __useGetCustomerAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetCustomerAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerAppointmentsQuery, GetCustomerAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAppointmentsQuery, GetCustomerAppointmentsQueryVariables>(GetCustomerAppointmentsDocument, options);
      }
export function useGetCustomerAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAppointmentsQuery, GetCustomerAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAppointmentsQuery, GetCustomerAppointmentsQueryVariables>(GetCustomerAppointmentsDocument, options);
        }
export type GetCustomerAppointmentsQueryHookResult = ReturnType<typeof useGetCustomerAppointmentsQuery>;
export type GetCustomerAppointmentsLazyQueryHookResult = ReturnType<typeof useGetCustomerAppointmentsLazyQuery>;
export type GetCustomerAppointmentsQueryResult = Apollo.QueryResult<GetCustomerAppointmentsQuery, GetCustomerAppointmentsQueryVariables>;
export const VacanciesSearchDocument = gql`
    query vacanciesSearch($dto: VacanciesSearchInput!) {
  vacanciesSearch(input: $dto) {
    ...Vacancy
  }
}
    ${VacancyFragmentDoc}`;

/**
 * __useVacanciesSearchQuery__
 *
 * To run a query within a React component, call `useVacanciesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanciesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanciesSearchQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useVacanciesSearchQuery(baseOptions: Apollo.QueryHookOptions<VacanciesSearchQuery, VacanciesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanciesSearchQuery, VacanciesSearchQueryVariables>(VacanciesSearchDocument, options);
      }
export function useVacanciesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanciesSearchQuery, VacanciesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanciesSearchQuery, VacanciesSearchQueryVariables>(VacanciesSearchDocument, options);
        }
export type VacanciesSearchQueryHookResult = ReturnType<typeof useVacanciesSearchQuery>;
export type VacanciesSearchLazyQueryHookResult = ReturnType<typeof useVacanciesSearchLazyQuery>;
export type VacanciesSearchQueryResult = Apollo.QueryResult<VacanciesSearchQuery, VacanciesSearchQueryVariables>;