import React, { useState } from "react";
import { Avatar, Flex, List, Select, Space, Typography } from "antd";
import { format } from "date-fns";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  AppointmentStatus,
  useGetCustomersWithAppointmentsQuery,
} from "../../../../../graphql/schema";

const CustomerSearch = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  const { data, loading } = useGetCustomersWithAppointmentsQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 5,
        query: {
          fields: ["firstName", "lastName"],
          term: value,
        },
      },
    },
  });
  return (
    <Select
      style={{
        minWidth: 400,
      }}
      dropdownStyle={{
        maxHeight: 800,
      }}
      showSearch
      value={value}
      onSearch={(val) => setValue(val)}
      filterOption={(input, option) => {
        const name = `${option?.firstName} ${option?.lastName}`;
        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      dropdownRender={(menu) => {
        return (
          <Space
            direction="vertical"
            style={{
              padding: 10,
              maxHeight: 400,
              width: "100%",
              overflow: "auto",
            }}
          >
            <Typography.Text strong>Kunden</Typography.Text>
            <List
              dataSource={
                data?.customers?.items?.filter((item) => {
                  const name = `${item?.firstName} ${item?.lastName}`;
                  return name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                }) || []
              }
              renderItem={(item) => (
                <Flex
                  onClick={() => navigate(`/customer/${item.id}`)}
                  align="center"
                  gap={10}
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Avatar>{`${item.firstName[0]}${item.lastName[0]}`}</Avatar>
                  <Typography.Text>
                    {`${item.firstName} ${item.lastName}`}
                  </Typography.Text>
                </Flex>
              )}
            />
            <Typography.Text strong>Termine</Typography.Text>
            <List
              dataSource={
                data?.customers?.items
                  ?.filter((item) => {
                    const name = `${item?.firstName} ${item?.lastName}`;
                    return name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                  })
                  ?.flatMap((customer) => customer.appointments || [])
                  .filter((appointment) => {
                    return [
                      AppointmentStatus.New,
                      AppointmentStatus.Confirmed,
                      AppointmentStatus.CustomerArrived,
                      AppointmentStatus.Accomplished,
                      AppointmentStatus.Present,
                    ].includes(appointment.appointmentStatus);
                  }) || []
              }
              renderItem={(item) => (
                <Flex
                  gap={10}
                  justify="space-between"
                  align="center"
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginTop: 10,
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    navigate(`/appointments`, {
                      state: {
                        id: item.id,
                        storeId: item?.store?.id,
                        date: item.dateFrom,
                        status: item.appointmentStatus,
                      },
                    });
                    window.location.reload();
                  }}
                >
                  <Flex align="center" gap={10}>
                    <Flex vertical>
                      <Typography.Text>
                        {format(item.dateFrom, "dd")}
                      </Typography.Text>
                      <Typography.Text>
                        {format(item.dateFrom, "MMM")}
                      </Typography.Text>
                    </Flex>
                    <Typography.Text>
                      {`${item?.customers?.map(
                        (customer) =>
                          `${customer.firstName} ${customer.lastName}`,
                      )}`}
                    </Typography.Text>
                  </Flex>
                  <ArrowRightOutlined />
                </Flex>
              )}
            />
          </Space>
        );
      }}
    />
  );
};

export default CustomerSearch;
