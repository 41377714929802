import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { addDays, isSameDay, startOfWeek } from "date-fns";
import styles from "../treatment/appointment/calendar/Calendar.module.scss";
import {
  GetPractitionerAvailabilitiesByPractitionerIdDocument,
  GetPractitionersAvailabilitiesByStoreIdDocument,
  useCreatePractitionerAvailabilityMutation,
  useDeleteAvailabilityMutation,
  useGetPractitionerAvailabilitiesByPractitionerIdQuery,
  useGetStoresQuery,
  useUpdatePractitionerAvailabilityMutation,
} from "../../graphql/schema";
import AvailabilityDrawer from "./AvailabilityDrawer";
import getStoreColor from "./storeColors";
import { plugin as dayjsTimeZonePlugin } from "fullcalendar-plugin-dayjs-timezone";

const AvailabilitiesCalendar = () => {
  const params = useParams();
  const id = parseInt(params.id!, 10);
  const calenderRef = useRef<any>();
  const [startDate, setStartDate] = useState<Date>(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState<Date>(
    addDays(startOfWeek(new Date()), 7),
  );
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [drawerInfo, setDrawerInfo] = React.useState<any | undefined>(
    undefined,
  );
  const [remove] = useDeleteAvailabilityMutation();
  const [create] = useCreatePractitionerAvailabilityMutation();
  const [update] = useUpdatePractitionerAvailabilityMutation();
  const { data: stores } = useGetStoresQuery();
  const { data } = useGetPractitionerAvailabilitiesByPractitionerIdQuery({
    fetchPolicy: "no-cache",
    variables: {
      practitionerId: id,
      start: startDate?.toDateString(),
      end: endDate?.toDateString(),
    },
  });
  const initialView = "timeGridWeek";

  const handleDateChange = (dateInfo: any) => {
    setStartDate(dateInfo.start);
    setEndDate(dateInfo.end);
  };

  const handleCloseDrawer = () => {
    setDrawerInfo(undefined);
    setIsDrawerVisible(false);
  };

  const handleOpenDrawer = (info: any) => {
    setDrawerInfo(info);
    setIsDrawerVisible(true);
  };

  const getStoreName = (storeId: number) => {
    return (
      stores?.stores.items?.find((store) => store.id === storeId)?.name ||
      "Gelöschte Filiale"
    );
  };

  const handleRemoveAvailability = async (availabilityId: number) => {
    if (!availabilityId) return;
    await remove({
      variables: {
        id: availabilityId,
      },
      refetchQueries: [
        GetPractitionerAvailabilitiesByPractitionerIdDocument,
        GetPractitionersAvailabilitiesByStoreIdDocument,
      ],
    });

    handleCloseDrawer();
  };

  const handleSubmitDrawer = async (values: any) => {
    if (!values) return;
    if (values.id) {
      await update({
        variables: {
          id: values.id,
          dto: {
            practitionerId: id,
            start: values.times[0].toDate(),
            end: values.times[1].toDate(),
            storeId: values.storeId,
          },
        },
        refetchQueries: [
          GetPractitionerAvailabilitiesByPractitionerIdDocument,
          GetPractitionersAvailabilitiesByStoreIdDocument,
        ],
      });
    } else {
      await create({
        variables: {
          dto: {
            practitionerId: id,
            start: values.times[0].toString(),
            end: values.times[1].toString(),
            storeId: values.storeId,
          },
        },
        refetchQueries: [GetPractitionerAvailabilitiesByPractitionerIdDocument],
      });
    }
    handleCloseDrawer();
  };

  const availability = useMemo(() => {
    if (!drawerInfo?.event) return undefined;
    return data?.practitionerAvailabilitiesByPractitionerId?.find(
      (a, index: number) => {
        return String(a.id) === drawerInfo?.event?.id;
      },
    );
  }, [drawerInfo]);

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24} />
        </Row>
        <FullCalendar
          timeZone="Europe/Berlin"
          selectOverlap={false}
          schedulerLicenseKey="0862586236-fcs-1713283190"
          ref={calenderRef}
          viewClassNames={[styles.calendar, styles.fcDaygridDayEvents]}
          dayHeaderClassNames={[styles.dayHeader]}
          dayCellClassNames={[styles.dayCell]}
          eventClassNames={[styles.event]}
          slotLaneClassNames={[styles.slotLane]}
          plugins={[
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            dayjsTimeZonePlugin,
          ]}
          initialView={initialView}
          select={(selectInfo) => {
            handleOpenDrawer(selectInfo);
          }}
          datesSet={(dateInfo) => {
            handleDateChange(dateInfo);
          }}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          selectAllow={(selectInfo) => {
            return isSameDay(selectInfo.start, selectInfo.end);
          }}
          allDaySlot={false}
          locale="de"
          buttonText={{
            today: "Heute",
            month: "Monat",
            week: "Woche",
            day: "Tag",
            list: "Liste",
          }}
          events={
            data?.practitionerAvailabilitiesByPractitionerId
              ?.filter((av) => !!av.store)
              .map((item: any, index: number) => {
                return {
                  id: item?.id || undefined,
                  title: getStoreName(item.store?.id),
                  start: new Date(item.start),
                  end: new Date(item.end),
                  color: getStoreColor(item.store?.id),
                  extendedProps: {
                    index,
                  },
                };
              }) || []
          }
          eventClick={(info: any) => {
            handleOpenDrawer(info);
          }}
          firstDay={1}
          slotMinTime="05:00:00"
          slotMaxTime="24:00:00"
          slotDuration="00:15:00"
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            week: "short",
          }}
          selectable
        />
        <AvailabilityDrawer
          isDrawerVisible={isDrawerVisible}
          handleCloseDrawer={handleCloseDrawer}
          handleSubmit={handleSubmitDrawer}
          availability={availability}
          info={drawerInfo}
          handleRemove={handleRemoveAvailability}
        />
      </Col>
    </Row>
  );
};

export default AvailabilitiesCalendar;
