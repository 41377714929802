import { Form, Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useEffect, useState } from "react";
import {
  useGetProductsLazyQuery,
  useGetVariationsSelectQuery,
} from "../../graphql/schema";

interface Props {
  index: number;
  value?: number | null;
  updateTotalDuration: (packageProducts: any) => Promise<void>;
  onChange?: (value: number, totalDuration: number | undefined) => void;
  disabled?: boolean;
}

const PackageVariationSelect = ({
  index,
  value,
  onChange,
  updateTotalDuration,
  disabled,
}: Props) => {
  const form = Form.useFormInstance();
  const packageProducts = Form.useWatch("packageProducts");
  const packageProductId = Form.useWatch([
    "packageProducts",
    index,
    "productId",
  ]);
  const [isSubProduct, setIsSubProduct] = useState(false);
  const filter = packageProductId
    ? {
        "product.id": packageProductId,
      }
    : undefined;
  const queryFilter = filter
    ? {
        variables: {
          options: {
            ...where(filter),
          },
        },
      }
    : {};
  const { data } = useGetVariationsSelectQuery(queryFilter);
  const [getProducts] = useGetProductsLazyQuery();

  useEffect(() => {
    const fetchIsSubProduct = async () => {
      const products = await getProducts({
        variables: {
          options: {
            itemsPerPage: 10000,
          },
        },
      });
      const product = products?.data?.products?.items?.find(
        (item: any) => item.id === packageProductId,
      );
      setIsSubProduct(product?.isSubProduct || false);
    };
    fetchIsSubProduct();
  }, [packageProducts, packageProductId, getProducts]);

  return (
    <Select
      disabled={isSubProduct || disabled}
      value={value}
      onChange={async (val) => {
        const variation = data?.variations.items.find((v) => v.id === val);
        const updatedPackageProducts = packageProducts.map(
          (product: any, i: number) =>
            i === index
              ? {
                  ...product,
                  variationId: value,
                }
              : product,
        );
        form.setFieldsValue({
          ...form.getFieldsValue(),
          updatedPackageProducts,
        });
        await updateTotalDuration(packageProducts);
        if (onChange) onChange(val, variation?.totalDuration || 0);
      }}
      options={
        data?.variations.items.map((variation) => {
          return {
            value: variation.id,
            label: variation.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default PackageVariationSelect;
