import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  GetCustomersDocument,
  GetStoreDocument,
  GetStoresDocument,
  useCreateStoreMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import StoreForm, {
  FormFields,
  parseFormFields,
} from "../components/store/StoreForm";

const CreateStore = () => {
  const navigate = useNavigate();

  const [create] = useCreateStoreMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    const data = await create({
      variables: {
        dto: parseFormFields(fields),
      },
      refetchQueries: [
        GetCustomersDocument,
        GetStoresDocument,
        GetStoreDocument,
      ],
    });
    navigate(`/store/${data.data?.createStore.id}`);
  }, []);

  return (
    <Content>
      <GenericForm
        title="Filiale erstellen"
        singleItemTitle="Filiale"
        formRenderer={(formProps) => <StoreForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/stores");
        }}
        initialValues={{
          name: "",
          address: "",
          description: "",
          city: "",
          monday: [
            [dayjs("08:00:00", "HH:mm:ss"), dayjs("19:00:00", "HH:mm:ss")],
          ],
          tuesday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
          wednesday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
          thursday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
          friday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
          saturday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
          sunday: [[dayjs("08:00", "HH:mm"), dayjs("19:00", "HH:mm")]],
        }}
      />
    </Content>
  );
};

export default CreateStore;
