import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  CouponDiscountType,
  CouponUsageType,
  CustomerType,
  GetCouponsDocument,
  useCreateCouponMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import CouponForm, {
  FormFields,
  parseFormFields,
} from "../components/coupons/CouponForm";

const CreateCoupon = () => {
  const navigate = useNavigate();

  const [create] = useCreateCouponMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    const data = await create({
      variables: {
        dto: parseFormFields(fields),
      },
      refetchQueries: [GetCouponsDocument],
    });

    message.success("Der Coupon wurde erstellt.");
    navigate(`/coupon/${data.data?.createCoupon.id}`);
  }, []);

  return (
    <Content>
      <GenericForm
        title="Gutschein erstellen"
        singleItemTitle="Gutschein"
        formRenderer={(formProps) => (
          <CouponForm formProps={formProps} editCode />
        )}
        onSave={handleSave}
        onBack={() => {
          navigate("/coupons");
        }}
        initialValues={{
          name: "",
          code: "",
          description: "",
          customerType: CustomerType.Standard,
          usageType: CouponUsageType.Single,
          realCoupon: false,
          combinable: false,
          storeIds: [],
          couponType: CouponDiscountType.Amount,
          value: "0",
        }}
      />
    </Content>
  );
};

export default CreateCoupon;
