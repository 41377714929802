import { Button, Drawer, Flex, Form, message } from "antd";
import React, { useCallback, useEffect } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useGetIsExistingCustomerLazyQuery } from "customer-client/src/graphql/schema";
import {
  CustomerType,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "../../../../graphql/schema";
import Loading from "../../../base/Loading";
import { useCalendar } from "../calendar/CalendarContext";
import CustomerForm, {
  FormFields,
  parseFormFields,
} from "../../../customer/CustomerForm";
import Content from "../../../layout/Content";
import { useAppointment } from "../context/AppointmentContext";

interface Props {
  id: number | null;
}

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};

const CustomerDrawer = ({ id }: Props) => {
  const [form] = Form.useForm();
  const { refetch: refetchAppointment } = useAppointment();
  const { openCustomerDrawer, setOpenCustomerDrawer } = useCalendar();
  const [isExisting] = useGetIsExistingCustomerLazyQuery();

  const { data, loading, refetch } = useGetCustomerQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  useEffect(() => {
    if (data?.customer) {
      form?.setFieldsValue({
        firstName: data?.customer.firstName || "",
        lastName: data?.customer.lastName || "",
        email: data?.customer.email || "",
        preferredCommunicationType: data?.customer.preferredCommunicationType,
        cooperation: data?.customer.cooperation || false,
        category: data?.customer.category || CustomerType.Standard,
        termsAccepted: data?.customer.termsAccepted || false,
        phone: data?.customer.phone?.includes(" ")
          ? data?.customer.phone?.split(" ")[1]
          : data?.customer.phone || "",
        alternativePhone: data?.customer.alternativePhone?.includes(" ")
          ? data?.customer?.alternativePhone?.split(" ")[1]
          : data?.customer.alternativePhone || "",
        countryCodePhone: data?.customer.phone.includes(" ")
          ? data?.customer.phone.split(" ")[0]
          : "+49",
        countryCodeAlternativePhone: data?.customer.alternativePhone?.includes(
          " ",
        )
          ? data?.customer.alternativePhone.split(" ")[0]
          : "+49",
        note: data?.customer.note || "",
        streetNumber: data?.customer.streetNumber || "",
        city: data?.customer.city || "",
        zipCode: data?.customer.zipCode || "",
        country: data?.customer.country || "",
        street: data?.customer.street || "",
      });
    }
  }, [data, id]);

  const [update] = useUpdateCustomerMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      try {
        const isExistingCustomer = await isExisting({
          variables: {
            email: fields.email,
          },
        });
        if (
          isExistingCustomer?.data?.isExistingCustomer &&
          fields.email !== data?.customer.email
        ) {
          message.error("Kunde mit dieser E-Mail Adresse existiert bereits.");
          return;
        }
        await update({
          variables: {
            id: openCustomerDrawer?.toString() || "",
            dto: parseFormFields(fields),
          },
          refetchQueries: [
            "GetCustomersSelect",
            "GetAppointmentsByStatus",
            "GetAppointmentsBetweenDates",
          ],
        });
        refetch();
        await refetchAppointment();

        message.success("Die Änderungen wurden gespeichert.");

        setOpenCustomerDrawer(null);
      } catch (e) {
        message.error("Es ist ein Fehler aufgetreten.");
      }
    },
    [id, data],
  );

  if (loading) return <Loading />;

  if (!data?.customer) return null;
  return (
    <Drawer
      contentWrapperStyle={{
        width: "60%",
      }}
      headerStyle={{
        display: "none",
      }}
      footer={
        <Flex justify="end">
          <Button
            type="primary"
            onClick={async () => {
              await handleSave(form.getFieldsValue() as FormFields);
            }}
          >
            Speichern
          </Button>
        </Flex>
      }
      mask={false}
      placement="left"
      onClose={async () => {
        setOpenCustomerDrawer(null);
      }}
      open={!!id}
    >
      <Content>
        <GenericForm
          title="Kunden bearbeiten"
          singleItemTitle="Kunde"
          formRenderer={(formProps) => (
            <CustomerForm
              formProps={{ ...formProps, form }}
              id={openCustomerDrawer}
            />
          )}
          onBack={() => {
            setOpenCustomerDrawer(null);
          }}
          initialValues={{
            firstName: data?.customer.firstName || "",
            lastName: data?.customer.lastName || "",
            email: data?.customer.email || "",
            preferredCommunicationType:
              data?.customer.preferredCommunicationType,
            cooperation: data?.customer.cooperation || false,
            category: data?.customer.category || CustomerType.Standard,
            termsAccepted: data?.customer.termsAccepted || false,
            phone: data?.customer.phone?.includes(" ")
              ? data?.customer.phone?.split(" ")[1]
              : data?.customer.phone || "",
            alternativePhone: data?.customer.alternativePhone?.includes(" ")
              ? data?.customer?.alternativePhone?.split(" ")[1]
              : data?.customer.alternativePhone || "",
            countryCodePhone: data?.customer.phone.includes(" ")
              ? data?.customer.phone.split(" ")[0]
              : "+49",
            countryCodeAlternativePhone:
              data?.customer.alternativePhone?.includes(" ")
                ? data?.customer.alternativePhone.split(" ")[0]
                : "+49",
            note: data?.customer.note || "",
            streetNumber: data?.customer.streetNumber || "",
            city: data?.customer.city || "",
            zipCode: data?.customer.zipCode || "",
            country: data?.customer.country || "",
            street: data?.customer.street || "",
          }}
        />
      </Content>
    </Drawer>
  );
};

export default CustomerDrawer;
