import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { AuthContext, LoginResult } from "./authContext";
import {
  deleteTokensFromStorage,
  getRoles,
  getTokensFromStorage,
  setTokensInStorage,
  tokenIsValid,
} from "./tokenHelper";
import { useLoginMutation } from "../graphql/schema";
import generateLink from "./generateLink";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const apolloClient = useApolloClient();

  const [login] = useLoginMutation();

  const [token, setToken] = useState<string | null | undefined>(undefined);

  const updateClientToken = useCallback(
    (t?: string | null) => {
      apolloClient.setLink(
        generateLink(t, () => {
          deleteTokensFromStorage();
          setToken(null);
          updateClientToken(null);
          apolloClient.resetStore();
        }),
      );
    },
    [apolloClient],
  );

  const updateTokens = (t: string) => {
    updateClientToken(t);
    setTokensInStorage(t);
    setToken(t);
  };

  useEffect(() => {
    (async () => {
      const [loadedToken] = await getTokensFromStorage();
      if (loadedToken && tokenIsValid(loadedToken)) {
        updateClientToken(loadedToken);
        setToken(loadedToken);
      } else {
        updateClientToken(null);
        setToken(null);
      }
    })();
  }, []);

  const providerValue = useMemo(() => {
    const getAuthToken = async (): Promise<string> => {
      if (token && tokenIsValid(token)) return token;

      // Invalid token
      setToken(null);

      return "";
    };

    const signIn = async (
      username: string,
      password: string,
    ): Promise<LoginResult> => {
      const result = await login({
        variables: {
          email: username,
          password,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data?.login?.access_token) {
        const newToken = result.data.login.access_token;
        updateTokens(newToken);
        return LoginResult.successful;
      }

      return LoginResult.failed;
    };

    const logout = () => {
      deleteTokensFromStorage();
      setToken(null);
      updateClientToken(null);
      apolloClient.resetStore();
    };

    return {
      isAuthenticated: !!token,
      authIsLoading: token === undefined,
      getAuthToken,
      signIn,
      logout,
      roles: getRoles(token),
    };
  }, [token, apolloClient]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
