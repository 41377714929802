import { Button, Tooltip } from "antd";

interface Props {
  onClick: () => Promise<void>;
}

const CreateAppointmentButton = ({ onClick }: Props) => {
  return (
    <Tooltip title="Termin erstellen">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={() => onClick()}
      >
        Termin erstellen
      </Button>
    </Tooltip>
  );
};

export default CreateAppointmentButton;
