const PractitionerColors = [
  "#3498DB",
  "#1ABC9C",
  "#16A085",
  "#27AE60",
  "#2ECC71",
  "#F39C12",
  "#E67E22",
  "#BDC3C7",
  "#9B59B6",
  "#9B59B6",
];

export default PractitionerColors;
