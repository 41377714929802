import { Select } from "antd";
import { useGetMaterialCategoriesQuery } from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
}

const MaterialCategorySelect = ({ value, onChange }: Props) => {
  const { data } = useGetMaterialCategoriesQuery();

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.materialCategories.items.map((materialCategory) => {
          return {
            value: materialCategory.id,
            label: materialCategory.name,
          };
        }) || []
      }
    />
  );
};

export default MaterialCategorySelect;
