import { Select } from "antd";
import { useGetStoresSelectQuery } from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  filter?: any;
}

const StoreSelect = ({ value, onChange, filter }: Props) => {
  const { data } = useGetStoresSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...(filter || {}),
        itemsPerPage: 1000,
      },
    },
  });
  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={
        data?.stores.items.map((store) => {
          return {
            value: store.id,
            label: store.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default StoreSelect;
