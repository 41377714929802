import React from "react";
import { message } from "antd";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import {
  useGetOwnUserQuery,
  useUpdateOwnUserMutation,
} from "../graphql/schema";
import Loading from "../components/base/Loading";
import OwnUserForm, { FormFields } from "../components/user/OwnUserForm";
import Content from "../components/layout/Content";

const UserSettings = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetOwnUserQuery({
    fetchPolicy: "no-cache",
  });

  const [update] = useUpdateOwnUserMutation();

  const handleSave = async (fields: FormFields) => {
    await update({
      variables: {
        dto: {
          password: fields.password.trim() !== "" ? fields.password : null,
        },
      },
    });
    message.success("Die Änderungen wurden gespeichert.");
  };

  if (loading) return <Loading />;

  return (
    <Content>
      <GenericForm
        title="Benutzereinstellungen"
        onSave={handleSave}
        singleItemTitle="Benutzereinstellungen"
        formRenderer={(formProps) => <OwnUserForm formProps={formProps} />}
        initialValues={{
          password: "",
        }}
        onBack={() => navigate("/")}
      />
    </Content>
  );
};

export default UserSettings;
