import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Spin spinning />
    </div>
  );
};
export default Loading;
