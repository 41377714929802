import React, { useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import { useNavigate } from "react-router-dom";
import { where } from "@3ts/react-ant-crud";
import { plugin as dayjsTimeZonePlugin } from "fullcalendar-plugin-dayjs-timezone";
import {
  AppointmentFragment,
  AppointmentStatus,
  useGetAppointmentsForCustomerQuery,
} from "../../graphql/schema";
import getStatusName from "../../helper/common/getStatusName";

interface Props {
  id: number | null;
}

const CustomerCalendar = ({ id }: Props) => {
  const data = useGetAppointmentsForCustomerQuery(
    id
      ? {
          fetchPolicy: "no-cache",
          variables: {
            options: {
              ...where<AppointmentFragment>({
                ["customers.id" as string]: [id],
              }),
              itemsPerPage: 1000,
            },
          },
        }
      : {},
  );

  const getProductNames = (products: any[]) => {
    return products
      .filter((product) => !product?.package?.id)
      .map((product) => product.name)
      .join(", ");
  };

  const getBackgroundColor = useCallback(
    (appointmentStatus: AppointmentStatus) => {
      if (appointmentStatus === AppointmentStatus.Cancelled) return "#ff4040";
      if (appointmentStatus === AppointmentStatus.Absent) return "#343a40";
      if (appointmentStatus === AppointmentStatus.RescheduledByKalialab)
        return "#696969";
      if (appointmentStatus === AppointmentStatus.New) return "#3399ff";
      if (appointmentStatus === AppointmentStatus.NoShow) return "#adb5bd";
      if (appointmentStatus === AppointmentStatus.Confirmed) return "#FED049";
      if (
        appointmentStatus === AppointmentStatus.ConfirmedWithVirtualPractitioner
      )
        return "#FED049";
      if (appointmentStatus === AppointmentStatus.CustomerArrived)
        return "#00cc00";
      if (appointmentStatus === AppointmentStatus.Accomplished)
        return "#00DFA2";
      return "#3399ff";
    },
    [data],
  );
  const navigate = useNavigate();

  if (!id) return null;
  return (
    <FullCalendar
      timeZone="Europe/Berlin"
      schedulerLicenseKey="0862586236-fcs-1713283190"
      themeSystem="bootstrap5"
      plugins={[listPlugin, dayjsTimeZonePlugin]}
      initialView="listYear"
      locale="de"
      eventClick={(info: any) => {
        navigate(`/appointments`, {
          state: {
            id: info.event.id,
            storeId: info.event.extendedProps.storeId,
            date: info.event.start,
            status: info.event.extendedProps.status,
          },
        });
      }}
      events={
        data?.data?.appointments.items.map((item) => ({
          title: `${getStatusName(item.appointmentStatus)} ${
            item.products?.length ? "-" : ""
          } ${getProductNames(item.products)}`,
          id: item.id.toString(),
          start: item.dateFrom,
          end: item.dateUntil,
          backgroundColor: getBackgroundColor(item.appointmentStatus),
          extendedProps: {
            storeId: item.store?.id,
            status: item.appointmentStatus,
          },
        })) || []
      }
      slotMinTime="07:00:00"
      slotMaxTime="21:00:00"
      slotDuration="00:15:00"
      slotLabelFormat={{
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        week: "short",
      }}
    />
  );
};

export default CustomerCalendar;
