import React, { useCallback } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  GetUsersDocument,
  useCreateUserMutation,
  useValidateUserExistsMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import UserForm, {
  FormFields,
  parseFormFields,
} from "../components/user/UserForm";

const CreateUser = () => {
  const navigate = useNavigate();

  const [create] = useCreateUserMutation();
  const [exists] = useValidateUserExistsMutation();

  const handleSave = useCallback(async (fields: FormFields) => {
    const userExists = await exists({
      variables: {
        email: fields.email,
      },
    });
    if (userExists.data?.userExists) {
      message.error(
        "Ein Mitarbeiter mit dieser E-Mail-Adresse existiert bereits.",
      );
      return;
    }
    const data = await create({
      variables: {
        dto: parseFormFields(fields),
      },
      refetchQueries: [GetUsersDocument],
    });

    message.success("Der Mitarbeiter wurde erstellt.");
    navigate(`/user/${data.data?.createUser.id}`);
  }, []);

  return (
    <Content>
      <GenericForm
        title="Mitarbeiter erstellen"
        singleItemTitle="Mitarbeiter"
        formRenderer={(formProps) => <UserForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/users");
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          street: "",
          streetNumber: "",
          city: "",
          zipCode: "",
        }}
      />
    </Content>
  );
};

export default CreateUser;
