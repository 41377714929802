import { Checkbox, Col, Row } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useGetProductsSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number];
  onChange?: (value: number[]) => void;
  filter?: any;
}

const ProductsCheckboxGroup = ({ value, onChange, filter = {} }: Props) => {
  const { data } = useGetProductsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where(filter),
        itemsPerPage: 10000,
      },
    },
  });
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      value={value}
    >
      <Row>
        {data?.products.items
          .filter((product) => product.available)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((product) => {
            return (
              <Col span={8} key={product.id}>
                <Checkbox key={product.id} value={product.id}>
                  {product.name}
                </Checkbox>
              </Col>
            );
          })}
      </Row>
    </Checkbox.Group>
  );
};

export default ProductsCheckboxGroup;
