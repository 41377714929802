import React, { useCallback } from "react";

import { Button, message } from "antd";
import Content from "../components/layout/Content";
import { useDeleteAllAppointmentsMutation } from "../graphql/schema";

const Dev = () => {
  const [deleteAllAppointments] = useDeleteAllAppointmentsMutation();

  const handleDelete = useCallback(async () => {
    await deleteAllAppointments();

    message.success("Alle Termine gelöscht.");
  }, []);

  return (
    <Content>
      <div>
        <p style={{ color: "red" }}>
          {" "}
          VORSICHT! DIESER KNOPF LÖSCHT ALLE TERMINE. NUR FÜR DEBUGGING-ZWECKE
          GEDACHT!!!
        </p>
        <Button disabled onClick={handleDelete}>
          ALLE TERMINE LÖSCHEN!
        </Button>
      </div>
    </Content>
  );
};

export default Dev;
