import { Button, Col, Typography } from "antd";
import { addDays, format, getWeek } from "date-fns";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React from "react";
import StaticTimezoneDatePicker from "../../../../staticTimezoneDatePicker/StaticTimezoneDatePicker";

interface Props {
  drawerVisible: boolean;
  calenderRef: any;
  setViewDates: any;
  viewDates: any;
}

const DatePickerOptions = ({
  drawerVisible,
  setViewDates,
  viewDates,
  calenderRef,
}: Props) => {
  const setLeftButtonDates = () => {
    if (calenderRef.current) {
      if (calenderRef.current.getApi().view.type === "timeGridThreeDays") {
        setViewDates({
          start: addDays(viewDates.start, -3),
          end: addDays(viewDates.end, -3),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, -3));
      }
      if (calenderRef.current.getApi().view.type === "timeGridWeek") {
        setViewDates({
          start: addDays(viewDates.start, -7),
          end: addDays(viewDates.end, -1),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, -7));
      }
      if (calenderRef.current.getApi().view.type === "resourceTimeGridDay") {
        setViewDates({
          start: addDays(viewDates.start, -1),
          end: addDays(viewDates.start, 0),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, -1));
      }
    }
  };

  const setRightButtonDates = () => {
    if (calenderRef.current) {
      if (calenderRef.current.getApi().view.type === "timeGridThreeDays") {
        setViewDates({
          start: addDays(viewDates.start, 3),
          end: addDays(viewDates.end, 3),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, 3));
      }
      if (calenderRef.current.getApi().view.type === "timeGridWeek") {
        setViewDates({
          start: addDays(viewDates.start, 7),
          end: addDays(viewDates.end, 7),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, 7));
      }
      if (calenderRef.current.getApi().view.type === "resourceTimeGridDay") {
        setViewDates({
          start: addDays(viewDates.start, 1),
          end: addDays(viewDates.end, 1),
        });
        calenderRef.current.getApi().gotoDate(addDays(viewDates.start, 1));
      }
    }
  };
  return (
    <Col span={drawerVisible ? 12 : 8}>
      <Button
        style={{
          margin: 10,
          marginRight: 10,
          marginLeft: 0,
        }}
        onClick={() => {
          setViewDates({
            start: new Date(),
            end: addDays(new Date(), 6),
          });
          if (calenderRef.current) {
            calenderRef.current.getApi().gotoDate(new Date());
          }
        }}
      >
        <Typography.Text>Heute</Typography.Text>
      </Button>
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={() => {
          setLeftButtonDates();
        }}
        icon={<LeftOutlined />}
      />
      {calenderRef?.current?.getApi()?.view?.type === "timeGridThreeDays" && (
        <StaticTimezoneDatePicker
          picker="date"
          defaultValue={dayjs(viewDates.start)}
          format={(value) =>
            value
              ? `${format(value.toDate(), "dd.MM.")} - ${format(
                  addDays(value.toDate(), 2),
                  "dd.MM.",
                )}`
              : ""
          }
          onChange={(date) => {
            if (date) {
              setViewDates({
                start: date.toDate(),
                end: addDays(date.toDate(), 2),
              });
              if (calenderRef.current) {
                calenderRef.current.getApi().gotoDate(date?.toDate());
              }
            }
          }}
          allowClear={false}
          value={dayjs(viewDates.start)}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      {calenderRef?.current?.getApi()?.view?.type === "resourceTimeGridDay" && (
        <StaticTimezoneDatePicker
          picker="date"
          format="DD.MM.YYYY"
          value={dayjs(viewDates.start)}
          defaultValue={dayjs(viewDates.start)}
          onChange={(date) => {
            if (date) {
              setViewDates({
                start: date.toDate(),
                end: addDays(date.toDate(), 6),
              });
              if (calenderRef.current) {
                calenderRef.current.getApi().gotoDate(date.toDate());
              }
            }
          }}
          allowClear={false}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      {calenderRef?.current?.getApi()?.view?.type === "timeGridWeek" && (
        <StaticTimezoneDatePicker.WeekPicker
          format={(value) => (value ? `KW ${getWeek(value.toDate())}` : "")}
          defaultValue={dayjs(viewDates.start)}
          onChange={(date) => {
            if (date) {
              setViewDates({
                start: date.toDate(),
                end: addDays(date.toDate(), 6),
              });
              if (calenderRef.current) {
                calenderRef.current.getApi().gotoDate(date.toDate());
              }
            }
          }}
          value={dayjs(viewDates.start)}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      <Button
        style={{
          margin: 10,
          marginLeft: 0,
        }}
        onClick={() => {
          setRightButtonDates();
        }}
        icon={<RightOutlined />}
      />
    </Col>
  );
};

export default DatePickerOptions;
